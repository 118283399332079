import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Banner4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const Banner4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <Banner4OpinionStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_1')}</h1>
        <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_2')}</h3>
        <ButtonWithArrowOnHover
          link="https://4opinion.eu/"
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'four_opinion_3',
          )}
        />
      </div>
    </Banner4OpinionStyles>
  );
};
