import React, {
  Dispatch, FC,
  SetStateAction, useMemo, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import { useAppSelector } from '../../state/hooks';
import { SubscribeBannerStyles } from './styles';
import nps from '../../assets/images/emailBanner/nps.png';
import arrow from '../../assets/images/emailBanner/arrow.svg';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { CloseIcon } from '../../assets/icons/Close';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import {
  Field,
  Form, Formik,
  FormikHelpers, FormikProps,
} from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { Api } from '../../api';
import { CustomCheckbox } from './customCheckbox';
import { CustomRadio } from './customRadio';

type TSubscribeBanner = {
  setShowSubscribe: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
  email: string;
  lang: string;
  emailTerms: boolean;
}

const initialValues: FormValues = {
  email: '',
  lang: '',
  emailTerms: false,
};

export const SubscribeBanner: FC<TSubscribeBanner> = ({ setShowSubscribe }) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const { pathname } = useLocation();

  const [cookies, setCookie] = useCookies<string>(['emailSubscribe', 'emailHoldSubscribe']);

  const navigate = useNavigate();

  const genID = uuidv4();

  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<any>();

  const handleClose = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 3);
    setCookie('emailHoldSubscribe', 'true', { path: '/', expires: expirationDate });
    setShowSubscribe(false);
  };

  const validationSchema = useMemo(
    () => Yup.object({
      lang: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      email: Yup.string()
        .email(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_email'),
        )
        .required(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
        ),
      emailTerms: Yup.boolean().isTrue(
        getTranslationsByLangOrEng(
          interfaceLanguage,
          'validation_privacy_policy',
        ),
      ),
    }),
    [interfaceLanguage],
  );

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setLoading(true);
    console.log(values);
    const data = {
      email: values.email,
      web_site: `https://4service.company${pathname}`,
      language: values.lang,
    };
    if (!loading) {
      Api.sendEmailForm(data).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          const farFutureDate = new Date('9999-01-01');
          setCookie('emailSubscribe', 'true', { path: '/', expires: farFutureDate });
          setSuccessfully(true);
          setLoading(false);
        }
      });
    }
    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="banner__form">
        <h1 className="banner__title">{getTranslationsByLangOrEng(interfaceLanguage, 'banner_2')}</h1>
        <div className="banner__fields">
          <input
            type="email"
            className="banner__fields-email"
            value={values.email}
            onChange={(e) => setFieldValue('email', e.target.value)}
            placeholder="*Email"
          />
          <div className="banner__language">
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'banner_4')}</p>
            <div className="banner__language-list" role="group" aria-labelledby="radio-group">
              <CustomRadio
                name="lang-en"
                value="en"
                label="EU"
                onChange={(e) => setFieldValue('lang', 'en')}
                selected={values.lang}
              />
              <CustomRadio
                name="lang-ua"
                value="ua"
                label={getTranslationsByLangOrEng(interfaceLanguage, 'banner_8')}
                onChange={(e) => setFieldValue('lang', 'ua')}
                selected={values.lang}
              />
              <CustomRadio
                name="lang-ru"
                value="ru"
                label={getTranslationsByLangOrEng(interfaceLanguage, 'banner_9')}
                onChange={(e) => setFieldValue('lang', 'ru')}
                selected={values.lang}
              />
            </div>
          </div>
        </div>
        <div className="banner__bottom">
          <div className="banner__terms">
            <CustomCheckbox
              name="emailTerms"
              onChange={() => {
                setFieldValue('emailTerms', !values.emailTerms);
              }}
              value={values.emailTerms}
            />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'banner_3')}</p>
          </div>
          <button type="submit">{getTranslationsByLangOrEng(interfaceLanguage, 'banner_7')}</button>
        </div>
      </div>
    </Form>
  );

  return (
    <SubscribeBannerStyles>
      <div className="banner">
        {!successfully
          ? (
            <>
              <div className="banner__img">
                <img src={nps} className="banner__img-bg" alt="" />
                <h2>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'banner_1')}
                </h2>
                {/* <div
                  className="banner__img-link"
                  onClick={() => {
                    setShowSubscribe(false);
                    navigate(`/${interfaceLanguage}/cx`);
                  }}
                >
                  <img src={arrow} alt="arrow" />
                </div> */}
              </div>
              <div className="banner__info">
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  enableReinitialize
                  validationSchema={validationSchema}
                >
                  {renderForm}
                </Formik>
              </div>
            </>
          )
          : (
            <div className="banner__success" id={genID}>
              <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'banner_5')}</h2>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'banner_6')}</p>
            </div>
          )}

        <div className="banner__close" onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
    </SubscribeBannerStyles>
  );
};
