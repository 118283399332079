import styled from 'styled-components';

export const LayoutStyles = styled.div`
  overflow-y: clip;

  .layoutContentWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: clip;
  }

  &.darkTheme {
    background-color: #000;
  }

  &.lightTheme {
    background-color: #fff;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .mainBody {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }

  .scrollButton {
    position: fixed;
    right: 18px;
    bottom: 70px;
    border-radius: 100%;
    border: 1px solid #ffffff;
    width: 25px;
    height: 25px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    svg {
      transform: rotate(-90deg);
    }
  }

  .footerWrapper {
    position: relative;
    z-index: 99;
  }
`;
