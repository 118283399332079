import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Benefits4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const Benefits4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <Benefits4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_4')}</h2>
        <div className="cards">
          <div className="cards-item">
            <h5>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_5')}</h5>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_6')}</p>
          </div>
          <div className="cards-item">
            <h5>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_7')}</h5>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_8')}</p>
          </div>
          <div className="cards-item">
            <h5>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_9')}</h5>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_10')}</p>
          </div>
        </div>
      </div>
    </Benefits4OpinionStyles>
  );
};
