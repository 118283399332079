export const de: { [key: string]: string } = {
  subscribe_with_click: 'Abonnieren Sie mit einem Klick',
  minimum_number_characters: 'mindestanzahl von 5 Zeichen',
  request_details: 'Anfragedetails',
  menu_21: 'Preisanalyse für Automobile',
  form_title: 'Wir werden uns umgehend bei Ihnen melden',
  form_required: 'Mit * gekennzeichnete Felder sind obligatorisch',
  form_name_placeholder: 'Name',
  form_email_placeholder: 'Email',
  form_phone_placeholder: 'Telefonnummer',
  form_company_placeholder: 'Firma',
  form_submit: 'Absenden',
  form_1:
    'Ich erteile meine Einwilligung zur Verarbeitung personenbezogener Daten gemäß dem',
  form_2: 'Datenschutz',
  validation_privacy_policy: 'Bitte akzeptieren Sie die Datenschutzerklärung',
  validation_phone: 'Die Telefonnummer ist nicht gültig',
  validation_email: 'Ungültiges E-Mail-Format',
  validation_required: 'Pflichtfelder sind nicht ausgefüllt',
  submit_error: 'Fehler beim Speichern des Formulars',
  footer_1: 'Unsere Partner:',
  footer_2: 'Methoden',
  footer_3: 'Produkte',
  footer_4: 'Kontaktdaten:',
  footer_5: 'Impressum',
  footer_6: 'Datenschutz',
  about_us_1: 'Soziale Unternehmensverantwortung von 4Service',
  about_us_2:
    'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
  about_us_3: 'Soziale Unternehmensverantwortung von 4Service',
  about_us_4:
    'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
  about_us_5: 'Businessspiel »ServiceMania»',
  about_us_6: 'Unsere Öffentlichkeitsarbeit für Kinder und Studenten',
  about_us_7: 'Wohltätigkeit / Freiwilligenarbeit',
  about_us_8:
    'Waisenhäuser, Altenheime und Freiwilligendienste für die ukrainische Armee. Der berührendste Teil unserer Tätigkeit',
  about_us_9: 'Tierschutz',
  about_us_10:
    'Jeder kann mit seinem Haustier zu uns ins Büro kommen. Wir helfen Tierheimen, sammeln Spenden für Futter und suchen auch ein Zuhause für heimatlose Tiere',
  about_us_11: '4Service Group',
  about_us_12:
    '4Service Group ist ein internationales Forschungsunternehmen, das die Kundenerfahrung verwaltet.',
  about_us_13:
    'Wir führen komplexe Projekte zur Umsatzsteigerung und Geschäftsentwicklung kundenorientierter Unternehmen durch.',
  about_us_14:
    'ist eine internationale Forschungsholding mit Hauptsitz in Wien, Österreich. Das Unternehmen wurde 2001 mit dem Ziel gegründet, die Lebensqualität seiner Kunden und Menschen zu verbessern, die mit Firmen interagieren, ihre Dienstleistungen nutzen und ihre Produkte konsumieren. Schließlich wurde der Name „Forservice“ nicht umsonst für ein Unternehmen gewählt, das für Kundenservice und gute Qualität steht.',
  about_us_15:
    '4Service wurde in Kyiv, in der Ukraine, gegründet und entwickelte sich zu einem führenden Anbieter von Mystery Shopping Services. Es ist weiterhin als Forschungsunternehmen in 52 Ländern erfolgreich tätig und bietet neue Möglichkeiten in einem ganzheitlichen Ansatz zur Analyse und Verbesserung aller Phasen der Kundenerfahrung (CX) an.',
  about_us_16: 'geboren im Namen des Dienstes',
  about_us_17: 'erstes Büro außerhalb der Ukraine',
  about_us_18: 'Verlegung des Firmensitzes nach Wien',
  about_us_19: 'elitäres MSPA Mitglied, Top-10 Agenturen in Europa',
  about_us_20: 'Gründung von Büros in 9 Ländern',
  about_us_21: 'erste Digitalprojekte, Voicer',
  about_us_22: 'Partner von DesignThinkers Academy',
  about_us_23: 'erste weltweite CХ-Projekte',
  about_us_24: 'Mission',
  about_us_25:
    'Unsere Mission ist den Kundenservice weltweit zu verbessern! Uns inspirieren die qualitativen Veränderungen, die wir bei den Menschen und Unternehmen sehen, mit denen wir zusammenarbeiten.',
  about_us_26: '20+ Jahre Kundenerfahrung',
  about_us_27: '95 Länder',
  about_us_28:
    'Wir sammeln und teilen die besten Branchenerfahrungen aus der ganzen Welt',
  about_us_29: 'Hauptsitz in Wien',
  about_us_30:
    'Länder. Wir sammeln und teilen die besten Branchenerfahrungen aus der ganzen Welt',
  about_us_31: 'Bewertungen der Servicequalität',
  about_us_32: 'wir gehören zu den Top-10 europäischen CX-Agenturen',
  about_us_33: 'Büros / HQ in Österreich',
  about_us_34: 'aktive Projekte',
  about_us_35: 'Ihr zukünftiger Arbeitsplatz',
  about_us_36: 'Unsere Büros',
  about_us_37:
    '4Service ™ bemüht sich, einen komfortablen Arbeitsplatz für seine Mitarbeiter zu schaffen. Unsere Mitarbeiter arbeiten im Büro, von Zuhause oder können dies kombinieren, um den besten Komfort und die beste Produktivität jedes Mitarbeiters zu fördern. Räume zum Entspannen, kostenlose psychologische Betreuung und eine einladende Umgebung. Wir sind menschen- und tierfreundlich.',
  about_us_38: 'Team, das strahlt und anpackt',
  about_us_39:
    'Unser multinationales Team ist bestrebt zuzuhören, sich auf das Feedback unserer Kunden zu stützen und gemeinsam zu wachsen. Die Karte der Kundenreise ist für unser Geschäft genauso wichtig, wie die Einführung neuer Produkte oder das Erreichen unserer finanziellen Ziele. Unsere Aktivitäten konzentrieren sich darauf, einen positiven Einfluss auf die Gemeinschaften um uns herum zu haben und unseren Kunden Innovationen und menschenorientierte Lösungen zu liefern.',
  about_us_40: 'Alles',
  about_us_41: 'Geschäftsführung',
  about_us_42: 'Team Global',
  about_us_43: 'Produktbesitzer',
  about_us_44: 'Unsere Partner',
  about_us_45:
    'Wir führen komplexe Projekte zur Umsatzsteigerung und Geschäftsentwicklung kundenorientierter Unternehmen durch.',
  about_us_46:
    'ist eine der führenden „designorientierten“ Berufshochschulen, die in 25 Ländern vertreten ist und kreative multidisziplinäre Teams und Gemeinschaften ausbildet, entwickelt und unterstützt, um positive Veränderungen voranzutreiben. Design Thinking ermöglicht es, innovative Ideen, Änderungen und Lösungen für komplizierte Probleme zu entwickeln und bereitzustellen.',
  about_us_47:
    'Ihre Mission ist es, Teams und Einzelpersonen Raum und Zeit zu bieten, um Inspiration und Herausforderungen zu finden, zu lernen durch Handeln und ihre Fähigkeit zur kreativen Problemlösung zu stärken, und Organisationen dabei zu helfen, die notwendige Umgebung und Denkweise aufzubauen, um mutige Innovationen anzustoßen.',
  about_us_48:
    'ist die repräsentative Handelsvereinigung für Unternehmen, die in der Mystery-Shopping-Branche tätig sind. Die Vereinigung ist weltweit regional tätig und hat Niederlassungen in Amerika, Europa/Afrika und Asien/Pazifik. Die Mitgliedsunternehmen schließen sich zu einer gemeinsamen Struktur, um die Mystery Shopping-Branche durch gemeinsame Bemühungen und Maßnahmen zu stärken.',
  about_us_49:
    'ist eine gemeinnützige Mitgliederorganisation. Die Mitglieder bilden eine Gemeinschaft, die vom Potenzial von Datenanalyse, Forschung und Erkenntnissen überzeugt ist, um Gesellschaften, Organisationen und das Leben aller zu verbessern.',
  about_us_50:
    'ESOMAR hat seinen Hauptsitz in Amsterdam, Niederlande. ESOMAR ist seit 1947 durch seine Mitglieder in über 130 Ländern präsent.',
  about_us_51:
    '(UCCA) ist eine professionelle Gemeinschaft privater Experten und Unternehmen, die sich zum Ziel der Entwicklung und Interaktion im Bereich Kontaktzentrum und Kundenservice zusammengeschlossen haben. Die Philosophie der UCCA ist es, die Kultur der Kontaktzentrum-Branche auf der Grundlage moderner Technologien und hoher Standards zu verbessern.',
  about_us_52: 'Der ukrainische Kontaktzentrumsverband wurde 2010 gegründet',
  about_us_53:
    'studiert die beste weltweite Erfahrung im Bildungsbereich, um sie auf dem ukrainischen Markt anzupassen und zu nutzen. Deshalb kooperiert Academy DTEK mit den führenden internationalen Wirtschaftsschulen: INSEAD, IE Wirtschaftsschule und Stanford Universität.',
  about_us_54:
    'Academy DTEK konzentriert sich auf die Entwicklung einer kundenorientierten und innovativen Kultur in der D.Client Schule, ID Schule, Executive Education: Energy of Innovation, Power of Communications, DX School Digital Program, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Serhij Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Internationale(-r) Vertriebsmanager(-in)',
  bht_1: 'Brand Health ',
  bht_2: 'Tracking',
  bht_3: 'Marken sind unglaublich mächtig',
  bht_4: 'Erfahren Sie, wie gut Ihre Marke insgesamt funktioniert',
  bht_5: 'Über',
  bht_6: 'Was ist Brand Health Tracking?',
  bht_7:
    'Hierbei handelt es sich um eine Art Marktforschung, die mit einer gewissen Regelmäßigkeit (alle zwei Wochen, monatlich etc.) durchgeführt wird, um',
  bht_8:
    'anhand von Indikatoren wie Bekanntheit, Nachfrage, Image, Verbraucherprofil, Markengesundheit usw. Informationen über den Status einer Marke auf dem Markt zu erhalten.',
  bht_9: 'Kauft die Marke am häufigsten',
  bht_10: 'Loyal',
  bht_11: 'Hat die Marke in den letzten 3 Monaten gekauft',
  bht_12: 'Stammkunden',
  bht_13: 'Hat die Marke in den letzten 6 Monaten gekauft',
  bht_14: 'Kurzfristige Kunden',
  bht_15: 'Hat die Marke im letzten Jahr gekauft',
  bht_16: 'Langfristige Kunden',
  bht_17: 'Kennt die Marke, hat sie aber noch nie benutzt/gekauft',
  bht_18: 'Vertraut, aber sind keine Kunden',
  bht_19: 'Nicht vertraut: haben noch nie über die Marke gehört',
  bht_20: 'Nicht vertraut',
  bht_21: 'Verbraucherprofil der Marke',
  bht_22:
    'Bewertung der Beständigkeit von Anstrengungen, die langfristig unternommen werden, um eine Marke aufzubauen',
  bht_23: 'Messung vom Wissen der Kunden über die Marke',
  bht_24: 'Nachweis der Effektivität der Werbe- und Informationskampagnen',
  bht_25:
    'Ermittlung, welche Auswirkungen eine Phase hoher Werbung auf die Verkaufs-/Marketingaktivitäten der Wettbewerber hat',
  bht_26:
    'Bereitstellung einer ständigen Kontrolle für den Geschäftsinhaber in Zeiten der kontinuierlichen Marktveränderungen',
  bht_27: 'Welche Aufgaben kann BHT lösen:',
  bht_28: 'Forschung anfordern',
  bht_29: 'Zu lösende Aufgabe',
  bht_30: 'Zum Beispiel,',
  bht_31:
    'benötigt ein Unternehmen bei der Messung des Marktanteils nur eine BHT-Sitzung pro Quartal, sollte eine Umfrage der Kunden bei der Messung der Markenbekanntheit durch Werbung häufiger durchgeführt werden.',
  bht_32: 'CATI',
  bht_33: 'Face-to-Face',
  bht_53: 'Befragungen',
  bht_34: 'СAWI',
  bht_35: 'Zu lösende Aufgabe',
  bht_36: 'Durch das Sammeln von Informationen',
  bht_37:
    'mit verschiedenen Methoden wird das Markentracking normalerweise in drei Phasen unterteilt, je nach Zielgruppe und wie schwer sie zu erreichen ist:',
  bht_38:
    'Wir verwenden sowohl traditionelle als auch moderne Methoden zur Datenerhebung: ',
  bht_39:
    'Wir arbeiten mit zwei Modellen, eines mit einem einfachen Satz von Indikatoren, während das andere auf komplexere Branchen wie die Lebensmittel- und Dienstleistungsbranche abzielt. Ein komplexeres Modell beinhaltet eine gründliche Studie, die bis zu 10 geschätzte Indizes umfasst.',
  bht_40: 'Wir bieten',
  bht_41: 'eine Kontrolle über Ausgangsdaten',
  bht_42: 'Wir decken',
  bht_43: 'das ganze Land ab',
  bht_44: 'Wir bieten eine Lösung',
  bht_45:
    'für nicht dynamische Märkte/ Märkte mit einer begrenzten Anzahl von Spielern (Brand Point Analyse)',
  bht_46: 'Warum wir?',
  bht_47: 'Warum sollten Sie uns wählen?',
  bht_50: 'Schlechtes Ergebnis',
  bht_51: 'Gutes Ergebnis',
  bht_52: 'Durchschnittliches Ergebnis',
  cases_1:
    'Herausforderung: Wie kann das Kauferlebnis von Kunden für Elektrofahrzeuge in Hyundai-Ausstellungsräumen verbessert werden?',
  cases_2: 'Karte der Kundenreise',
  cases_3: 'Ideenfindung',
  cases_4: 'Qualitative Forschung',
  cases_5:
    'Herausforderung: Was sollte in den Geschäften geändert werden (Serviceprozesse, Einrichtung, Markenwahrnehmung), um das Interesse der Kunden an der Marke Oppo zu wecken?',
  cases_6: 'Online-CJM',
  cases_7: 'UI-Tests',
  cases_8: 'Befragungen',
  cases_9:
    'Herausforderung: Was sollte in den Geschäften geändert werden, um das Interesse der Kunden an der Marke Oppo zu wecken?',
  cases_10: 'Qualitativer Teil',
  cases_11: 'Ideenfindung',
  cases_12: 'Quantitativer Teil',
  cases_13: 'Karte der Kundenreise',
  cases_14: 'Fälle',
  cases_15: 'Alles',
  cases_16: 'Lassen Sie uns gemeinsam an Ihrem großartigen Fall arbeiten',
  csi_1: 'Kunden',
  csi_2: 'zufriedenheitsindex (CSI)',
  csi_3: 'Bestimmen Sie die Gründe für ',
  csi_4: 'Zufriedenheit und Unzufriedenheit der Verbraucher',
  csi_5: 'Kundenzufriedenheitsforschung ',
  csi_6:
    'ist die Ermittlung der Faktoren, Ursachen und Motive, die die Kaufentscheidung eines Kunden sowie das Verhalten der Zielgruppe beeinflussen.',
  csi_7: 'Eine Umfrage zur Kundenzufriedenheit ',
  csi_8:
    'zeigt, wie zufrieden oder unzufrieden ein Kunde mit einem bestimmten Aspekt des Services oder einem Produkt im Allgemeinen ist.',
  csi_9: 'Die Umfrage zur Kundenzufriedenheit ',
  csi_10:
    'wird unter bestehenden Kunden eines Unternehmens und den Nutzern bestimmter Produkte/Dienstleistungen durchgeführt.',
  csi_11: 'Was ist CSI?',
  csi_12:
    'Die Kundenzufriedenheit misst, inwieweit die Erwartungen eines Kunden hinsichtlich des Kaufs eines Produktes oder Services Ihres Unternehmens erfüllt wurden.',
  csi_13: 'Glücklicher Mensch',
  csi_14: 'Über',
  csi_15: 'Versteckte Faktoren identifizieren,',
  csi_16: 'die die Kundenzufriedenheit beeinträchtigen können',
  csi_17: 'Gründe für den Anstieg/Rückgang',
  csi_18: 'der Nachfrage nach Waren und Dienstleistungen bestimmen',
  csi_19: 'Geschäftsprozesse eines Unternehmens ',
  csi_20: 'optimieren',
  csi_21: 'Sie an die Bedürfnisse ',
  csi_22: 'des modernen Verbrauchers anpassen',
  csi_23: 'Eine wirkungsvolle Werbebotschaft bilden ',
  csi_24: 'und ein Produkt unter der Kundenanfrage positionieren',
  csi_25: 'Zu lösende Aufgaben',
  csi_26: 'Welche Aufgaben kann die Umfrage zur Kundenzufriedenheit lösen',
  csi_27: 'Mann mit dem Handy',
  csi_28: 'Wir erstellen ein mathematisches Modell ',
  csi_29:
    'von Faktoren, die die Kundenzufriedenheit/Kundenbindung beeinflussen können (Regressionsanalyse)',
  csi_30: 'Geschäftsprozesse eines Unternehmens ',
  csi_31: 'optimieren',
  csi_32: 'Wir erstellen einen berechneten Kundenzufriedenheitsindex ',
  csi_33: '(CSI) und vergleichen ihn mit einem deklarativen',
  csi_34: 'Wir forschen ',
  csi_35: 'sowohl im B2B-, als auch im B2C-Bereich.',
  csi_36: 'Unsere Umfragen werden von einem Team ',
  csi_37:
    'aus Analysten speziell für die individuellen Projekte unserer Kunden entwickelt',
  csi_38: 'Warum wir',
  csi_39: 'Warum sollten Sie uns wählen?',
  csi_40: 'Methods of collecting information:',
  main_1:
    'ihre finanzielle Leistungsfähigkeit verbessern, indem sie aus den Erfahrungen ihrer Kunden lernen',
  main_2: 'СX Beratung und Dienstleistungen 360°-Ansicht Ihres Unternehmens',
  main_3:
    'Entdecken Sie unser Angebot an Lösungen und Tools, mit denen wir Ihre geschäftlichen Herausforderungen lösen',
  main_4: 'Produkte',
  main_5:
    'Qualitative Forschung sammelt und analysiert nicht-numerische Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen.',
  main_6:
    'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen und Fragebögen gesammelt werden.',
  main_7:
    'Mystery Shopping ist eine Marktforschungstechnik zur Messung und Überwachung der Interaktionen zwischen einem Kunden und einem Unternehmen oder einer Organisation in einem vordefinierten Szenario.',
  main_8:
    'Feedback-Management-System. Die Stimme Ihres Kunden in einem System Customer Feedback Management System',
  main_9:
    'Eine KI-gestützte Content-Intelligence-Plattform, die Erkenntnisse aus dem Publikum liefert',
  main_10:
    'Performance-Management-Software. Gamifizierte Anwendung zur Umsatzsteigerung',
  main_11:
    'Preisüberwachung ist der Prozess der Erhebung, Verarbeitung und Analyse der Preise der Wettbewerber.',
  main_12:
    'Service Design bedeutet, Bedürfnisse und Wünsche der Menschen zu verstehen, die ein Produkt oder eine Dienstleistung nutzen, indem man Zeit mit ihnen verbringt.',
  main_13:
    'Unser analytisches Team wendet die aktuellsten und grundlegendsten Ansätze an, die wir bei vielen Kunden als erfolgreich bewiesen haben.',
  main_14: 'Methoden',
  main_15: 'Karte der Kundenreise',
  main_16:
    'Bei der Karte der Kundenreise (CJM) geht es darum, eine Zielgruppe zu definieren, ihre Bedürfnisse und ihr Umfeld zu verstehen und dann einen Plan zu erstellen, um ein ansprechendes Erlebnis zu bieten.',
  main_17: 'Karte der Kundenreise',
  main_18: 'Net Promoter Score',
  main_19:
    'Net Promoter Score (NPS) ist der Goldstandard für Kundenerfahrungsmetriken, basierend auf einer Frage: Wie wahrscheinlich würden Sie Unternehmen/Produkte/Dienstleistungen weiterempfehlen?',
  main_20: 'NPS',
  main_21: 'Brand Health Tracking',
  main_22:
    'Brand Health Tracking (BHT)-Forschung kann messen, wie Ihre Marke in Bezug auf Bekanntheit und Nutzung, Markenpositionierung und Markenleistung abschneidet',
  main_23: 'BHT',
  main_24: 'Personalforschung',
  main_25:
    'Personalforschung dient für die Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zu der aktuellen Entwicklung und Management',
  main_26: 'Personalforschung',
  main_27: 'Kundenzufriedenheitsindex',
  main_28:
    'Personalforschung dient für die Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zu der aktuellen Entwicklung und Management',
  main_29: 'CSI',
  main_30: 'UX-Tests',
  main_31:
    'Nutzbarkeit-Tests (UX) zeigen, wie echte Menschen mit einer Website, App oder einem anderen Produkt interagieren und ihr Verhalten und ihre Reaktionen darauf beobachten.',
  main_32: 'Warum wir',
  main_33:
    'Unsere Mission ist es, Unternehmen dabei zu helfen, ein hocheffektives Kundenerlebnis zu bieten.',
  main_34: 'Qualität im Fokus',
  main_35: 'Wir kontrollieren die Qualität ',
  main_36:
    'der Forschungsdaten. Alle Datensätze stellen wir den Kunden zur Verfügung',
  main_37: 'Kosteneffizienz',
  main_38: 'Unsere Erfahrung, Ressourcen und optimierten',
  main_39: 'Geschäftsprozesse ermöglichen uns, Ihnen Geld und Zeit zu sparen',
  main_40: 'Intelligente Lösungen',
  main_41: 'Wir verwenden verschiedene Techniken ',
  main_42: 'und Instrumente, um Ihre speziellen Probleme zu lösen',
  main_43: 'Qualität im Fokus',
  main_44: 'Wir kontrollieren die Qualität ',
  main_45:
    'der Forschungsdaten. Alle Datensätze stellen wir den Kunden zur Verfügung',
  main_46: 'Kosteneffizienz',
  main_47: 'Unsere Erfahrung, Ressourcen und optimierten ',
  main_48: 'Geschäftsprozesse ermöglichen uns, Ihnen Geld und Zeit zu sparen',
  main_49: 'Intelligente Lösungen',
  main_50: 'Wir verwenden verschiedene Techniken ',
  main_51: 'und Instrumente, um Ihre speziellen Probleme zu lösen',
  main_52: 'Cash & Carry',
  main_53: 'Elektronik',
  main_54: 'Luxus/Premium',
  main_55: 'Essen & Gastfreundschaft',
  main_56: 'Einzelhandel',
  main_57: 'Automobil',
  main_58: 'Mode',
  main_59: 'Banken',
  main_60: 'Reisen & Freizeit',
  main_61: 'Uhren & Schmuck',
  main_62: 'Haushalt & Haushaltsgeräte',
  main_63: 'Blog',
  main_64: 'Besuchen Sie den Blog, um weitere interessante Beiträge zu lesen',
  main_65: 'Einzelhandelsaudit',
  main_66:
    'Sammeln Sie detaillierte Daten zu Ihrem Geschäft oder Ihrer Marke, analysieren Sie Wachstumsbarrieren und entwickeln Sie wirksame Strategien zu deren Überwindung',
  cjm_1: 'Kunde',
  cjm_2: 'Karte der Kundenreise',
  cjm_3: 'Betreten Sie die Welt der Kunden und teilen Sie ihre Erfahrungen mit',
  cjm_4: 'Was ist eine Karte der Kundenreise?',
  cjm_5:
    'CJM bietet eine visualisierte Geschichte der Verbraucherinteraktion mit einem Produkt, einer Dienstleistung, einem Unternehmen oder einer Marke über verschiedene Kanäle und über einen bestimmten Zeitraum.',
  cjm_6:
    'Dadurch können Unternehmen die Erfahrung der Kundeninteraktion objektiv analysieren, auftretende Hindernisse beheben und beseitigen und Empfehlungen zur Verbesserung des Produkts geben.',
  cjm_7: 'Bestimmung der Zielgruppe, Segmentierung',
  cjm_8: 'Identifizierung der Phasen der Kundeninteraktion',
  cjm_9: 'Identifizierung der primären Kundeninteraktionskanäle',
  cjm_10: 'Erforschung der Kundenerfahrungen',
  cjm_11: 'Analyse von Hindernissen und Hypothesen zur Optimierung',
  cjm_12: 'Ausführung und Prüfung von Hypothesen',
  cjm_13:
    'Die Gestaltung einer Karte der Kundenreise besteht aus folgenden Schritten:',
  cjm_14: 'Die Karte der Kundenreise zeigt:',
  cjm_15:
    'CJM bietet eine visualisierte Geschichte der Verbraucherinteraktion mit einem Produkt, einer Dienstleistung, einem Unternehmen oder einer Marke über verschiedene Kanäle und über einen bestimmten Zeitraum.',
  cjm_16: 'Wir sorgen für Teamarbeit',
  cjm_17: 'zwischen Analysten und Designern',
  cjm_18: 'Wir bieten jedem ',
  cjm_19: 'Kunden einen individuellen Ansatz.',
  cjm_20: 'Wir können allgemeine',
  cjm_21:
    'Kundenzufriedenheit mit dem Service/Produkt erhöhen und letztendlich die Loyalität gegenüber dem Unternehmen erhöhen.',
  cjm_22: 'Wir garantieren ',
  cjm_23:
    'Optimierung des Verbraucherwegs und berücksichtigen Geschäftsprozesse des Unternehmens',
  cjm_24: 'Wir bieten unseren Kunden',
  cjm_25:
    'eine Möglichkeit, kombinierte Ansätze zu nutzen: CATI, CAWI, Fokusgruppen, Tiefeninterviews, Experteninterviews, Ethnografie etc.',
  cjm_26: 'Warum sollten Sie uns wählen?',
  methods_hr_1: 'Personalforschung',
  methods_hr_2:
    'Erhalten Sie objektive Einblicke in Ihr Team und erfahren Sie, wie die HR-Leistung verbessert werden kann.',
  methods_hr_3:
    'Einige Gründe, warum Sie möglicherweise eine Personalforschung benötigen',
  methods_hr_4: 'der befragten Mitarbeiter',
  methods_hr_5: 'HR-Projekte umgesetzt',
  methods_hr_6: 'Schulungen für Mitarbeiter durchgeführt',
  methods_hr_7:
    'Führende europäische Holding für Kundenerfahrung- und Mitarbeiterforschung',
  methods_hr_8: '4Service Personalforschung in Zahlen',
  methods_hr_9:
    'Um Probleme und Schwächen in jeder Lebensphase der Mitarbeiter Ihres Unternehmens zu identifizieren: Suche/Rekrutierung/Einstieg/Karriere/Entwicklung/Re-Zertifizierungen',
  methods_hr_10:
    'Um zu wissen, welche Anlässe Gehaltserhöhungen und Boni verdienen',
  methods_hr_11:
    'Sie müssen Lösungen für Unzufriedenheit am Arbeitsplatz vorhersagen oder bereitstellen',
  methods_hr_12:
    'Durch HR-Forschung werden neue und aktuelle Methoden der Bewertung von HR-Politiken, -Programmen und -Praktiken möglich',
  methods_hr_13:
    'Um psychische Gesundheit der Mitarbeiter im Unternehmen zu verbessern',
  methods_hr_14:
    'Wenn Sie die Produktivität durch einen zielorientierten Ansatz verbessern möchten',
  methods_hr_15:
    'Wenn Sie ein internationales und/oder Außenteam haben und es verwalten müssen',
  methods_hr_16:
    'Um zu verstehen, wie attraktiv Ihre Arbeitgebermarke für potenzielle Mitarbeiter ist',
  methods_hr_17: 'Zufriedenheit der Mitarbeiter',
  methods_hr_18: 'HR-Branding-Forschung',
  methods_hr_19: 'Kulturelle Vielfalt',
  methods_hr_20: 'Vergütungs- und Gehaltspläne',
  methods_hr_21: 'Engagement und Loyalität des Teams',
  methods_hr_22: 'Mitarbeiter der geschützten Klasse',
  methods_hr_23: 'Bereiche der Forschung',
  methods_hr_24: 'Lösung 1',
  methods_hr_25: 'eNPS, Mitarbeiterzufriedenheit, Mitarbeiterloyalität',
  methods_hr_26: 'Was wir messen:',
  methods_hr_27: 'Loyalität:',
  methods_hr_28:
    'Mitarbeiterloyalität, Vorhersage des zukünftigen Verhaltens, Bestimmung der Gewichtung von Faktoren, die die Loyalität beeinflussen',
  methods_hr_29: 'Beteiligung:',
  methods_hr_30:
    'Beteiligung der Mitarbeiter an der Arbeit, Interesse an Ergebnissen und Leistungen',
  methods_hr_31: 'Engagement:',
  methods_hr_32: 'Identifikation von Mitarbeiterwerten und Unternehmenswerten',
  methods_hr_33: 'Vertrauen 360:',
  methods_hr_34:
    'Vertrauen in Management, Kollegen, Untergebene, Vertrauen in Strategien und Richtlinien des Arbeitgebers',
  methods_hr_35: 'Zufriedenheit:',
  methods_hr_36:
    'Definition der einzigartigen Faktoren für jedes Unternehmen, Bewertung der Zufriedenheit mit jedem Schlüsselfaktor, der die Loyalität beeinflusst',
  methods_hr_37: 'Lösung 2',
  methods_hr_38: 'Arbeitgeber Branding',
  methods_hr_39: 'Interner Arbeitgeber',
  methods_hr_40:
    'Markenanalyse (Mitarbeiter-, Beteiligter-, interne Recruiter- und HR-Spezialisten-Befragungen)',
  methods_hr_41: 'Externe Arbeitgeberanalyse ',
  methods_hr_42:
    '(Konkurrenten-EVP-Analyse, externe Recruiter-Befragung, Stellensuchende-Befragung)',
  methods_hr_43: 'Markenbekanntheit und Reputations-',
  methods_hr_44: 'analyse',
  methods_hr_45: 'Anwesenheit des Arbeitgebers',
  methods_hr_47: 'Sammlung von ',
  methods_hr_48: 'Arbeitgeberreferenzen',
  methods_hr_49: 'Wettbewerbs-Benchmarking ',
  methods_hr_50: 'zu Schlüsselfaktoren wie ',
  methods_hr_51: 'Gehalt, ',
  methods_hr_52: 'Arbeitsumfeld, Atmosphäre, Art der Arbeit',
  methods_hr_53: 'Analyse der Bedürfnisse und Erwartungen ',
  methods_hr_54: 'von Arbeitssuchenden',
  methods_hr_55: 'Tiefeninterviews, ',
  methods_hr_56:
    'Sekundärforschung und quantitative Forschung werden verwendet',
  methods_hr_57: 'Lösung 3',
  methods_hr_58: 'Mitarbeiter Reisekarte/Mystery-Mitarbeiter',
  methods_hr_59: 'Aufbau der Reise des Arbeitssuchenden',
  methods_hr_60:
    'Bestimmung der Hauptphasen des Suchprozesses, der Kommunikationskanäle, der Auswahlkriterien und der Bewertung der Arbeitssuchende',
  methods_hr_61: 'Überwachung aller Phasen',
  methods_hr_62:
    'und Kanäle der Kommunikation mit Mystery Arbeitssuchenden (einfach einen Termin für ein Vorstellungsgespräch vereinbaren, ein Vorstellungsgespräch bestehen, ein Praktikum in der Firma machen)',
  methods_hr_63: 'Ermittlung ',
  methods_hr_64:
    'von Schwachstellen in den Rekrutierungs- und Onboarding-Prozessen',
  methods_hr_65: 'Empfehlungen zur Verbesserung, ',
  methods_hr_66: 'Benchmarking mit Industrieführern',
  mystery_shopping_1: 'Mystery',
  mystery_shopping_2: 'Shopping',
  mystery_shopping_3: 'Bewerten Sie Ihren Service mit echten Kunden',
  mystery_shopping_4:
    'Prüfen Sie Standards, beurteilen Sie Ihre Mitarbeiter, werden Sie näher an Ihrem Kunden',
  mystery_shopping_5:
    'Wir sind ein Elite-Mitglied der professionell führenden Vereinigung - Mystery Shopping Providers Association (MSPA) Europe.',
  mystery_shopping_6: 'von Erfahrung',
  mystery_shopping_7: 'besucht monatlich',
  mystery_shopping_8: 'Testkäufer weltweit',
  mystery_shopping_9: 'Aktive Projekte',
  mystery_shopping_10: 'Büros, Hauptsitz in Österreich',
  mystery_shopping_11: 'Europäische Mystery-Shopping-Anbieter',
  mystery_shopping_12: 'Bewertungen der Servicequalität',
  mystery_shopping_13: 'Fragebogen',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Käuferprofil',
  mystery_shopping_16: 'Validierung und Widerspruch',
  mystery_shopping_17: 'Analytik',
  mystery_shopping_18:
    'Mit diesen 5 Schritten garantiert unsere Firma 4Service die Qualität des Mystery Shopper Services.',
  mystery_shopping_19: 'Programm',
  mystery_shopping_20:
    'Die 5 Elemente des Mystery Shopping Programms von 4Service',
  mystery_shopping_21: 'Fragebogen:',
  mystery_shopping_22: 'der Kern Ihres Programms',
  mystery_shopping_23:
    'Dieser Teil des Programms ist für alle Beteiligten sichtbar',
  mystery_shopping_24: 'Für Top-Manager.',
  mystery_shopping_25:
    'Der Fragebogen sollte alle wichtigen Schritte und Details des Prozesses darstellen',
  mystery_shopping_26: 'Für Mitarbeiter.',
  mystery_shopping_27:
    'Der Fragebogen sollte die Erwartungen der Arbeitgeber widerspiegeln',
  mystery_shopping_28: 'Für Testkäufer.',
  mystery_shopping_29:
    'Klar und strukturiert, um Verwirrung und Vorurteile zu vermeiden',
  mystery_shopping_30: 'Shopmetrics-Software:',
  mystery_shopping_31: 'benutzerfreundlich und innovativ',
  mystery_shopping_32: 'Vorteile:',
  mystery_shopping_33: 'Integriert sich ',
  mystery_shopping_34: 'in Ihre Geschäftsprozesse und Software',
  mystery_shopping_35: 'GDPR-getrieben',
  mystery_shopping_36: 'Instrumente für das Datenschutzmanagement',
  mystery_shopping_37: 'Ausführlich',
  mystery_shopping_38: 'Paket zur Einhaltung gesetzlicher Vorschriften',
  mystery_shopping_39: 'Bericht',
  mystery_shopping_40: 'Ersteller',
  mystery_shopping_41: 'Verwaltung ',
  mystery_shopping_42: 'der Zugriffsebenen',
  mystery_shopping_43: 'Die meisten Fragebögen ',
  mystery_shopping_44: 'sind innerhalb einer Stunde ausgefüllt',
  mystery_shopping_45: 'Käuferprofil:',
  mystery_shopping_46: 'Über 200.000 Testkäufer weltweit',
  mystery_shopping_47:
    'Hauptkriterien: maximale Nähe zu Ihrer Zielgruppe, mit Vorerfahrungen mit Ihrem Unternehmen oder Wettbewerbern',
  mystery_shopping_48: 'Käuferschulung:',
  mystery_shopping_49: 'Einführungstrainingstest',
  mystery_shopping_50: 'Skype/Telefonanruf',
  mystery_shopping_51: 'Einstufungstest (optional)',
  mystery_shopping_52: 'Validierung und Widerspruch',
  mystery_shopping_53: 'Validierung',
  mystery_shopping_54: 'System',
  mystery_shopping_55: '100% der Berichte ',
  mystery_shopping_56: 'werden vom Validierungsteam geprüft',
  mystery_shopping_57: 'Zusätzliche Anrufe ',
  mystery_shopping_58: 'zur Klarstellung',
  mystery_shopping_59: 'Audioaufzeichnungen ',
  mystery_shopping_60: 'nach dem Besuch, um die wichtigsten Punkte zu notieren',
  mystery_shopping_61: 'System zur Verhinderung ',
  mystery_shopping_62: 'von Mitarbeiterbetrug',
  mystery_shopping_63: 'Widerspruch',
  mystery_shopping_64: 'System',
  mystery_shopping_65: 'Jede Käuferbewertung ',
  mystery_shopping_66:
    'kann vom Mitarbeiter direkt auf der Webseite widersprochen werden',
  mystery_shopping_67: 'Jeder Widerspruch ',
  mystery_shopping_68:
    'wird vom lokalen Validierungsteam geprüft und beantwortet',
  mystery_shopping_69: 'Sie können die Statistik ',
  mystery_shopping_70: 'der Widersprüche einsehen',
  mystery_shopping_71: 'Analytik.',
  mystery_shopping_72: 'Der Bericht wird persönlich vorgelegt und enthält:',
  mystery_shopping_73: 'Schmerzpunkte ',
  mystery_shopping_74: 'und Bedenken des Unternehmens',
  mystery_shopping_75: 'Dynamik ',
  mystery_shopping_76: 'nach Abteilungen und Abschnitten',
  mystery_shopping_77: 'Zusammenhang ',
  mystery_shopping_78:
    'zwischen der Erfüllung der Standards und der Kundenzufriedenheit',
  mystery_shopping_79: 'Benchmarking ',
  mystery_shopping_80: 'gegen Mitbewerber',
  mystery_shopping_81: 'Praktische ',
  mystery_shopping_82: 'Empfehlungen zu Serviceverbesserungen',
  mystery_shopping_83: 'Was unterscheidet uns?',
  mystery_shopping_84: 'Wir führen Ideenfindungssitzungen durch',
  mystery_shopping_85:
    'Wir empfehlen nicht nur, was zu tun ist, sondern wir suchen gemeinsam mit Ihnen nach praktischen Wegen, um Serviceprobleme zu lösen.',
  mystery_shopping_86:
    'Sitzungen zur Ideenfindung sind ein leistungsstarkes Werkzeug, das uns hilft:',
  mystery_shopping_87:
    'Richtungen für die Verbesserung von Produkten oder Dienstleistungen zu entwickeln',
  mystery_shopping_88:
    'Neue Einnahmequellen und Geschäftsstrategien zu erkunden',
  mystery_shopping_89:
    'Lösungen für komplizierte kundenorientierte Herausforderungen zu finden',
  mystery_shopping_90: 'Schmerzpunkte der Kunden in Freuden zu verwandeln',
  mystery_shopping_91: 'Wir führen Video-Befragungen',
  mystery_shopping_92:
    'Wir sammeln nicht nur Fragebögen – wir bekommen auch Live-Kommentare von Käufern',
  mystery_shopping_93: 'Wir tun dies, um:',
  mystery_shopping_94:
    'Unsere erfahrenen Moderatoren sind in der Lage, hochwertige Tiefeninterviews zu führen und effektives Feedback zu geben',
  mystery_shopping_95: 'Sehen Sie das Erlebnis aus der Perspektive des Kunden',
  mystery_shopping_96: 'Verbessern Sie Ihre Content-Marketing-Strategie',
  mystery_shopping_97: 'Prognostizieren Sie das Kundenverhalten',
  mystery_shopping_98: 'Wir entwerfen die Karte der Kundenreise',
  mystery_shopping_99:
    'Die Karte der Kundenreise hilft uns, uns in einen Kunden hineinzuversetzen, um seine Bedürfnisse und Herausforderungen zu verstehen.',
  mystery_shopping_100:
    'Das Erstellen einer Kundenreise ist der beste Weg, um:',
  mystery_shopping_101: 'Das Erlebnis aus der Perspektive des Kunden zu sehen',
  mystery_shopping_102: 'Ihre Content-Marketing-Strategie zu verbessern',
  mystery_shopping_103: 'Das Kundenverhalten zu prognostizieren',
  mystery_shopping_104:
    'Lücken im Service oder in der Kommunikation zu identifizieren',
  mystery_shopping_105: 'Wir bieten verschiedene Arten von Mystery Shopping an',
  mystery_shopping_106: 'Arten von Mystery Shopping',
  mystery_shopping_107: 'Mystery Anrufer',
  mystery_shopping_108:
    'Ruft Ihre Vorgesetzten und/oder das Kontaktzentrum an, um die Servicequalität am Telefon zu ermitteln',
  mystery_shopping_109: 'Luxuriöser Mystery Shopping',
  mystery_shopping_110:
    'Prüfung des Services an Luxusstandorten durch eine spezielle Kategorie von Testkäufern',
  mystery_shopping_111: 'Wettbewerbsbesuche',
  mystery_shopping_112:
    'Der einfachste Weg, um zu verstehen, wie Ihr Konkurrent arbeitet',
  mystery_shopping_113: 'Mystery Mitarbeiter',
  mystery_shopping_114:
    'Hilft Ihnen, den gesamten Weg Ihres Mitarbeiters zu verstehen (Einstellung, Anpassung, Büroprozesse usw.)',
  mystery_shopping_115: 'Motivationsbesuch',
  mystery_shopping_116:
    'Ein nützliches Instrument, um das Angebot zusätzlicher Dienstleistungen und/oder Produkte sowie den Verkauf bestimmter Produkte anzuregen',
  mystery_shopping_117: 'Online-Testkäufer',
  mystery_shopping_118:
    'Simuliert und misst das Kundenerlebnis in Ihrem Online-Shop, auf Ihrer Webseite oder in Ihrer App',
  mystery_shopping_119: 'Verkaufs- und Werbeaktions-Prüfung',
  mystery_shopping_120:
    'Hilft zu erkennen, wie Werbeaktionen an allen Standorten angezeigt werden und wie effektiv sie funktionieren.',
  mystery_shopping_121: 'Einkauf und Rückgabe',
  mystery_shopping_122:
    'Hilft dabei, den gesamten Prozess vom Kauf bis zur Rückgabe abzubilden, um das Kundenerlebnis zu verbessern.',
  mystery_shopping_123: '> 20 jahre',
  nps_1: 'Net Promotion',
  nps_2: 'Score',
  nps_3: 'Kundenloyalität messen, verfolgen und verbessern',
  nps_4: 'Sie antworten mit einer Punktzahl von 0 bis 6.',
  nps_5:
    'Sie haben möglicherweise schlechte Erfahrungen gemacht und werden wahrscheinlich nicht wieder bei Ihnen kaufen oder andere sogar davon abhalten, bei Ihnen zu kaufen',
  nps_6: 'Dies sind Befragte mit einer Punktzahl zwischen 7 und 8.',
  nps_7:
    'Sie sind mit Ihrem Service zufrieden, aber nicht glücklich genug, um als Promoter angesehen zu werden.',
  nps_8: 'Dies sind Befragte, die Ihr Unternehmen mit 9 oder 10 bewerten.',
  nps_9:
    'Das bedeutet, dass sie Sie einem Freund oder Kollegen weiterempfehlen würden und sich deshalb für Sie einsetzen.',
  nps_10: 'Was ist NPS?',
  nps_11: 'Es misst die Kundenwahrnehmung anhand einer einfachen Frage:',
  nps_12:
    'Wie wahrscheinlich ist es, dass Sie [Organisation/Produkt/Dienstleistung] einem Freund oder Kollegen weiterempfehlen würden?',
  nps_13: 'Braucht es mein Unternehmen?',
  nps_14: 'NPS ist Ihr nachhaltiges Wachstum',
  nps_15: 'Dynamik der Kundenloyalität verstehen',
  nps_16: 'Ihre Mitarbeiter motivieren',
  nps_17: 'Einstellung zu verschiedenen Produkten verstehen',
  nps_18: 'Vergleichen, wie Kunden Sie und Ihre Mitbewerber behandeln',
  nps_19: 'Was kann ich mit NPS machen?',
  nps_20: 'Für welche Art von Unternehmen ist NPS relevant?',
  nps_21: 'Methoden zum Sammeln von Informationen:',
  nps_22: 'Unsere Empfehlungen beim Arbeiten mit NPS',
  nps_23:
    'Das bedeutet, dass wir Richtlinien entwickeln, die dabei helfen, Kritiker in Promotern zu verwandeln',
  nps_24: 'Wir arbeiten mit „Kreislauf“-Methode',
  nps_25:
    'NPS ist eine Tracking-Forschung, bei der wir Veränderungen in der Kundenloyalität verfolgen.',
  nps_26: 'Wir beobachten die Dynamik',
  nps_27:
    'Für eine tiefergehende Analyse empfehlen wir, immer die Wettbewerber in Ihrer Branche zu bewerten',
  nps_28: 'Bewertung der Wettbewerber',
  price_monitoring_1: 'Preisüberwachung',
  price_monitoring_2: 'Einzelhandelsüberwachung',
  price_monitoring_3:
    'Erhöhen Sie das Umsatzpotenzial und optimieren Sie Ihre Preispolitik durch Preisbeobachtung der Wettbewerber',
  price_monitoring_4:
    '4Service bietet seit über 20 Jahren Preisüberwachungsdienste weltweit an',
  price_monitoring_5:
    'Die Preise werden innerhalb kurzer Zeit von unserem Team qualifizierter interner Prüfer überwacht, sodass wir schnell auf Preisänderungen der Wettbewerber reagieren können.',
  price_monitoring_6:
    'Herausforderungen, die unser Unternehmen mit Preisüberwachung lösen kann',
  price_monitoring_7: 'Bewertung der Preis- ',
  price_monitoring_8: 'und Produktpolitik der Wettbewerber',
  price_monitoring_9: 'Überwachung der Werbeaktionen ',
  price_monitoring_10: 'und Sonderangebote',
  price_monitoring_11: 'Überwachung der Marktbedingungen ',
  price_monitoring_12: 'und des Marktumfelds',
  price_monitoring_13: 'Prüfung und Kontrolle von Verkaufsstellen ',
  price_monitoring_14: 'für Spezialgeräte und Handelswaren',
  price_monitoring_15: 'Verwaltung des Warenflusses ',
  price_monitoring_16: 'innerhalb des Handels',
  price_monitoring_17: 'Optimierung der Bedingungen ',
  price_monitoring_18:
    'für eine bessere Zusammenarbeit mit Lieferanten und Händlern',
  price_monitoring_19: 'Messung der Regalfläche, ',
  price_monitoring_20:
    'Beurteilung der Warenpositionierung, Lagerverfügbarkeit',
  price_monitoring_21: 'Kennzahlen und Überwachungskriterien',
  price_monitoring_22:
    'Alle Daten werden in einem Webinterface zusammengestellt, einschließlich:',
  price_monitoring_23: 'des Namen ',
  price_monitoring_24: 'der Produkt- und Markenkategorie',
  price_monitoring_25: 'der Verfügbarkeit',
  price_monitoring_26: 'von Werbematerialien',
  price_monitoring_27: 'des Preises',
  price_monitoring_28: 'und der Verfügbarkeit der Sonderangebote',
  price_monitoring_29: 'des Regal',
  price_monitoring_30: 'der Größe',
  price_monitoring_31: 'des Produktes',
  price_monitoring_32: 'der Auslage',
  price_monitoring_33: 'des Artikels',
  price_monitoring_34: 'des sichtbaren',
  price_monitoring_35: 'Typen',
  price_monitoring_36: 'der Verpackung',
  price_monitoring_37: 'sonstige ',
  price_monitoring_38: 'allgemeine Informationen',
  price_monitoring_39: 'Analyse vor dem Projekt:',
  price_monitoring_40: 'Wir untersuchen ',
  price_monitoring_41:
    'die Preis- und Produktpolitik sowie die Geschäfts- und Branchenpraktiken Ihres Unternehmens',
  price_monitoring_42: 'Wir bieten ',
  price_monitoring_43:
    'zeitnahe Schulungen, Tests und Zertifizierungen für Auditoren an',
  price_monitoring_44: 'Wir passen ',
  price_monitoring_45: 'die Software an und richten sie ein',
  price_monitoring_46: 'Die Feldarbeit:',
  price_monitoring_47: 'Besuche von Einzelhandels-',
  price_monitoring_48: 'geschäften',
  price_monitoring_49: 'Berichterstattung:',
  price_monitoring_50: 'Unser analytisches Team ',
  price_monitoring_51:
    'erstellt Online-Berichte in den persönlichen Konten des Unternehmens',
  price_monitoring_52: 'Wir bieten ',
  price_monitoring_53:
    'einen vollständigen Fotobericht, PPT-Bericht, Excel-Bericht in Bezug auf die Art des Ortes, den Namen der Kette und die Adresse der Filiale an',
  price_monitoring_54: 'Wie es ',
  price_monitoring_55: 'funktioniert',
  price_monitoring_56: 'Größe der individuellen Rabatte',
  price_monitoring_57: 'Kostenkalkulation',
  price_monitoring_58: 'Höhe der einzelnen Boni',
  price_monitoring_59: 'Loyalitätsprogramme',
  price_monitoring_60: 'usw.',
  price_monitoring_61: 'Wir verwenden spezialisierte Software: ',
  price_monitoring_62:
    'PRADATA, Shopmetrics und bieten umfassende technische Unterstützung an',
  price_monitoring_63: 'Schnelle Bereitstellung ',
  price_monitoring_64: 'und Skalierung (ab Tag 1)',
  price_monitoring_65: 'Alle Daten sind ',
  price_monitoring_66: 'durch Fotos und GPS-Tagging bestätigt ',
  price_monitoring_67: 'Wir haben die notwendige Anzahl ',
  price_monitoring_68: 'an Außendienstmitarbeitern in jedem Land',
  price_monitoring_69: 'Wir können große ',
  price_monitoring_70: 'Datenmengen verarbeiten ',
  price_monitoring_71: 'Überwachung der einzelnen Preise',
  price_monitoring_72:
    'Wenn Sie komplexe Produkte haben, können wir Ihnen helfen, Ihre Preisstrategie zu definieren.',
  price_monitoring_73:
    'Wir können Preise einzelner Wettbewerber überwachen, zum Beispiel:',
  price_monitoring_74: 'Niedrige ',
  price_monitoring_75: 'Kosten',
  price_monitoring_76: 'Hohe ',
  price_monitoring_77: 'Effizienz',
  price_monitoring_78: 'Excel-Bericht',
  price_monitoring_79: 'PPT-Bericht',
  price_monitoring_80: 'BI-Bericht',
  price_monitoring_81:
    'Preisdaten von Wettbewerbern helfen Ihnen, die besten Preisentscheidungen zu treffen.',
  price_monitoring_82:
    'Wir bieten einen vollständigen Fotobericht, PPT-Bericht, Excel-Bericht in Bezug auf die Art des Ortes an',
  qualitative_research_1: 'Qualitative',
  qualitative_research_2: 'Forschungen',
  qualitative_research_3:
    'Sammeln, analysieren und interpretieren der Daten, indem man die Handlungen und Worte Ihrer Kunden beobachtet.',
  qualitative_research_4: 'Werte',
  qualitative_research_5: 'Verhalten',
  qualitative_research_6: 'Hintergrund',
  qualitative_research_7: 'Erwartungen',
  qualitative_research_8: 'Umgebung',
  qualitative_research_9: 'Ängste/Hindernisse',
  qualitative_research_10: 'Welchen Zweck hat die Wahl des Kunden?',
  qualitative_research_11: 'Mann mit dem Handy',
  qualitative_research_12: 'Marke',
  qualitative_research_13: 'Kategorie',
  qualitative_research_14: 'Werbung',
  qualitative_research_15: 'Produkt',
  qualitative_research_16:
    'Die Einzigartigkeit qualitativer Methoden besteht darin, dass sie uns ermöglichen, die Ursachen und Motivationen des Verbraucherverhaltens zu verstehen.',
  qualitative_research_17:
    'Analyse der Einstellung des Verbrauchers zu einem bestimmten Phänomen',
  qualitative_research_18: 'Persönliche Perspektiven und Erfahrungen',
  qualitative_research_19:
    'Themen, die sich nicht durch Umfragen untersuchen lassen',
  qualitative_research_20: 'Empfindliche Themen',
  qualitative_research_21: 'Schwer erreichbare Zielgruppe',
  qualitative_research_22: 'Komplexe Geographie',
  qualitative_research_23: 'Tiefeninterviews sind nützlich für:',
  qualitative_research_24: 'Tiefeninterviews',
  qualitative_research_25:
    'Ein Tiefeninterview ist eine qualitative Forschungstechnik, bei der Einzelinterviews mit Befragten durchgeführt werden, um ihre Perspektiven über eine bestimmte Idee, ein bestimmtes Programm oder eine bestimmte Situation zu erkunden. In einem Tiefeninterview werden individuelle Erfahrungen/Wahrnehmungen/Praktiken detailliert untersucht.',
  qualitative_research_26: 'Ziele:',
  qualitative_research_27: 'Charakterisierung sozialer und kultureller Normen',
  qualitative_research_28: 'Schwer erreichbare Zielgruppe',
  qualitative_research_29:
    'Teilen und Vergleichen (Morgan), untersucht durch Umfragen',
  qualitative_research_30:
    'Themen, die sich nicht durch Umfragen untersuchen lassen',
  qualitative_research_31: 'Ausführliche Fokusgruppen sind nützlich für:',
  qualitative_research_32: 'Fokusgruppen',
  qualitative_research_33:
    'Tiefe Erforschung einzelner Treffen, aufkommende Diskussionen, Diskussionen zwischen Manifestationen unter dem Einfluss des Gruppenmoderators.',
  qualitative_research_34:
    'Generierung von Erkenntnissen über gemeinsame Erfahrungen und soziale Normen durch Gruppendiskussionen, narrative Daten in einer fokussierten Diskussion',
  qualitative_research_36: 'Vorteile:',
  qualitative_research_37:
    'Untersuchung des Lebensstils und des Verhaltens des Verbrauchers unter verschiedenen realen Bedingungen (zu Hause, am Verkaufsort, am Arbeitsplatz usw.)',
  qualitative_research_38:
    'Suche nach Erkenntnissen für die Entwicklung neuer Produkte, Verpackungen, Serviceverbesserungen usw.',
  qualitative_research_39:
    'Ausführliche ethnographische Forschung ist nützlich für:',
  qualitative_research_40: 'Ethnographische Forschung',
  qualitative_research_41:
    'Ethnographie ist eine Art qualitativer Forschung, bei der man in eine bestimmte Gemeinschaft oder Organisation eintaucht, um deren Verhalten und Interaktionen aus der Nähe zu beobachten.',
  qualitative_research_42:
    'Ethnographie ist eine flexible Forschungsmethode, mit der Sie ein tiefes Verständnis für die gemeinsame Kultur, Konventionen und soziale Dynamik einer Gruppe gewinnen können. Sie bringt jedoch auch einige praktische und ethische Herausforderungen.',
  qualitative_research_43: 'Methoden:',
  qualitative_research_44:
    'Ethnographie ist eine Untersuchung des Verbrauchers unter realen Bedingungen und seiner Interaktion mit einem Produkt / einer Dienstleistung.',
  qualitative_research_45: 'Vorteile von der Zusammenarbeit mit uns',
  qualitative_research_46:
    'Wir finden Befragte aus jeder Zielgruppe. Und wir kontrollieren die Rekrutierungsqualität',
  qualitative_research_47: 'Rekrutierung beliebiger Komplexität',
  qualitative_research_48:
    'Unsere Moderatoren sind mehrsprachig und verfügen über Moderationsfähigkeiten',
  qualitative_research_49: 'Erfahrene Moderatoren',
  qualitative_research_50: 'Wir arbeiten online/offline',
  qualitative_research_51: 'Wir stellen Audio-/Videoaufnahmen',
  qualitative_research_52: 'zur Verfügung',
  qualitative_research_53:
    'Wir können qualitative Forschung an jedem Ort durchführen',
  quantitative_research_1: 'Untersuchung der Kundenzufriedenheit ',
  quantitative_research_2:
    'mit einem bestimmten Produkt oder Service (SCI, NPS)',
  quantitative_research_3: 'Durchführung von Segmentierungserhebungen',
  quantitative_research_4: 'in verschiedenen Märkten',
  quantitative_research_5: 'Marktforschung',
  quantitative_research_6: 'NPS-Management',
  quantitative_research_7: 'Wettbewerbsanalyse',
  quantitative_research_8: 'Quantitative',
  quantitative_research_9: 'Forschungen',
  quantitative_research_10:
    'Forschungsmethodik, mit der Theorien über Einstellungen und Verhaltensweisen von Menschen anhand objektiver numerischer und statistischer Daten getestet wurden',
  quantitative_research_11: 'Jahre',
  quantitative_research_12: 'der CX-Erfahrung weltweit',
  quantitative_research_13: 'Panel',
  quantitative_research_14: 'der Befragten',
  quantitative_research_15: 'Länder',
  quantitative_research_16: 'Wir führen Projekte auf der ganzen Welt durch',
  quantitative_research_17: 'Wer wir sind',
  quantitative_research_18:
    '4Service ist eine globale Marketingforschungs- und CX-Holding. Wir bieten fachkundige Datenerfassungsdienste für eine Vielzahl von Unternehmen an',
  quantitative_research_19: '4Service in Zahlen',
  quantitative_research_20: 'Computerunterstützte ',
  quantitative_research_21: 'Web-Interviews',
  quantitative_research_22:
    'Eine internetbasierte Fragebogentechnik. Der Befragte füllt einen elektronischen Fragebogen ohne Interviewer aus. Der Fragebogen kann elektronisch übermittelt oder auf der Webseite veröffentlicht werden.',
  quantitative_research_23: 'Computerunterstützte Web-Interviews',
  quantitative_research_24: 'In den meisten Fällen ',
  quantitative_research_25:
    'ist diese Methode finanziell und zeitlich am günstigsten',
  quantitative_research_26: 'Diese Umfrage gibt ',
  quantitative_research_27:
    'eine Möglichkeit, ein schwer zu erreichendes Publikum zu befragen',
  quantitative_research_28: 'Es bietet umfangreiche Möglichkeiten',
  quantitative_research_29:
    'zur Demonstration von Video- und Audiomaterial, sowie Bilddemonstrationen',
  quantitative_research_30: 'Die Befragten können sich ',
  quantitative_research_31: 'überall auf der Welt befinden',
  quantitative_research_32: 'Es erlaubt, das spezifische Verhalten ',
  quantitative_research_33:
    'des Internetpublikums zu untersuchen. Die wichtigsten Ergebnisse sind für den Kunden in Echtzeit über ein Webinterface abrufbar.',
  quantitative_research_34: 'Geeignet für alle vertraulichen, ',
  quantitative_research_35:
    'sensiblen, persönlichen Themen oder Probleme, die die Befragten im Gespräch mit einem Interviewer möglicherweise nur zögerlich besprechen',
  quantitative_research_36: '150 000+ Befragten',
  quantitative_research_37:
    'Wir haben unsere eigene Gruppe von Befragten weltweit.',
  quantitative_research_38: 'Wir arbeiten mit anspruchsvollen Anfragen',
  quantitative_research_39:
    'Durch den Einsatz ergänzender Instrumente (soziale Medien, Werbung) können wir eine spezifische Zielgruppe und ein ausgefeiltes Profil des Befragten finden.',
  quantitative_research_40: 'Wir motivieren unsere Befragten',
  quantitative_research_41:
    'Wir haben ein System entwickelt, um das Ausfüllen langer Umfragen durch materielle Anreize zu fördern.',
  quantitative_research_42: 'Persönliche Befragungen',
  quantitative_research_43: 'werden mit Tablets durchgeführt ',
  quantitative_research_44:
    'Dies ist eine der Hauptmethoden zur Erhebung quantitativer Daten, bei der der Interviewer direkt nach einem streng strukturierten Fragebogen mit dem Befragten kommuniziert.',
  quantitative_research_45:
    'Persönliche Interviews können am Arbeits- oder Wohnort der Befragten, auf der Straße oder an ausgewiesenen Verkaufsstellen per Tablet durchgeführt werden.',
  quantitative_research_46:
    'Persönliche Interviews mit der Verwendung vom Tablet',
  quantitative_research_47: 'Ermittlung des Bekanntheitsgrades ',
  quantitative_research_48: 'und der Markenwahrnehmung',
  quantitative_research_49: 'Kundensegmentierung ',
  quantitative_research_50: 'nach ihrem Kaufverhalten',
  quantitative_research_51: 'Analyse der Effektivität ',
  quantitative_research_52: 'einer Werbekampagne',
  quantitative_research_53: 'Messung ',
  quantitative_research_54: 'der Kundenzufriedenheit',
  quantitative_research_55: 'Bestimmung des besten Preises ',
  quantitative_research_56: 'für das Produkt oder die Dienstleistung',
  quantitative_research_57: 'Analyse ',
  quantitative_research_58: 'der Verbraucherpräferenzen',
  quantitative_research_59: 'Untersuchung ',
  quantitative_research_60: 'des Kaufverhaltens der Kunden',
  quantitative_research_61: 'Bewertung ',
  quantitative_research_62: 'der Marktkapazität',
  quantitative_research_63: 'DigSee-Software',
  quantitative_research_64:
    'Wir verwenden eine spezielle Software mit Audioaufnahme und GPS',
  quantitative_research_65: 'Interviewer und Vorgesetzte',
  quantitative_research_66:
    'Wir können Interviews in beliebiger Sprache durchführen und in die vom Kunden bevorzugte Sprache übersetzen',
  quantitative_research_67: 'Probenahme',
  quantitative_research_68:
    'Die analytische Abteilung entwickelt ein System für die Rekrutierung von Befragten, um die bestmöglichen Ergebnisse zu erzielen',
  quantitative_research_69: 'Computerunterstützte ',
  quantitative_research_70: 'telefonische Befragung',
  quantitative_research_71:
    'Methodik zum Sammeln quantitativer Informationen durch Telefoninterviews mit einem klar strukturierten Fragebogen.',
  quantitative_research_72: 'Computerunterstützte telefonische Befragung',
  quantitative_research_73: 'Wir haben unsere eigenen Callcenter',
  quantitative_research_74:
    'Wir verwenden eine spezielle Software mit Audioaufnahme und GPS',
  quantitative_research_75: 'Qualifizierte Mitarbeiter',
  quantitative_research_76:
    'Unsere Mitarbeiter sprechen verschiedene Sprachen, sind geschult und geprüft und verbessern ihre Fähigkeiten kontinuierlich',
  quantitative_research_77: 'Datenbanken',
  quantitative_research_78:
    'Wir arbeiten mit unseren eigenen Datenbanken und Ihren Kundenstämmen',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Wir garantieren Qualität und unterzeichnen ein SLA (Service Level Agreement)',
  quantitative_research_81: 'Zielsetzung',
  quantitative_research_82: 'Definieren der Methodik',
  quantitative_research_83: 'Segmentierung und Probenahme',
  quantitative_research_84: 'Definieren von Datenerfassungskanälen',
  quantitative_research_85:
    'Gestaltung eines Fragebogens, der für den Befragten logisch ist',
  quantitative_research_86: 'Prozess der Datenerfassung',
  quantitative_research_87: 'Qualitätssicherung',
  quantitative_research_88: 'Analysetechniken',
  quantitative_research_89: 'Datenverarbeitung',
  quantitative_research_90: 'Design der Umfrage',
  quantitative_research_91: 'GPS-Standortverfolgung von Interviewern',
  quantitative_research_92: 'Datenüberprüfung',
  quantitative_research_93: 'Fragebogenprogrammierung und Testen',
  quantitative_research_94: 'Datenvalidierung',
  quantitative_research_95:
    'Audioaufnahmen unter Einhaltung der GDPR-Anforderungen',
  quantitative_research_96: 'Ausbildung und Kompetenz des Forschungspersonals',
  quantitative_research_97: 'Qualitätssicherung',
  quantitative_research_98: 'Faktorenanalyse',
  quantitative_research_99: 'Segmentierung',
  quantitative_research_100: 'Wahrnehmbare Zuordnung',
  quantitative_research_101: 'Korrelation',
  quantitative_research_102: 'Umwandlungsmodell',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Cluster-Analyse',
  quantitative_research_105: 'usw.',
  quantitative_research_106: 'Analytische Berichte',
  quantitative_research_107:
    'Wir verwenden SPSS® und andere Instrumente, um Analyseberichte mit unterschiedlichen Methoden zu erstellen',
  quantitative_research_108: 'What business challenges сan be solved:',
  quantitative_research_109: 'What business challenges сan be solved:',
  social_responsibility_1: 'Soziale Unternehmensverantwortung von 4Service',
  social_responsibility_2:
    'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
  social_responsibility_3: 'Bildungsaktivitäten/ Jugendunterstützung',
  social_responsibility_4:
    'Unsere Öffentlichkeitsarbeit für Kinder und Studenten',
  social_responsibility_5: 'Businessspiel «ServiceMania»',
  social_responsibility_6:
    'Ein wichtiger Bestandteil unserer Trainingsaktivitäten ist das Businessspiel »ServiceMania».',
  social_responsibility_7:
    '«Service Mania zwingt Sie dazu, strategisch zu überlegen, wie Sie mit ungewöhnlichen Situationen umgehen.',
  social_responsibility_8: 'Forschungspräsentation/ Diia.Business',
  social_responsibility_9:
    'Eine Geschäfts- und Kundenperspektive auf einer Servicesituation: eine Präsentation der 4Service Forschungsergebnisse von Victoria Skorbota, Entwicklungsleiterin bei 4Service Ukraine',
  social_responsibility_10: 'Sitzungen zum Service-Design',
  social_responsibility_11:
    'Die Service-Design Methodik ist ein wichtiger Bestandteil von 4Service. Vor einigen Jahren wurden wir Partner der DesignThinkers Academy und wir wenden nicht nur die besten Praktiken in unserer Arbeit an, sondern führen auch aktiv Schulungen für die besten Unternehmen durch',
  social_responsibility_12: 'Wohltätigkeit/ Freiwilligenarbeit',
  social_responsibility_13:
    'Waisenhäuser, Altenheime und Freiwilligendienste für die ukrainische Armee. Der berührendste Teil unserer Tätigkeit',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Wir arbeiten mit dem Blagomai Wohltätigkeitsfonds zusammen und versuchen, den Menschen in Not Glück und Hilfe zu geben',
  social_responsibility_16: 'Spendensammlung für Waisenhäuser und Altersheime',
  social_responsibility_17:
    'Wir haben mehrere Spendenaktionen für Altersheime und Waisenhäuser initiiert, um Grundbedarfsartikel zu kaufen',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'Viele unserer Mitarbeiter und engen Freunde sind vom Krieg betroffen. Jeder von uns hilft so viel er kann',
  social_responsibility_20: 'Tierschutz',
  social_responsibility_21:
    'Jeder kann mit seinem Haustier zu uns ins Büro kommen. Wir helfen Tierheimen, sammeln Spenden für Futter und suchen manchmal auch ein Zuhause für heimatlose Tiere.',
  social_responsibility_22: 'Unterstützung von Tierheimen',
  social_responsibility_23:
    'Obdachlose Tiere brauchen ebenso Hilfe wie alle schutzbedürftigen Mitglieder unserer Gesellschaft.',
  social_responsibility_24:
    'Unsere Firma hat das Tierheim Sirius Stray Animal Shelter (Kyiv Region, Ukraine) in unsere Obhut genommen. ',
  social_responsibility_25:
    'Kreative Initiativen zur Hilfe für streunende Tiere',
  social_responsibility_26:
    'Wie können wir unsere Kunden und Partner auf das dringende Thema Tierheime aufmerksam machen?',
  social_responsibility_27: 'Tierfreundliches Büro',
  social_responsibility_28:
    'Viele unserer Mitarbeiter haben Haustiere. Wir haben ein gesundes Umfeld geschaffen, in dem jeder mit seinen Haustieren ins Büro kommen und sich wohlfühlen kann.',
  ux_testing_1: 'UX-Tests',
  ux_testing_1_1: 'UX-Tests',
  ux_testing_2:
    'Menschlicher und KI-basierter Ansatz zum Testen und Verbessern Ihrer Webseiten und Anwendungen',
  ux_testing_3: 'Was ist UX-Test?',
  ux_testing_4:
    'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren.',
  ux_testing_5: 'Die Navigation ist nicht sehr übersichtlich',
  ux_testing_6: 'Ich habe keine Ahnung, wie man bestellt',
  ux_testing_7: 'Zu wenig Fotos im Katalog',
  ux_testing_8: 'Sie werden:',
  ux_testing_9: 'Verbesserungsmöglichkeiten entdecken',
  ux_testing_10:
    'Probleme im Design und in der Kundeninteraktion identifizieren',
  ux_testing_11:
    'Herausfinden, auf welche Schwierigkeiten der Benutzer bei der Interaktion mit der Webseite stößt',
  ux_testing_12: 'Die Kundenreise beobachten',
  ux_testing_13: 'Erkennen, wann und warum Ihre Zielgruppe die Seite verlässt',
  ux_testing_14:
    'Definieren, welche Abschnitte am wenigsten und am meisten Interesse und Emotionen hervorrufen',
  ux_testing_15: 'Benutzerbasierte UX-Tests',
  ux_testing_16:
    '4Service liefert Ihnen echte menschliche Einblicke, die Sie benötigen, um kundenorientierte Webseiten und Anwendungen mit qualitativen und quantitativen Methoden zu erstellen.',
  ux_testing_17:
    'Ich verstehe die Verwendung der Webseite nicht. Wo ist das Menü versteckt?',
  ux_testing_18:
    'Ich denke, es gibt zu viel Platz zwischen den Buchstaben, der Text ist nicht lesbar',
  ux_testing_19: 'Die Bilder überlagern sich. Ist das so vorgesehen?',
  ux_testing_20:
    'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren',
  ux_testing_21: 'Forschungsdesign für benutzerbasierte UX-Tests:',
  ux_testing_22: 'Entdeckung der Verbesserungsmöglichkeiten',
  ux_testing_23:
    'Bestimmung von Art und Methode der Prüfung (qualitativ/ quantitativ)',
  ux_testing_24: 'Erstellung von Hypothesen und Nutzerszenarien',
  ux_testing_25: 'Analyse und Berichterstattung über Testergebnisse',
  ux_testing_26: 'Zwei Lösungen',
  ux_testing_27: 'Wir bieten zwei Ansätze für erfolgreiche UX-Tests an',
  ux_testing_28: 'Benutzerbasierte UX-Tests',
  ux_testing_29: 'KI + menschenbasierte UX-Tests',
  ux_testing_30: 'Unsere Stärken:',
  ux_testing_31: 'Wir können das Wettbewerbsumfeld analysieren',
  ux_testing_32:
    'Wir können ausgewählte Experten hinzuziehen, um Interviews durchzuführen und daran teilzunehmen',
  ux_testing_33:
    'Wir führen Interviews in verschiedenen Sprachen und übersetzen sie in die gewünschte Sprache',
  ux_testing_34: 'mit vorhandenen Nutzbarkeit-Metriken',
  ux_testing_35: 'oder Standards',
  ux_testing_36: 'Ihre Leistung ',
  ux_testing_37: 'im Vergleich zu einem Mitbewerber',
  ux_testing_38: 'Versionen ',
  ux_testing_39: 'des gleichen Produktes',
  ux_testing_40: 'Zufriedenheitsbewertung',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Erfolgsrate',
  ux_testing_43: 'Fehlerrate',
  ux_testing_44: 'Abschlusszeit der Aufgabe',
  ux_testing_45: 'Benutzerbasierte UX-Tests',
  ux_testing_46: 'Qualitative Methode',
  ux_testing_47:
    'Wir setzen mindestens 100 Personen ein, um an UX-Tests für diese Phase teilzunehmen',
  ux_testing_48:
    'Es ist relevant, wenn Sie einen Vergleich Ihrer Webseite oder App benötigen',
  ux_testing_49: 'Maße der Quantitativen Methode:',
  ux_testing_50: 'Beobachtung',
  ux_testing_51:
    'Verbringen der Zeit mit einem Benutzer oder einer Gruppe von Benutzern und beobachten ihr Verhalten mit dem Produkt bei der täglichen Verwendung',
  ux_testing_52: 'Tiefeninterview',
  ux_testing_53:
    'Sie können die Einstellungen, Überzeugungen, Wünsche und Erfahrungen der Benutzer kennenlernen, die Ihre Webseite besuchen. Wir empfehlen 15-20 Interviews zu führen',
  ux_testing_54: 'Ethnografische Forschung',
  ux_testing_55:
    'Durchgeführt in der Umgebung der Befragten, wo sie das Produkt verwenden werden. Durch das Beobachten bekommen Sie ein gutes Verständnis für die Psychologie des Benutzers, emotionale Herausforderungen, denen er gegenübersteht',
  ux_testing_56: 'Benutzerbasierte UX-Tests',
  ux_testing_57: 'Qualitative Methode',
  ux_testing_58:
    'Diese Methode hilft, die Motivationen und die Logik des Benutzerverhaltens zu verstehen.',
  ux_testing_59: 'Wir verwenden:',
  ux_testing_60:
    'Wir entwickeln eine Karte der Kundenreise, um die Ergebnisse unserer Forschung zu veranschaulichen.',
  ux_testing_61: '30 Teilnehmer (männlich/weiblich)',
  ux_testing_62:
    'Die Befragten bestehen eine Vorabprüfung und werden zur Teilnahme an der Umfrage eingeladen.',
  ux_testing_63: 'Remote Online-Interviews auf der Wantent-Plattform',
  ux_testing_64: 'KI + menschenbasierte',
  ux_testing_65: 'KI + menschenbasierte UX-Tests',
  ux_testing_66:
    'Eine Kombination aus einzigartiger Technologie der künstlichen Intelligenz Wantent und echten Benutzern',
  ux_testing_67:
    'Die große Schrift in der Überschrift hat Aufmerksamkeit erregt',
  ux_testing_68:
    'Nutzer schauen auf die «laut sprechenden» Zahlen, lesen aber die Informationen daneben nicht «г»',
  ux_testing_69:
    'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren.',
  ux_testing_70: 'Forschungsdesign für KI + menschenbasierte Tests',
  ux_testing_71: 'Wie es funktioniert',
  ux_testing_72: 'Wantent ist eine KI-basierte Lösung für den Content-Markt.',
  ux_testing_73:
    'Wantent schätzt Effizienz von Webseiten und Apps durch den Einsatz von ML-Technologien zur Analyse der emotionalen Reaktionen und des Engagements der Zielgruppe.',
  ux_testing_74:
    'Um die Ergebnisse zu interpretieren, nutzen wir Storytelling-Techniken, Diversität und Empathie, um Leistung und Wachstum voranzutreiben.',
  ux_testing_75:
    'Aufmerksamkeit und Emotionen des Benutzers beim Laufen durch Abschnitte',
  ux_testing_76:
    'Statistiken nach Teilnehmergruppe (Aufmerksamkeit und Fokus während der gesamten Sitzung)',
  ux_testing_77: 'Analyse der Seitenumwandlungen',
  ux_testing_78: 'Aufmerksamkeit und Emotionen während der Aufgabenerfüllung',
  ux_testing_79: 'Erkenntnisse zur Wahrnehmung ',
  ux_testing_80: 'der Webseite durch verschiedene Teilnehmergruppen',
  ux_testing_81: 'Analyse der Leichtigkeit ',
  ux_testing_82:
    'der Navigation durch Seiten und der Leichtigkeit, wichtige Elemente zu finden',
  ux_testing_83: 'Analyse der Wahrnehmung ',
  ux_testing_84: 'der auf der Webseite bereitgestellten Informationen',
  ux_testing_85: 'Definierung ',
  ux_testing_86:
    'der Verbesserungsbereiche innerhalb einzelner Seiten (Navigation, Infoblöcke usw.)',
  ux_testing_87: 'Wir analysieren im Detail',
  ux_testing_88: 'Wichtigste Ergebnisse der Forschung',
  ux_testing_89:
    'Empfehlungen zur Verbesserung der Struktur der Webseite und einzelner Seiten',
  ux_testing_90: 'Wir wählen die Zielgruppe für UX-Tests sorgfältig aus.',
  ux_testing_91:
    'Wir forschen in verschiedenen Ländern, auf verschiedenen Sprachen und unter verschiedenen Nutzergruppen',
  ux_testing_92: 'Wir können Anwendungen',
  ux_testing_93:
    'und Webseiten von Wettbewerbern testen und Vergleichsanalyse bereitstellen',
  ux_testing_94: 'Wir kombinieren verschiedene Forschungsmethoden.',
  ux_testing_95:
    'Unsere qualifizierten Moderatoren begleiten den Teilnehmer durch den Testprozess.',
  ux_testing_96: 'Wir testen ',
  ux_testing_97: 'auf einer Vielzahl von Geräten und Softwareversionen',
  ux_testing_98: 'Warum sollten Sie uns wählen',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Ein einzigartiges Partnerprojekt von 4Service und Wantent © Eine Kombination aus künstlicher Intelligenz und einem menschenbasierten Ansatz zur Content Analyse',
  wantent_4: 'Messen des Erfolgs, bevor der Content ausgestrahlt wird',
  wantent_5: 'Minderung der Risiken von Diversitätsproblemen',
  wantent_6: 'tiefe Einblicke und Empfehlungen zur Verbesserung des Contents',
  wantent_7: 'objektive Entscheidungen über Content und Marketingstrategie',
  wantent_8: 'Wantent ist eine KI-basierte Lösung für den Content-Markt.',
  wantent_9:
    'Wantent ist eine auf künstlicher Intelligenz basierende Lösung, um Content an den Markt anzupassen. Wantent wertet die Effektivität von Videoinhalten aus, indem es ML-Technologien anwendet, um die emotionale Reaktion und das Engagement des Publikums zu analysieren.',
  wantent_10:
    'Wantent hilft dabei, die Eignung für den Content-Markt zu definieren und die Content-Effizienz zu maximieren durch:',
  wantent_11: 'Szenario Aufbau & Teilnehmerrekrutierung',
  wantent_12: 'personalisiertes Projektdesign und Publikum weltweit',
  wantent_13: 'Sammeln der Zuschauerreaktionen',
  wantent_14: 'detailliertes und klares Feedback vom Publikum',
  wantent_15: 'Analyse der Content Wahrnehmung',
  wantent_16: 'Verhaltens- und emotionale Reaktionsanalyse durch KI',
  wantent_17:
    'Bereitstellung von Erkenntnissen für die abschließende Zusammenfassung',
  wantent_18: 'Empfehlungen für Verbesserungen',
  wantent_19: 'Wantent maximiert Ihre Content-Effizienz',
  wantent_20: 'Vorabtest des Anzeigenkonzepts',
  wantent_21: 'Testen kreativer Konzepte',
  wantent_22:
    'Überprüfung der Konsistenz der zukünftigen kreativen mit der entwickelten Strategie und Verständnis der Reaktionen der Zielgruppe für mögliche Anpassungen der kreativen Konzepte/Meldungen',
  wantent_23: 'Sichtbarkeitstest der Markenelemente (Postproduktion)',
  wantent_24:
    'Bestätigung der Wirksamkeit der Investition für Werbung/Sponsoring',
  wantent_25: 'Anzeigentests',
  wantent_26: 'A/B-Tests',
  wantent_27:
    'Vergleich der Effektivität vom Kreativen und Erhöhung des Engagements und der Aufmerksamkeit während der Produktionsphase',
  wantent_28: 'Einführung von Anzeigentests',
  wantent_29:
    'Definieren der Leistung von Nachrichten und Erstellen von Empfehlungen zur Verbesserung der Audio- und Bildqualität',
  wantent_30: 'Lösungen für TV',
  wantent_31: 'Pilotprojekte, Promos, neue Formate, Tests von TV-Moderatoren',
  wantent_32:
    'Durchführen einer vergleichenden Analyse, um das ansprechendste zu identifizieren und zu stärken',
  wantent_33: 'Testen von Fernsehsendungen und Spielfilmen',
  wantent_34:
    'Analyse der Beteiligung, des Aufmerksamkeitsniveaus und der emotionalen Reaktion der Zuschauer während des Betrachtens langer Contents unter natürlichen Bedingungen. Wir finden automatisch die Spitzen der Aufmerksamkeit des Publikums und Momente der Ablenkung, um die Videomontage zu bewerten und zu verbessern',
  wantent_35: 'UI/UX-Tests',
  wantent_36: 'Webseite-Prototypen und Testen von mobilen Anwendungen',
  wantent_37:
    'Analyse von Benutzerreaktionen und Emotionen mit Wantent-Technologie:',
  wantent_38: 'Aufmerksamkeitsgrad',
  wantent_39: 'emotionale Reaktionen',
  wantent_40: 'Heatmaps der Blickrichtung der Teilnehmer',
  wantent_41:
    'Analyse der Benutzerfreundlichkeit von Webseiten und Anwendungen basierend auf dem Feedback der Teilnehmer:',
  wantent_42: 'Skala der Systembenutzbarkeit (SUS)',
  wantent_43: 'Client Effort Score (Benutzerfreundlichkeit)',
  wantent_44: 'Net Promotion Score (NPS)',
  wantent_45: 'GDPR-Konformität und Datenschutz der Benutzer',
  wantent_46:
    'Wantent ist vollständig konform mit den GDPR- und CCPA-Richtlinien und erfüllt die Grundsätze der Erhebung, Speicherung, Verarbeitung und des Schutzes der personenbezogenen Daten der Teilnehmer',
  wantent_47: 'Mykyta Lobyntsev',
  wantent_48: 'Chief Digital Officer, Reface (Medien & Unterhaltung)',
  wantent_49:
    'Wir experimentieren jeden Tag mit unzähligen neuen Content-Ideen mit maschinellen Lerntechnologien. Wantent hilft uns, Risiken und Vorteile zu verstehen und zu bewerten, das Feedback, das Verhalten und die Wahrnehmung der Benutzer zu entdecken, um die Kausalität verschiedener Aspekte unserer Lösungen zu ermitteln.',
  wantent_50: 'Oleksandr Smirnov',
  wantent_51: 'Miteigentümer TABASCO (Werbung)',
  wantent_52:
    'Wantent bietet einen wirklich einzigartigen Service, der uns hilft, sehr kleine Details darüber zu erfahren, was Verbraucher mögen und was nicht. Es gibt keine Möglichkeit zu schummeln, es gibt keine Möglichkeit zu lügen - Wantent kennt immer die Wahrheit, und hiermit erhalten wir als Marketingexperten die unbezahlbaren (hahaha - wir kennen den genauen Preis und es ist erschwinglich) Richtlinien, wie wir unsere Kommunikation verbessern können.',
  wantent_53: 'Rezensionen',
  terms_1: 'Allgemeine Geschäftsbedingungen',
  terms_2: 'Nutzungsbestimmungen',
  terms_3:
    'Bitte lesen Sie diese Nutzungsbestimmungen („Nutzungsbedingungen“, „Vereinbarung“) sorgfältig durch, bevor Sie die Webseite nutzen.',
  terms_4:
    '(«Webseite») betrieben von 4Service Holdings GmbH («4Service», «uns», «wir», «unser»). «c»',
  terms_5:
    'Nutzungsbedingungen: Durch die Nutzung dieser Webseite bestätigen Sie, dass Sie diese Vereinbarung gelesen haben und damit einverstanden sind, ihre Bedingungen einzuhalten. Wenn Sie nicht an die Bedingungen dieser Vereinbarung gebunden sein möchten, wird Ihnen empfohlen, die Webseite dementsprechend zu verlassen. 4Service gewährt nur denjenigen die Nutzung und den Zugriff auf diese Webseite, ihre Produkte und ihre Dienstleistungen, die seine Bedingungen akzeptiert haben.',
  terms_6: 'Datenschutz',
  terms_7:
    'Bevor Sie unsere Webseite weiter nutzen, empfehlen wir Ihnen, unsere Datenschutzrichtlinie bezüglich unserer Benutzerdatenerfassung zu lesen. Sie hilft Ihnen, unsere Praktiken besser zu verstehen.',
  terms_8: 'Altersbeschränkung',
  terms_9:
    'Sie müssen mindestens 16 (sechzehn) Jahre alt sein, um diese Webseite nutzen zu dürfen. Durch die Nutzung dieser Webseite garantieren Sie, dass Sie mindestens 16 (sechzehn) Jahre alt sind und diese Vereinbarung rechtlich einhalten können. 4Service übernimmt keine Verantwortung für Haftungen im Zusammenhang mit Altersangaben.',
  terms_10: 'Geistiges Eigentum',
  terms_11:
    'Sie stimmen zu, dass alle auf dieser Webseite bereitgestellten Materialien, Produkte und Dienstleistungen Eigentum von 4Service, seinen verbundenen Unternehmen, Direktoren, leitenden Angestellten, Mitarbeitern, Vertretern, Lieferanten oder Lizenzgebern sind, einschließlich aller Urheberrechte, Geschäftsgeheimnisse, Marken, Patente und anderen geistigen Eigentums. Sie stimmen auch zu, dass Sie das geistige Eigentum von 4Service in keiner Weise reproduzieren oder weiterverbreiten werden, einschließlich elektronischer, digitaler oder neuer Markenregistrierungen.',
  terms_12: 'Zugriff auf unsere Webseite',
  terms_13:
    'Der Zugriff auf unsere Webseite ist vorübergehend gestattet, und wir behalten uns das Recht vor, den Service, den wir auf unserer Webseite anbieten, ohne Vorankündigung zurückzuziehen oder zu ändern. Wir haften nicht, wenn unsere Webseite aus irgendeinem Grund zu irgendeinem Zeitpunkt oder für einen bestimmten Zeitraum nicht verfügbar ist. Von Zeit zu Zeit können wir den Zugriff auf einige Teile unserer Webseite oder unsere gesamte Webseite auf Benutzer beschränken, die sich bei uns registriert haben. Sie sind dafür verantwortlich, alle Vorkehrungen zu treffen, die für den Zugriff auf unsere Webseite erforderlich sind, einschließlich der Verwendung von Geräten, die mit unserer Webseite kompatibel sind. Sie sind auch dafür verantwortlich sicherzustellen, dass alle Personen, die über Ihre Internetverbindung auf unsere Webseite zugreifen, diese Bedingungen kennen und sie einhalten.',
  terms_14: 'Geltendes Recht',
  terms_15:
    'Durch den Besuch dieser Webseite stimmen Sie zu, dass die Gesetze der Republik Österreich, ohne Rücksicht auf Grundsätze des Kollisionsrechts, diese Allgemeinen Geschäftsbedingungen oder alle Streitigkeiten jeglicher Art, die zwischen 4Service und Ihnen entstehen könnten, regeln.',
  terms_16: 'Streitigkeiten',
  terms_17:
    'Alle Streitigkeiten im Zusammenhang mit Ihrem Besuch dieser Webseite werden vom Handelsgericht Wien geschlichtet.',
  terms_18: 'Entschädigung',
  terms_19:
    'Sie stimmen zu, 4Service und seine verbundenen Unternehmen schad- und klaglos zu halten und 4Service von Rechtsansprüchen und Forderungen freizustellen, die sich aus Ihrer Nutzung oder Ihrem Missbrauch unserer Webseite ergeben können. Wir behalten uns das Recht vor, unseren eigenen Rechtsbeistand auszuwählen.',
  terms_20: 'Dauer der Speicherung',
  terms_21:
    'Unter Berücksichtigung der Verarbeitungszwecke beträgt die Speicherdauer der personenbezogenen Daten der Nutzer (Speicherdauer) 24 Monate ab dem Datum, an dem die ordnungsgemäße Einwilligung zur Datenverarbeitung von Ihnen eingeholt wurde.',
  terms_22: 'Haftungsbeschränkung',
  terms_23:
    '4Service haftet nicht für Schäden, die Ihnen durch den Missbrauch unserer Webseite entstehen können. 4Service behält sich das Recht vor, diese Vereinbarung jederzeit ohne Vorankündigung zu bearbeiten, zu modifizieren und zu ändern, indem Sie diese Veröffentlichung aktualisieren. Ihre fortgesetzte Nutzung der Webseite jetzt oder nach der Veröffentlichung von Änderungen oder Modifikationen zeigt Ihre Zustimmung zu solchen Änderungen oder Modifikationen. Wenn ein Teil dieser Vereinbarung für rechtswidrig, nichtig oder nicht durchsetzbar erklärt wird, gilt dieser Teil als trennbar und berührt nicht die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen. Mögliche Beweise für die Nutzung der Webseite für illegale Zwecke werden den Strafverfolgungsbehörden zur Verfügung gestellt. Diese Vereinbarung ist eine Vereinbarung zwischen 4Service und dem Benutzer.',
  terms_24:
    'Bitte richten Sie alle Fragen und Anliegen im Zusammenhang mit Datenschutz/ Nutzung an folgende Adresse:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstraße 7',
  terms_27: '1010 Wien',
  terms_28: 'Österreich',
  terms_29: 'Überprüfung',
  terms_30:
    'Wenn die personenbezogenen Daten, die Sie uns zur Verfügung stellen, nicht richtig sind, haben Sie das Recht, uns zu bitten, sie tatsächlich zu korrigieren (GDPR Artikel 16).',
  thank_you_1: 'Vielen Dank!',
  thank_you_2: 'Wir werden uns innerhalb von 24 Stunden bei Ihnen melden',
  thank_you_3:
    'P.S. Unsere Manager prüfen bereits Ihre Anfrage, um ein besseres Angebot zusammenzustellen',
  thank_you_4: 'Menü',
  thank_you_5: 'Die Frau tippt',
  privacy_policy_1:
    'Datenschutzerklärungsformular für betroffene Personen (Benutzer der Webseite)',
  privacy_policy_2:
    'Diese Datenschutzerklärung (Privacy Notice) richtet sich an natürliche Personen (betroffene Personen), deren personenbezogene Daten von der 4Service Holdings GmbH gemäß den Anforderungen der GDPR im Rahmen des Besuchs der Webseite erhoben werden.',
  privacy_policy_3:
    '(hier nach Webseite bzw. Benutzer der Webseite/ Benutzer).',
  privacy_policy_4:
    'Das Formular der Datenschutzerklärung für betroffene Personen (Benutzer der Webseite) der 4Service Holdings GmbH gilt in allen Unternehmen der 4Service-Gruppe, die der rechtlichen Autorität unter der Aufsicht der 4Service Holdings GmbH unterliegen oder von ihr kontrolliert werden.',
  privacy_policy_5: 'Kontaktdaten',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Firma/wir/uns),',
  privacy_policy_8: 'Adresse:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Wien, Österreich.',
  privacy_policy_10: 'Email:',
  privacy_policy_12: 'Wer sind wir?',
  privacy_policy_13:
    'Eine Einzelperson (Sie), die die Webseite zu irgendeinem Zweck besucht. Für die Zwecke dieses Dokuments werden Sie auch als Benutzer bezeichnet.',
  privacy_policy_14: 'Personenbezogene Daten, die wir von Ihnen erheben',
  privacy_policy_15:
    'Wenn Sie unsere Webseite öffnen, senden wir Ihnen eine Datenschutzerklärung, in der wir Sie bitten, uns Ihre Zustimmung zur Verarbeitung der folgenden Informationen zu erteilen:',
  privacy_policy_16:
    'IP-Adresse, Benutzername, Nachname, Adresse, Telefonnummer (Festnetz oder Mobil), E-Mail-Adresse, Adresse, Firmenname, Land, E-Mail-Adresse, Vorname, Nachname, Telefonnummer, Provinz, Bundesland und Postleitzahl, Nutzungsdaten, Daten über die Interaktion mit externen sozialen Netzwerken oder Plattformen, Informationen zur Registrierung und Authentifizierung auf der Webseite',
  privacy_policy_17: 'geografische Lage.',
  privacy_policy_18:
    'Falls Sie Ihren Willen äußern und uns eine solche Zustimmung geben, beginnen wir mit der Verarbeitung dieser Informationen von Ihnen.',
  privacy_policy_19: 'Rechtmäßigkeit (Einwilligung)',
  privacy_policy_20:
    'Wenn Sie unsere Webseite öffnen, senden wir Ihnen eine Datenschutzerklärung, in der wir Sie bitten, uns Ihre Zustimmung zur Verarbeitung der folgenden Informationen zu erteilen:',
  privacy_policy_21:
    'Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist die Einwilligung, die wir von Ihnen erhalten, indem Sie das Einwilligungsformular unter folgendem Link ausfüllen:',
  privacy_policy_22:
    'Wenn Sie das Einwilligungsformular ausfüllen, bedeutet dies, dass Sie alle in dieser Datenschutzerklärung aufgeführten Bedingungen verstehen und akzeptieren.',
  privacy_policy_23: 'Widerruf der Einwilligung',
  privacy_policy_24:
    'Sie haben das Recht, eine früher erteilte Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Sie können Ihre Einwilligung widerrufen, indem Sie uns eine entsprechende Anfrage an folgende E-Mail-Adresse withdraw@4service-group.com senden, wobei das Anfrageformular unter folgendem Link verfügbar ist:',
  privacy_policy_25: 'Widerrufsformular für Benutzer',
  privacy_policy_26: 'Zwecke der Verarbeitung',
  privacy_policy_27:
    'Wir verarbeiten Ihre personenbezogenen Daten zu folgenden Zwecken:',
  privacy_policy_28:
    '– Verbesserung der Kundendienstleistungen (Ermöglicht eine effektivere Reaktion auf Kundenanfragen); – Personalisierung der Benutzererfahrung. (Es ermöglicht festzustellen, wer mehr an Dienstleistungen interessiert ist); – Verbesserung der Website. (Es ermöglicht die Verbesserung der Qualität von Produkten und Dienstleistungen, die Bequemlichkeit ihrer Nutzung, die Entwicklung neuer Dienstleistungen zur Verbesserung unserer Produkte und Dienstleistungen); – Kommunikation mit dem Benutzer durch Newsletter, Marketing- oder Werbematerialien und andere Informationen, die unsere Neuigkeiten, Aktualisierungen, Informationen über Dienstleistungen enthalten, mit dem Hinweis auf die Anweisung, wie man den Erhalt von Folge-E-Mails ablehnt. – Durchführung statistischer und anderer Arten von Forschung und Analyse auf der Grundlage anonymisierter Daten. – Bereitstellung personalisierter Dienste für den Benutzer und Durchführung von Vereinbarungen und Verträgen; – Teilnahme des Benutzers an verschiedenen Projekten, die von uns über die Website durchgeführt werden, Beantwortung von Anfragen des Benutzers über die Website, Recherche, Führung von Konten und Aufzeichnungen und Förderung von Dienstleistungen.',
  privacy_policy_29:
    'Subjekte, an die die personenbezogenen Daten übermittelt werden können',
  privacy_policy_30:
    'Bei der Verarbeitung Ihrer personenbezogenen Daten übermitteln wir Ihre personenbezogenen Daten an die Stellen, die als Auftragsverarbeiter des Unternehmens handeln. Die Auftragsverarbeiter des Unternehmens handeln ausschließlich nach Weisung des Unternehmens. Die Einzelheiten der von den Auftragsverarbeitern des Unternehmens durchgeführten Maßnahmen und eine Liste dieser Auftragsverarbeiter finden Sie in der Datenschutzrichtlinie unter folgendem Link: Datenschutzrichtlinie',
  privacy_policy_31:
    'Die Länder, an die die personenbezogenen Daten übermittelt werden können',
  privacy_policy_32:
    'Das Unternehmen übermittelt Ihre personenbezogenen Daten auf Grundlage eines Angemessenheitsbeschlusses gemäß den Vorgaben der GDPR und der EU-Kommission. Weitere Informationen zur Übermittlung personenbezogener Daten in die USA finden Sie in der Datenschutzrichtlinie unter folgendem Link: Datenschutzrichtlinie.',
  privacy_policy_33: 'Dauer der Speicherung',
  privacy_policy_34:
    'Unter Berücksichtigung der Verarbeitungszwecke beträgt die Speicherdauer der personenbezogenen Daten der Nutzer (Speicherdauer) 24 Monate ab dem Datum, an dem die ordnungsgemäße Einwilligung zur Datenverarbeitung von Ihnen eingeholt wurde.',
  privacy_policy_35: 'Zugangsrecht',
  privacy_policy_36:
    'Sie haben das Recht zu erfahren, ob Sie betreffende personenbezogene Daten verarbeitet werden, und 2) wenn ja, auf diese Daten zuzugreifen, mit vielen zusätzlichen Bestimmungen, die in Artikel 15 der GDPR aufgeführt sind.',
  privacy_policy_37: 'Recht auf Berichtigung',
  privacy_policy_38:
    'Wenn die personenbezogenen Daten, die Sie uns zur Verfügung stellen, nicht richtig sind, haben Sie das Recht, uns zu bitten, sie tatsächlich zu korrigieren (GDPR Artikel 16).',
  privacy_policy_39: 'Recht auf Löschung (Recht auf Vergessen)',
  privacy_policy_40:
    'Sie haben das Recht, von uns zu verlangen, dass Ihre personenbezogenen Daten unverzüglich gelöscht werden, und wir sind verpflichtet, Ihre personenbezogenen Daten unverzüglich zu löschen, wenn die in Artikel 17 der GDPR genannten Gründe zutreffen.',
  privacy_policy_41: 'Recht auf Einschränkung der Verarbeitung',
  privacy_policy_42:
    'Sie haben ein Recht auf Einschränkung der Verarbeitung Ihrer personenbezogenen Daten mit mehreren Ausnahmen im Rahmen der GDPR, insbesondere angegeben in Artikel 18 der GDPR.',
  privacy_policy_43:
    'Wir sind verpflichtet, Sie darüber zu informieren, welche Daten erhoben werden, wie sie verwendet werden, wie lange sie aufbewahrt werden und ob sie an Dritte weitergegeben werden. Diese Informationen müssen prägnant und in einfacher Sprache übermittelt werden.',
  privacy_policy_44: 'Recht auf Datenübertragbarkeit',
  privacy_policy_45:
    'Sie dürfen Ihre personenbezogenen Daten für Ihre eigenen Zwecke über verschiedene Dienste hinweg erhalten und wiederverwenden.',
  privacy_policy_46: 'Widerspruchsrecht',
  privacy_policy_47:
    'Sie haben ein Recht, der Verarbeitung personenbezogener Daten, die von der Gesellschaft verarbeitet werden, zu widersprechen. Wir müssen die Verarbeitung personenbezogener Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder wenn die Verarbeitung der Geltendmachung oder Ausübung von Rechtsansprüchen dient.',
  privacy_policy_48:
    'Recht, nicht einer Entscheidung unterworfen zu werden, die ausschließlich auf einer automatisierten Verarbeitung beruht',
  privacy_policy_49:
    'Sie haben ein Recht, einer automatisierten Profilerstellung zu widersprechen, die ohne Ihre Einwilligung erfolgt. Hiermit haben Sie das Recht, dass Ihre personenbezogenen Daten unter menschlicher Beteiligung verarbeitet werden.',
  privacy_policy_50: 'Beschwerden',
  privacy_policy_51:
    'Falls Sie eine Beschwerde darüber einreichen möchten, wie Ihre personenbezogenen Daten von dem Unternehmen (oder den oben beschriebenen Auftragsverarbeitern) verarbeitet werden oder wie Ihre Beschwerde bearbeitet wurde, haben Sie ein Recht, eine Beschwerde direkt bei der Aufsichtsbehörde und dem Unternehmen einzureichen.',
  privacy_policy_52: 'Details für jeden dieser Kontakte sind:',
  privacy_policy_53: 'Aufsichtsbehörde:',
  privacy_policy_54: 'Österreichische Datenschutzbehörde',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Wien',
  privacy_policy_58: 'Österreich / Europa',
  privacy_policy_59: 'Unternehmen:',
  privacy_policy_60: '4Service Holdings GmbH (Unternehmen/wir/uns),',
  privacy_policy_61: 'Adresse: Tegetthoffstraße 7, 1010 Wien, Österreich.',
  privacy_policy_62: 'Email:',
  privacy_policy_63: 'Datenschutz und Datenschutzrichtlinie',
  privacy_policy_64:
    'Lesen Sie hier mehr darüber, wie und warum wir Ihre Daten verwenden: Datenschutzrichtlinie',
  privacy_policy_65: 'Genehmigung',
  privacy_policy_66:
    'Das Unternehmen hat alle internen Dokumente entwickelt, um die Rollen der Mitarbeiter in Bezug auf die Verarbeitung personenbezogener Daten innerhalb des Unternehmens zu definieren, insbesondere ist der Geschäftsführer für die Genehmigung und Überprüfung der Rechtmäßigkeit dieses Dokuments verantwortlich.',
  contacts_1: 'Für Kunden',
  contacts_2:
    'Für alle Arten von Unternehmen, Marktforschungsinstitute und große Unternehmen, Geschäftspartner und Medien.',
  contacts_3: 'Tegetthoffstraße 7, Wien, Österreich',
  contacts_4: 'Für Einkäufer',
  contacts_5:
    'Einheimische und Reisende, Autofahrer und Studenten, professionelle Testkäufer und Privatpersonen.',
  contacts_6: 'Kontaktieren Sie uns aus Ihrem Land:',
  contacts_7: 'Italien',
  contacts_8: 'den USA',
  contacts_9: 'Großbritannien',
  contacts_10: 'der Schweiz',
  contacts_11: 'der Niederlande',
  contacts_12: 'der Slowakei',
  contacts_13: 'Slowenien',
  contacts_14: 'Rumänien',
  contacts_15: 'AZ',
  contacts_16: 'der Ukraine',
  contacts_17: 'Kasachstan',
  contacts_18: 'Rest der Welt',
  menu_1: 'Karte der Kundenreise',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX-Tests',
  menu_6: 'HR-Forschung',
  menu_7: 'Qualitative Forschung',
  menu_8: 'Quantitative Forschung',
  menu_9: 'Mystery-Shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Preisüberwachung',
  menu_14: 'Über uns',
  menu_15: 'Kontaktdaten',
  menu_16: 'Blog',
  menu_17: 'Methoden',
  menu_18: 'Produkte',
  menu_19: 'Shoppers  platform',
  menu_20: 'CSR',
  nps_passives: 'Passive',
  nps_detractors: 'Kritiker',
  nps_promoters: 'Promoter',
  nps_a_score: 'eine Punktzahl',
  area: 'Bereich',
  products_tabs_1:
    'Um eine maximale Effizienz zu erreichen, führen wir auch komplexe Arbeiten durch:',
  products_tabs_2: 'Produkte',
  products_tabs_3:
    'Qualitative Forschung sammelt und analysiert nicht-numerische Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen.',
  products_tabs_4:
    'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen und Fragebögen gesammelt werden.',
  our_clients_1: 'Unsere Kunden',
  preview_cases_section_1: 'Fälle',
  preview_cases_section_2:
    'Öffnen Sie die Fälle, um weitere interessante Beiträge zu lesen',
  blog_1: 'Mitreißender Content-Blog',
  blog_2: 'Keine Beiträge verfügbar für Ihre Anfrage.',
  about: 'Über',
  why_us: 'Warum wir',
  about_us: 'Über uns',
  key_features: 'Hauptmerkmale',
  solutions: 'Lösungen',
  monitoring: 'Überwachung',
  how: 'Wie',
  how_it_works: 'Wie es funktioniert',
  when_to_use: 'Wann zu benutzen',
  mystery_shopping: 'Mystery Shopping',
  voicer: 'Die Stimme des Kunden',
  wantent: 'Testen von Videoinhalten',
  play4sales: 'Play4Sales',
  price_monitoring: 'Preisüberwachung',
  service_design: 'Service-Design',
  qualitative_research: 'Qualitative Forschung',
  quantitative_research: 'Quantitative Forschung',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automobil',
  hashtag_retayl: '#Einzelhandel',
  hashtag_all_posts: '#Alle_Beiträge',
  hashtag_feedback: '#Feedback',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Fälle',
  hashtag_market_research: '#Marktforschung',
  more: 'Mehr',
  speak_to_an_expert: 'Sprechen Sie mit einem Experten',
  book_consultancy: 'Bestellen Sie eine Beratung',
  read_all: 'Alles lesen',
  find_out_more: 'Mehr erfahren',
  address: 'Adresse',
  mon_fri: 'Mo-Fr',
  phone_number: 'Telefonnummer',
  flag: 'Flagge',
  scroll_to_left: 'nach links scrollen',
  online: 'online',
  tel: 'Tel.',
  email: 'Email',
  light_it_up: 'Beleuchte es',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Leiterin der Geschäftsentwicklung',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Senior Projektmanagerin',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Managerin für Geschäftsentwicklung',
  about_us_66: 'Dmytro Zhelevskiy',
  about_us_67: 'Betriebsleiter',
  about_us_roles_1: 'Management',
  about_us_roles_2: 'Team Global',
  about_us_roles_3: 'Produkteigentümer',
  ad_testing_1: 'Anzeigentests',
  ad_testing_2:
    'Eine Werbekampagne ist eine riesige Investition. Stellen Sie sicher, dass Ihre Anzeigen leistungsstark sind und maximale Kapitalrentabilität erzielen',
  ad_testing_3: 'Schloss',
  ad_testing_4: 'Branchen die wir bedienen',
  ad_testing_5:
    '4Service ist eine globale Customer Experience Forschungsholding',
  ad_testing_6:
    '4Service ist eine globale Marketingforschungs- und CX-Holding. Wir bieten fachkundige Datenerfassungsdienste für eine Vielzahl von Unternehmen an',
  ad_testing_7: 'Cash & Carry Geschäft',
  ad_testing_8: 'Einzelhandel',
  ad_testing_9: 'Elektronik',
  ad_testing_10: 'Luxus/Premium',
  ad_testing_11: 'Restaurants & Gastfreundschaft',
  ad_testing_12: 'Automobil',
  ad_testing_13: 'Reisen & Freizeit',
  ad_testing_14: 'Mode',
  ad_testing_15: 'Banken',
  ad_testing_16: 'Uhren & Schmuck',
  ad_testing_17: 'Haushalt & Haushaltsgeräte',
  ad_testing_18: 'Mitglied von Esomar',
  ad_testing_19:
    'Wir haben Werbetests für verschiedene Unternehmen in unterschiedlichen Bereichen durchgeführt',
  ad_testing_20: 'Sie brauchen Anzeigentests falls Sie',
  ad_testing_21: 'für ein neues Produkt oder eine neue Dienstleistung werben',
  ad_testing_22: 'ein sensibles Thema ansprechen',
  ad_testing_23:
    'versuchen, eine neue demografische Zielgruppe oder einen neuen Markt zu erreichen',
  ad_testing_24: 'über mehrere unterschiedliche Designoptionen sprechen',
  ad_testing_25:
    'einen Machbarkeitsnachweis für Managementteams oder Investoren benötigen',
  ad_testing_26: 'Sie erhalten Antworten auf die Fragen',
  ad_testing_27:
    'Welche Werbekanäle sind für diese Anzeigen am besten geeignet?',
  ad_testing_28:
    'Verwenden Sie richtige visuelle Elemente, um Informationen zu vermitteln?',
  ad_testing_29: 'Kommen Ihre Werbetexte und Nachrichten an?',
  ad_testing_30:
    'Welche Werbekanäle sind für diese Anzeigen am besten geeignet?',
  ad_testing_31: 'Was fällt zuerst auf?',
  ad_testing_32:
    'Sind Platzierung und Anordnung der Anzeige leicht verständlich?',
  ad_testing_33: 'Wir bewerten',
  ad_testing_34: 'Markenzeichen',
  ad_testing_35:
    'wie Ihre Marke mit der Werbung verknüpft ist und wie stark sie in Erinnerung bleibt',
  ad_testing_36: 'Kennzahlen',
  ad_testing_37: 'Einbindung',
  ad_testing_38: 'Erkennung',
  ad_testing_39: 'Fit',
  ad_testing_40: 'Kreativ',
  ad_testing_41: 'Wie Ihre Werbung auffällt und Aufmerksamkeit erregt',
  ad_testing_42: 'Kennzahlen',
  ad_testing_43: 'ERKENNUNG',
  ad_testing_44: 'LIKING',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVATION',
  ad_testing_47: 'Kundenerlebnis, emotionales Engagement',
  ad_testing_48:
    'Mithilfe von Technologien der künstlichen Intelligenz und erfahrenen Analysten analysieren wir die wahren Emotionen der Menschen und erhalten Einblicke in ihre Wahrnehmung von Werbung',
  ad_testing_49: 'Bewertung',
  ad_testing_50: 'Männlich',
  ad_testing_51: 'Weiblich',
  ad_testing_52: 'hohes Engagement',
  ad_testing_53: 'Aufmerksamkeit',
  ad_testing_54: 'Was hat dem Publikum gefallen und woran erinnert es sich?',
  ad_testing_55: 'Schauspielerin',
  ad_testing_56: 'Gleichwertigkeit',
  ad_testing_57: 'Musikalischer Hintergrund',
  ad_testing_58: 'Positive Gefühle',
  ad_testing_59: 'Menschen verschiedener Rassen und Aussehen',
  ad_testing_60: 'Außenaufnahmen',
  ad_testing_61:
    'Was hat dem Publikum NICHT gefallen und woran erinnert es sich nicht?',
  ad_testing_62:
    'Die Stimme des Sprechers stimmte nicht mit dem überein, was auf dem Bildschirm passierte.',
  ad_testing_63: 'Geringe Beachtung des Produkts',
  ad_testing_64: 'Zu dynamisch',
  ad_testing_65: 'Schwer zu verstehen',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Eindrücke',
  ad_testing_68: 'Shows',
  ad_testing_69: 'Konversion',
  ad_testing_70: 'Medien',
  ad_testing_71:
    'Wir messen die Effektivität einer Werbekampagne in Verbindung mit der Medienaktivität und berechnen die Auswirkungen von Medieninvestitionen in verschiedenen Kanälen auf die überwachten Ergebnisse.',
  ad_testing_72:
    'Wir führen Werbeforschung in allen Formaten, Plattformen oder Kanälen durch',
  ad_testing_73: 'Videoinhalt',
  ad_testing_74: 'draußen',
  ad_testing_75: 'Fernsehwerbung',
  ad_testing_76: 'Ausdruck',
  ad_testing_77: 'Digital',
  ad_testing_78: 'Werbekampagnen',
  ad_testing_79: 'POS-Anzeige und Werbung',
  ad_testing_80: 'Animation',
  ad_testing_81: 'Mit unserer Hilfe können Sie Ihre Werbung jederzeit testen',
  ad_testing_82: 'Konzept',
  ad_testing_83: 'Welche Ideen haben eine stärkere Kapazität?',
  ad_testing_84: 'Produktion in der Frühphase',
  ad_testing_85: 'Welche Animationen werden in Produktion gehen?',
  ad_testing_86: 'Produktion',
  ad_testing_87:
    'Wie bearbeitet man ein Video, damit es Aufmerksamkeit erregt?',
  ad_testing_88: 'Vormedien',
  ad_testing_89:
    'Welche Kommunikationskanäle funktionieren und welche Ergebnisse sind zu erwarten?',
  ad_testing_90: 'Anzeigennachtest',
  ad_testing_91: 'Produktion',
  ad_testing_92:
    'Ist meine Werbung ansprechend? Lässt die Effektivität der Kreativität mit der Zeit nach?',
  ad_testing_93: 'Vormedien',
  ad_testing_94:
    'Wie effektiv ist meine Werbung in Zahlen? Wie schneidet es im Vergleich zur Konkurrenz ab?',
  ad_testing_95: 'Anzeigenvortest',
  ad_testing_96:
    'Um Ihnen qualitativ hochwertige Daten zur Verfügung zu stellen, verwenden wir',
  ad_testing_97: 'quantitative Forschungsmethodik',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'qualitative Forschungsmethodik',
  ad_testing_100: 'Fokusgruppen',
  ad_testing_101: 'KI',
  ad_testing_102:
    'Wantent - Analyse der Reaktion des Zuschauers, um die Wirksamkeit des angesehenen Materials zu bewerten',
  ad_testing_103: 'Analytische Berichte, die Sie erhalten',
  ad_testing_104:
    'Unser Team aus Analysten, Medienexperten und Vermarktern erstellt und präsentiert Ihnen einen maßgeschneiderten Bericht mit detaillierten Empfehlungen',
  ad_testing_age: 'Alter',
  ad_testing_ad: 'Anzeige',
  automotive_industry_price_analysis_1:
    'Preisanalyse für die Automobilindustrie',
  automotive_industry_price_analysis_2:
    'Die Analyse von Wettbewerbspreisen kann dazu beitragen, Gewinneinbußen zu verhindern, Marktanteile zu erhöhen und Ihre Marktposition zu überwachen. Lassen Sie uns Ihnen bei der Auswahl einer erfolgreichen Wettbewerbsstrategie helfen!',
  automotive_industry_price_analysis_3: 'Angebot anfordern',
  automotive_industry_price_analysis_4: 'Unsere Methodik',
  automotive_industry_price_analysis_5:
    'Stilvoller, eleganter Autosalon für Menschen',
  automotive_industry_price_analysis_6:
    'Die Mystery-Shopping-Methode ist der einzige Ansatz, der eine reale Kundenerfahrung genau widerspiegelt und einen glaubwürdigen Überblick bietet.',
  automotive_industry_price_analysis_7:
    'Wir bieten präzise Zahlen und verlässliche multilaterale Daten',
  automotive_industry_price_analysis_8:
    'Wir haben eine globale Abdeckung, die jedes Land der Welt umfasst',
  automotive_industry_price_analysis_9:
    'Zu unseren internen Ressourcen gehören Testkäufer und Auditoren',
  automotive_industry_price_analysis_10:
    'Wir validieren die Qualität unserer Daten',
  automotive_industry_price_analysis_11: 'Unsere Vorteile:',
  automotive_industry_price_analysis_12:
    'Preisüberwachung für bestimmte Wettbewerbsmodelle (B2B und B2C)',
  automotive_industry_price_analysis_13: 'Überwachung der Höhe der Rabatte',
  automotive_industry_price_analysis_14:
    'Überwachung des Einzelhandelsbestands für Ihre Marke und Ihre Wettbewerber',
  automotive_industry_price_analysis_15:
    'Überwachung der Preise im elektronischen Handel mit Kraftfahrzeugen',
  automotive_industry_price_analysis_16: 'Analyse der Preiskommunikation',
  automotive_industry_price_analysis_17:
    'Verfolgung der Versandgebühren des Einzelhändlers',
  automotive_industry_price_analysis_18:
    'Überwachung von Werbeaktionen und Verkäufen im Einzelhandel',
  automotive_industry_price_analysis_19:
    'Bestimmung der monatlichen Zahlungsbeträge',
  automotive_industry_price_analysis_20: 'Berechnung der Leasingzahlungen',
  automotive_industry_price_analysis_21:
    'Analyse spezifischer Preisüberlegungen für Elektrofahrzeuge (EVs)',
  automotive_industry_price_analysis_22:
    'Durchführung von Preisvergleichen zwischen Einzelhändlern und Direktkunden',
  automotive_industry_price_analysis_23:
    'Entwicklung von Preismodellen für Abonnements',
  automotive_industry_price_analysis_24:
    '4Service ist eine globale Customer Experience (CX) Holding mit über 20 Jahren Erfahrung',
  automotive_industry_price_analysis_25:
    'Sie werden Daten über die Zahlungsbereitschaft der Verbraucher für verschiedene Merkmale nutzen, um Ihr Angebot zu optimieren und die wichtigsten Merkmale für Ihre Zielkunden zu ermitteln.',
  automotive_industry_price_analysis_26:
    'Die Preisanalyse von 4Service für die Automobilindustrie umfasst:',
  automotive_industry_price_analysis_27:
    'Niedrigste Preise sind nicht immer der Schlüssel zur Wettbewerbsfähigkeit von Automarken. Wir helfen Ihnen, eine langfristige Preisstrategie zu entwickeln',
  automotive_industry_price_analysis_28:
    'der Kunden wissen, dass die Preise bei allen Marken steigen',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'der Kunden gaben an, dass sie ein Auto, das ihnen gefällt, auch dann kaufen würden, wenn die Marke sie über eine bevorstehende Preiserhöhung informieren würde,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'der Menschen halten Leasing und monatliche Zahlungen für das bevorzugte Modell beim Autokauf',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'der Händler erwähnten die Möglichkeit von Preiserhöhungen während des Kommunikations-/Verkaufsprozesses',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Die wichtigsten Zahlen',
  automotive_industry_price_analysis_37:
    'Sie werden herausfinden, wie Sie den Preis für Ihr Produkt im Vergleich zu anderen Fahrzeugen der Produktpalette gestalten können.',
  automotive_industry_price_analysis_38:
    'Sie werden Daten über die Zahlungsbereitschaft der Verbraucher für verschiedene Merkmale nutzen, um Ihr Angebot zu optimieren und die wichtigsten Merkmale für Ihre Zielkunden zu ermitteln.',
  automotive_industry_price_analysis_39:
    'der Kunden gaben an, dass sie ein Auto, das ihnen gefällt, auch dann kaufen würden, wenn die Marke sie über eine bevorstehende Preiserhöhung informieren würde',
  automotive_industry_price_analysis_40:
    'Sie werden in der Lage sein, die angemessene monatliche Zahlung und die Leasingraten zu bestimmen.',
  automotive_industry_price_analysis_41:
    'Welche Vorteile ergeben sich für Ihre Marke oder Ihren Händler:',
  automotive_industry_price_analysis_42: 'Sie reichen einen Antrag ein',
  automotive_industry_price_analysis_43:
    'Unser Manager setzt sich mit Ihnen in Verbindung, um die wichtigsten Ziele festzulegen',
  automotive_industry_price_analysis_44: 'Wir führen eine Marktforschung durch',
  automotive_industry_price_analysis_45:
    'Wir stellen Ihnen regelmäßig Berichte und Empfehlungen in einem Online-Format zur Verfügung',
  automotive_industry_price_analysis_46: 'Auto mit Verkaufsaufkleber',
  automotive_industry_price_analysis_47: 'Wir starten das Projekt in 1 Tag!',
  automotive_industry_price_analysis_48:
    'Wir bieten eine umfassende Reihe von Berichten an, darunter Fotoberichte, PPT-Berichte und Excel-Berichte, die nach der Art der Örtlichkeit unterteilt sind',
  retail_audit_1: 'FIELD MARKETING AUDIT/Einzelhandelsaudit',
  retail_audit_2:
    'Sammeln Sie detaillierte Daten zu Ihrem Geschäft oder Ihrer Marke, analysieren       Sie Wachstumsbarrieren und entwickeln Sie wirksame Strategien zu deren Überwindung',
  retail_audit_3: 'Angebot anfordern',
  retail_audit_4:
    '4Service führt im Auftrag von Marken Filialaudits vor Ort durch, um die Wirksamkeit ihrer Werbestrategien, Außendienstteams und Marketingtaktiken bei der Erreichung des beabsichtigten Zielmarkts zu bewerten.',
  retail_audit_5:
    'Wir beobachten die Durchführung von Werbekampagnen akribisch und stellen die Einhaltung der Werberichtlinien sicher.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'CX-Agenturen in Europa',
  retail_audit_8: 'Jahre Erfahrung',
  retail_audit_9: 'Länderabdeckung',
  retail_audit_10: '1,6 Millionen',
  retail_audit_11: 'Auswertungen pro Jahr',
  retail_audit_12: 'Was verfolgen wir?',
  retail_audit_13: 'Vorhandensein von Point-of-Sale (POS)-Materialien',
  retail_audit_14:
    'Präsenz und Aktivierung von Markenbotschaftern, Sicherstellung der Einhaltung des geplanten Zeitplans',
  retail_audit_15:
    'Bewertung des Engagements von Markenbotschaftern in Advocacy-Kampagnen',
  retail_audit_16:
    'Bewertung der Verbraucherinteraktion, Sicherstellung der Einhaltung von Werberichtlinien und Markenbotschaften',
  retail_audit_17: 'Maßnahmen zur Betrugsprävention',
  retail_audit_18:
    'Ihr Unternehmen kann die Ergebnisse eines Feldmarketing-Audits nutzen, um',
  retail_audit_19: 'Einhaltung von Budget und Prozessen beurteilen',
  retail_audit_20: 'Ist-Ergebnisse mit angestrebten Zielen vergleichen',
  retail_audit_21: 'Die Leistung von Marketingkampagnen verbessern ',
  retail_audit_22: 'Strategisch für die Zukunft planen',
  retail_audit_23: 'Möglichkeiten zur Kostensenkung identifizieren',
  retail_audit_24: 'Verkäufe und Konversionsraten verbessern',
  retail_audit_25: 'Kapitalrendite (ROI) optimieren',
  retail_audit_26:
    'SIE HABEN SICH FÜR 4SERVICE ALS IHREN HANDELSINVESTITIONSPARTNER ENTSCHEIDEN',
  retail_audit_27:
    'Ein führender internationaler Hersteller von Tabakprodukten hat sich mit 4Service zusammengetan, um seine Marken zu stärken und die Leistung im Geschäft zu verbessern. Unsere Teams haben eine Marketingüberprüfung vor Ort und Live-Berichterstattung über die Kundenerfahrung durchgeführt.',
  retail_audit_28:
    'Steigerung der Planungsgenauigkeit der Feldmarketing-Agentur',
  retail_audit_29: 'Steigerung der Markenbotschaften',
  retail_audit_30: 'Steigerung der Mitarbeiterbindung (Kundenaktivierung)',
  retail_audit_31:
    'Steigerung der Genauigkeit der Datensammlung und Einhaltung der DSGVO',
  retail_audit_32: 'Welche wertvollen Erkenntnisse finden wir normalerweise:',
  retail_audit_33:
    'Mangelnde Konsistenz oder sogar Konflikte zwischen den Bedingungen des SLA führen zu erheblichen nicht genehmigten Kosten für den Kunden',
  retail_audit_34:
    'Fehlerhafte Gebührenberechnung aufgrund von Verwechslung der angebotenen Dienstleistungen',
  retail_audit_35:
    'Nichtdurchführung des Vor-Ort-Services, einschließlich Nichtzuweisung von Mitarbeitern zum geplanten Standort | POS',
  retail_audit_36:
    'Gebühren auf Basis einer Schätzung, anstatt auf Basis von tatsächlichen Daten und nicht abgeglichen',
  retail_audit_37:
    'Fehlerhafte Anwendung der Aktionsrichtlinie, die betrügerische Daten liefert',
  retail_audit_38: 'Wie arbeiten wir?',
  retail_audit_39:
    'Unser Auditor besucht ausgewiesene Point-of-Sale (POS)-Standorte basierend auf dem Aktivierungsplan der Agentur.',
  retail_audit_40:
    'Sie bewerten wichtige Leistungsindikatoren (KPIs), wie im Briefing festgelegt, wie die Präsenz am POS, die Mitarbeiterbindung, die Einhaltung der Aktionsrichtlinien und Maßnahmen zur Betrugsprävention.',
  retail_audit_41: 'Validierung:',
  retail_audit_42: 'Wir sammeln und konsolidieren die erhaltenen Daten.',
  retail_audit_43: 'Ein umfassender Bericht wird erstellt.',
  retail_audit_44:
    'Unser Validierungsteam überprüft alle Umfragen sorgfältig auf Genauigkeit, einschließlich der Einhaltung des Projektauftrags und etwaiger zusätzlicher Kommentare.',
  retail_audit_45: 'Online-Berichtssystem:',
  retail_audit_46:
    'Abgeschlossene und validierte Fragebögen werden innerhalb von 36 Stunden in unser Online-Portal hochgeladen.',
  retail_audit_47:
    'Kunden haben Zugriff auf ein persönliches Kontoportal, das es ihnen ermöglicht, den Fortschritt jederzeit zu überwachen.',
  retail_audit_48:
    'Ein Abschlussbericht mit Ergebnissen und Empfehlungen wird bereitgestellt.',
  retail_audit_49: 'Sprechen Sie mit einem Experten',
  retail_audit_50: 'Unsere Stärken:',
  parthership: 'Partnerschaft',
  subscribe: 'Subscribe with a click',
  footer_7: 'Market Research',
  menu_22: 'Facility Check Audit',
  menu_23: 'Service Design',
  menu_24: 'CX',
  facility_check_audit_1: 'Einrichtung',
  facility_check_audit_2: 'Prüfung Audit',
  facility_check_audit_3: 'Erhöhen Sie das Markenerlebnis in jedem Detail',
  facility_check_audit_4: 'Entdecken Sie unseren Ansatz',
  facility_check_audit_5: 'Über',
  facility_check_audit_6: 'About',
  facility_check_audit_7:
    'Als globale Holding für Kundenerlebnisse bietet 4Service eine umfassende Palette von Dienstleistungen, die Marken dabei helfen, außergewöhnliche Erlebnisse für ihre Kunden zu schaffen und zu liefern. ',
  facility_check_audit_8: 'Unser Facility Check Audit Service',
  facility_check_audit_9:
    'soll Ihnen dabei helfen, sicherzustellen, dass Ihre physischen Standorte und Ihre Markenpräsenz in den Einzelhandelsgeschäften Ihren Markenstandards entsprechen und einen positiven Eindruck bei den Kunden hinterlassen.',
  facility_check_audit_10: 'Lösungen 1',
  facility_check_audit_11: 'Facility Check Audit',
  facility_check_audit_12:
    'ist eine sorgfältige Prüfung, bei der die Ausrichtung Ihrer Einzelhandelsgeschäfte oder Stände an den Standards Ihrer Marke bewertet wird. Wir überprüfen nicht nur die physischen Attribute, sondern messen auch, wie diese Elemente die Wahrnehmung Ihrer Marke durch Ihre geschätzten Kunden beeinflussen.',
  facility_check_audit_13: 'Zwei Lösungen',
  facility_check_audit_14: 'Zwei Durchführungsmethoden',
  facility_check_audit_15: 'Methode',
  facility_check_audit_16: 'Facility Check Audit durch sachverständige Prüfer',
  facility_check_audit_17: 'Validierung des Facility Check',
  facility_check_audit_18: 'Berichte',
  facility_check_audit_19:
    'Sie erhalten Berichte mit visuellen Beweisen und Statistiken, die innerhalb von 48 Stunden zugänglich sind',
  facility_check_audit_20:
    'Ein gut durchgeführtes Facility Check Audit kann für Ihre Marke eine Reihe von Vorteilen bieten, darunter:',
  facility_check_audit_21: 'Verbesserte Kundenkonversion:',
  facility_check_audit_22:
    'Ein positives Kundenerlebnis kann zu höheren Umsätzen und Folgegeschäften führen. Unsere Audits können Ihnen helfen, alle Bereiche zu identifizieren und anzugehen, die die Kundenzufriedenheit beeinträchtigen könnten.',
  facility_check_audit_23: 'Verbessertes Markenimage:',
  facility_check_audit_24:
    'Ihre physischen Standorte sind ein wichtiger Berührungspunkt für Kunden und können eine wichtige Rolle bei der Gestaltung Ihres Markenimages spielen. Mit unseren Audits können Sie sicherstellen, dass Ihre Standorte mit Ihren Markenwerten übereinstimmen und einen positiven Eindruck bei den Kunden hinterlassen.',
  facility_check_audit_25: 'Warum wir?',
  facility_check_audit_26: 'Warum ist Facility Check Audit wichtig?',
  facility_check_audit_27:
    'Ein gut durchgeführtes Facility Check Audit kann für Ihre Marke eine Reihe von Vorteilen bieten, darunter:',
  facility_check_audit_28: 'Verbessertes Markenimage:',
  facility_check_audit_29:
    'Ihre physischen Standorte sind ein wichtiger Berührungspunkt für Kunden und können eine wichtige Rolle bei der Gestaltung Ihres Markenimages spielen. Mit unseren Audits können Sie sicherstellen, dass Ihre Standorte mit Ihren Markenwerten übereinstimmen und einen positiven Eindruck bei den Kunden hinterlassen.',
  facility_check_audit_30: 'Verbesserte Kundenkonversion:',
  facility_check_audit_31:
    'Ein positives Kundenerlebnis kann zu höheren Umsätzen und Folgegeschäften führen. Unsere Audits können Ihnen helfen, alle Bereiche zu identifizieren und anzugehen, die die Kundenzufriedenheit beeinträchtigen könnten.',
  facility_check_audit_32: 'Wer braucht',
  facility_check_audit_33: 'Wer braucht Facility Check Audit?',
  facility_check_audit_34:
    'Facility Check Audit ist eine wertvolle Dienstleistung für jede Marke mit physischen Standorten und physischer Präsenz. Er ist besonders wichtig für Marken, die:',
  facility_check_audit_35: 'Sicherstellung der Einhaltung von Markenstandards:',
  facility_check_audit_36:
    'Unsere Audits können Ihnen dabei helfen, alle Bereiche zu identifizieren, in denen Ihre Standorte von Ihren Markenstandards abweichen könnten.',
  facility_check_audit_37: 'Verbesserung der Kundenerfahrung:',
  facility_check_audit_38:
    'Unsere Audits können Ihnen dabei helfen, Möglichkeiten zur Verbesserung des Kundenerlebnisses an Ihren Standorten zu identifizieren.',
  facility_check_audit_39: 'Eine stärkere Marke aufbauen:',
  facility_check_audit_40:
    'Unsere Audits können Ihnen dabei helfen, sicherzustellen, dass Ihre physischen Standorte einen positiven Eindruck bei den Kunden hinterlassen.',
  facility_check_audit_41:
    'Ihr Mitarbeiter oder Testkäufer macht ein Foto von dem Ort.',
  facility_check_audit_42:
    'Dann füllen sie einen Fragebogen aus und fügen das Foto bei.',
  facility_check_audit_43:
    'Der Validator erhält das Foto und prüft es auf Übereinstimmung mit den Normen.',
  facility_check_audit_44: 'Facility Check Audit durch sachverständige Prüfer',
  facility_check_audit_45: 'Vorbereitungsphase:',
  facility_check_audit_46: 'Gemeinsame Genehmigung des Prüfungsplans',
  facility_check_audit_47:
    'Die Händler werden auf die bevorstehende Prüfung vorbereitet und darüber informiert',
  facility_check_audit_48: 'Auditorenschulung:',
  facility_check_audit_49:
    'Unsere erfahrenen 4Service-Trainer befähigen Auditoren mit Markenrichtlinien',
  facility_check_audit_50: 'Auditphase der Einrichtung:',
  facility_check_audit_51:
    'Auditoren, die mit speziellen Genehmigungsschreiben ausgestattet sind, besuchen Standorte',
  facility_check_audit_52:
    'Gründliche Prüfung jedes einzelnen Punktes unter sorgfältiger Einhaltung der Leitlinien',
  facility_check_audit_53:
    'Visuelle Dokumentation, neben der Bewertung anhand der HQ-Voraussetzungen',
  facility_check_audit_54: 'Abschließender PPT-Bericht',
  facility_check_audit_55:
    'Ein umfassender PowerPoint-Bericht, der die Ergebnisse erläutert',
  facility_check_audit_56:
    'Immersive Online-/Offline-Präsentation der geprüften Ergebnisse',
  facility_check_audit_57: 'Validierung des Facility Check',
  facility_check_audit_58: 'Schritte:',
  facility_check_audit_59: 'Auditphase der Einrichtung:',
  facility_check_audit_60:
    'Markenvertreter nutzen die 4Service-App, um Bilder vor Ort aufzunehmen',
  facility_check_audit_61:
    'Adherence to stringent visual and descriptive criteria',
  facility_check_audit_62: 'Validierungsphase:',
  facility_check_audit_63:
    'Sorgfältige Prüfer überprüfen jeden Bericht genauestens',
  facility_check_audit_64:
    'Umfassender Vergleich von Fotos mit Markenstandards',
  facility_check_audit_65:
    'In den Bericht integrierte Anmerkungen und Erkenntnisse für mehr Klarheit',
  facility_check_audit_66:
    'Direkte Kommunikation mit Händlern zur Klärung von Fragen, falls erforderlich',
  facility_check_audit_67: 'Berichtsphase:',
  facility_check_audit_68:
    'Berichte mit visuellen Beweisen und Statistiken, die innerhalb von zwei Tagen zugänglich sind',
  facility_check_audit_69: 'Kontaktieren Sie uns heute',
  cx_solution_1: 'Wir finden nicht nur Erkenntnisse,',
  cx_solution_2: 'Wir bringen Ihr Unternehmen zu neuen Höhen',
  cx_solution_3:
    'Experten mit mehr als 20 Jahren Kundenerfahrung entdecken komplexe Lösungen und bieten Ihnen einen konkreten Aktionsplan, der zu Ihrem Wachstum beiträgt',
  cx_solution_4:
    'Entdecken Sie ein einzigartiges Paket von Diensten in einer zukunftssicheren Lösung',
  cx_solution_5: 'All-in-one CX-Lösung',
  cx_solution_6: 'Werbetests',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Die Stimme des Kunden',
  cx_solution_9: 'Desk research',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'CX-Beratung',
  cx_solution_13: 'Zuhören in den sozialen Medien',
  cx_solution_14: 'Quantitative und qualitative Forschung',
  cx_solution_15: 'Service design',
  cx_solution_16: 'Marketing-Strategie',
  cx_solution_17: 'UX/UI testing',
  cx_solution_18: 'Publikumseinblicke',
  cx_solution_19: 'Es ist uns eine Ehre, zu Ihrem Erfolg beizutragen',
  cx_solution_20:
    'Wir passen die Tools und Dienstleistungen an den Zweck und die Ziele Ihres Unternehmens an und kombinieren sie, um maßgeschneiderte Ergebnisse zu erzielen',
  cx_solution_21: 'Umfassende Forschung',
  cx_solution_22:
    'Erleichtern Sie Ihre Entscheidungsfindung und verschaffen Sie sich einen Wettbewerbsvorteil in einem datenzentrierten Markt.',
  cx_solution_23:
    'Qualitative Forschung ist das Sammeln und Analysieren nicht-numerischer Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen',
  cx_solution_24:
    'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen, Fragebögen und Erhebungen gesammelt wurden',
  cx_solution_25:
    'Wir verwenden eine Reihe von Tools, die auf Ihre spezifischen Anforderungen abgestimmt sind',
  cx_solution_26: 'Tools',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Die computergestützte Webbefragung ist ein wichtiges Instrument in unserem Arsenal, das uns die Durchführung von Umfragen und die Erhebung von Daten über Online-Plattformen mit Hilfe spezieller Software ermöglicht und so eine effiziente und genaue Datenerhebung für die Marktforschungsanforderungen unserer Kunden gewährleistet',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Die computergestützte Telefonbefragung ist eine Methode, die wir zur Durchführung von Umfragen und zur Sammlung wertvoller Daten am Telefon einsetzen. Dabei kommt eine spezielle Software zum Einsatz, die den Prozess rationalisiert und die Genauigkeit unserer Marktforschungsbemühungen gewährleistet',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Face-to-Face ist ein wesentlicher Bestandteil unseres Ansatzes, der die direkte Interaktion und das persönliche Engagement mit Kunden und Auftraggebern erleichtert und so Vertrauen, Verständnis und effektive Kommunikation fördert, die für den Aufbau dauerhafter Beziehungen und den Geschäftserfolg unerlässlich sind',
  cx_solution_33: 'Desk Research',
  cx_solution_34:
    'Desk Research umfasst eine umfassende Datensammlung und -analyse und dient als grundlegender Schritt in unserem Forschungsprozess, um Erkenntnisse zu gewinnen und strategische Entscheidungen für unsere Kunden zu treffen',
  cx_solution_35: 'SWOT-Analyse',
  cx_solution_36:
    'TDie SWOT-Analyse ist ein strategisches Bewertungsinstrument, das unseren Kunden hilft, interne Stärken und Schwächen sowie externe Chancen und Gefahren zu erkennen und dadurch wertvolle Erkenntnisse für die Entscheidungsfindung und die Entwicklung effektiver Unternehmensstrategien zu gewinnen',
  cx_solution_37: 'Ethnografische Forschung',
  cx_solution_38:
    'Studium von Menschen und Kulturen durch Eintauchen und Beobachtung in ihrer natürlichen Umgebung, was oft zu tiefen Einsichten in Verhaltensweisen, Überzeugungen und soziale Dynamik führt',
  cx_solution_39: 'Ausführliche Interviews',
  cx_solution_40:
    'Tiefeninterviews sind eine von uns angewandte qualitative Forschungsmethode, die ausführliche Einzelgespräche mit den Teilnehmern einschließt, um deren Perspektiven, Erfahrungen und Meinungen zu vertiefen, was unseren Kunden reiche Einblicke und ein besseres Verständnis für ihre Forschungsziele und Entscheidungsprozesse ermöglicht',
  cx_solution_41: 'Ideensitzungen',
  cx_solution_42:
    'Ideation Sessions sind gemeinsame Brainstorming-Sitzungen, in denen wir verschiedene Perspektiven und Fachkenntnisse zusammenbringen, um kreative Ideen und Lösungen für die Herausforderungen und Chancen unserer Kunden zu entwickeln, Innovationen zu fördern und strategische Initiativen voranzutreiben',
  cx_solution_43: 'Buyer personas',
  cx_solution_44:
    'Buyer Personas sind detaillierte Profile, die wir auf der Grundlage von Forschung und Datenanalyse entwickeln und die die Zielkunden unserer Kunden repräsentieren. Sie ermöglichen es uns, ihre Bedürfnisse, Vorlieben und Verhaltensweisen besser zu verstehen und unsere Strategien und Kommunikation so zu gestalten, dass wir sie effektiv ansprechen und mit ihnen in Resonanz treten, was letztlich den Geschäftserfolg fördert',
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping ist eine strategische Dienstleistung, die wir anbieten, um die Interaktionen zwischen einem Kunden und einem Unternehmen während eines vordefinierten Szenarios zu messen und zu überwachen',
  cx_solution_47: 'Accompany Checking',
  cx_solution_48:
    'Accompany Checking ist eine spezielle Form des Mystery Shoppings, bei der unsere Prüfer die Kunden während ihres gesamten Serviceerlebnisses begleiten und umfassende Einblicke in jeden Aspekt der Customer Journey gewähren. So können unsere Kunden ein tiefes Verständnis für ihre Serviceleistungen gewinnen und fundierte Verbesserungen vornehmen, um die allgemeine Kundenzufriedenheit und -loyalität zu erhöhen',
  cx_solution_49: 'Analyse der gesammelten Rückmeldungen',
  cx_solution_50:
    'Die Analyse der Feedbacksammlung ist ein wichtiger Prozess, der die systematische Sammlung und Analyse von Kundenfeedback aus verschiedenen Kanälen wie Umfragen, Rezensionen und sozialen Medien umfasst und es uns ermöglicht, wertvolle Einblicke zu gewinnen, Trends zu erkennen und datengestützte Empfehlungen für unsere Kunden zu geben, um ihre Produkte, Dienstleistungen und das allgemeine Kundenerlebnis zu verbessern',
  cx_solution_51: 'Schwerpunktgruppen',
  cx_solution_52:
    'Fokusgruppen sind dynamische Forschungssitzungen, die wir moderieren und in denen eine Gruppe unterschiedlicher Teilnehmer bestimmte Themen oder Produkte eingehend diskutiert. Auf diese Weise können wir qualitative Einblicke gewinnen, Einstellungen, Präferenzen und Wahrnehmungen aufdecken und Ideen und Konzepte erforschen, die letztlich in die Entscheidungs- und Strategieentwicklungsprozesse unserer Kunden einfließen',
  cx_solution_53:
    '20 Jahre Erfahrung in der Gestaltung außergewöhnlicher Kundenerlebnisse',
  cx_solution_54:
    'Stellen Sie sicher, dass Ihre Botschaft fesselt und bei Ihrem Publikum ankommt.',
  cx_solution_55:
    'Wir sind bestrebt, Ergebnisse zu liefern, die Ihre Erwartungen übertreffen',
  cx_solution_56:
    'Wir entdecken komplexe Lösungen, die zu Ihrem Wachstum beitragen',
  cx_solution_57: 'Verbessern Sie Ihr Kundenerlebnis mit unserer Methodik ',
  cx_solution_58:
    'Beim Customer Journey Mapping geht es darum, eine Zielgruppe zu definieren, ihre Bedürfnisse und ihr Umfeld zu verstehen und dann einen Plan zu erstellen, um ein ansprechendes Erlebnis zu bieten',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brand Health Tracking-Untersuchungen können messen, wie es um die Bekanntheit und Nutzung Ihrer Marke, die Markenpositionierung und die Markenleistung bestellt ist',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Der Kundenzufriedenheitsindex ist ein Wert, der angibt, wie zufrieden Ihre Kunden mit den Produkten/Dienstleistungen des Unternehmens sind.',
  cx_solution_63:
    'Preisbeobachtung ist der Prozess der Erfassung, Verarbeitung und Analyse der Preise der Wettbewerber',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Der Net Promoter Score ist der Goldstandard der Kundenerfahrungsmetriken und basiert auf einer einzigen Frage: Wie wahrscheinlich würden Sie Organisation/Produkt/Dienstleistung weiterempfehlen?',
  cx_solution_66: 'HR-Forschung',
  cx_solution_67:
    'Human Resource Research dient der Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zur aktuellen Entwicklung und zum Management',
  cx_solution_68:
    'Usability-Tests zeigen, wie echte Menschen mit einer Website, einer App oder einem anderen Produkt interagieren, und beobachten ihr Verhalten und ihre Reaktionen darauf',
  cx_solution_69: 'Andere',
  cx_solution_70: 'Experten wählen die beste Methode für Ihr Unternehmen',
  cx_solution_71: 'Fangen wir an',
  cx_solution_72: '1 - ERÖFFNUNGSSITZUNG',
  cx_solution_73:
    'Während des Kickoff-Meetings werden die wichtigsten Aufgaben festgelegt und in den Mittelpunkt gestellt. Ergebnis: Verständnis des Ziels, der Forschungsaufgaben, der Fristen, der Methoden und der Verantwortungsbereiche.',
  cx_solution_74: '2 - KONZEPTENTWICKLUNG',
  cx_solution_75:
    'Die Analytikabteilung von 4Service aggregiert die Daten und koordiniert die Berichtsstruktur. Anschließend präsentieren wir dem Kunden die Ergebnisse, ergänzt um Erkenntnisse und Empfehlungen zur Umsetzung.',
  cx_solution_76: '3 - FELDARBEIT',
  cx_solution_77:
    'Zur Durchführung der Studie rekrutieren wir Teilnehmer, führen Interviews und andere grundlegende Forschungsschritte durch. Der Kunde erhält transkribierte Audio-Video-Interviews sowie prägnante Zusammenfassungen.',
  cx_solution_78: '4 - ANALYTIK',
  cx_solution_79:
    'Die Analytikabteilung von 4Service aggregiert die Daten und koordiniert die Berichtsstruktur. Anschließend präsentieren wir dem Kunden die Ergebnisse, ergänzt um Erkenntnisse und Empfehlungen zur Umsetzung.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81:
    'Durchführung eines Gesprächs mit dem Kunden über den Umsetzungsplan',
  cx_solution_82: 'Erfahren Sie mehr über unsere bewährten Verfahren',
  cx_solution_83:
    'Die Customer Journey Map ermöglicht eine rasche Überprüfung aller Kommunikationskanäle, die Identifizierung potenzieller Schwachstellen an entscheidenden Touchpoints und ein umfassendes Verständnis von Bereichen, die vom geplanten Verlauf abweichen können.',
  cx_solution_84: 'Wann ist es zu verwenden?',
  cx_solution_85:
    'Erstellen Sie eine personalisierte Werbekampagne auf der Grundlage echter Daten über Zielgruppen',
  cx_solution_86:
    'Finden Sie die Auslöser der verschiedenen Zielgruppensegmente heraus und erfüllen Sie deren Bedürfnisse',
  cx_solution_87: 'Genaue Ausrichtung auf jede Gruppe und maximale Konversion',
  cx_solution_88:
    'Die Technik des Accompany Checking ermöglicht es, die Möglichkeiten der optimalen Wege zur Erreichung Ihrer Ziele zu bewerten.',
  cx_solution_89:
    'Testen einer neuen Website oder eines neuen Produkts vor der Einführung',
  cx_solution_90:
    'Überprüfung der entwickelten Prozesse vor der Implementierung von Änderungen',
  cx_solution_91:
    'Bewertung der Website oder des Produkts inmitten rückläufiger Verkaufszahlen',
  cx_solution_92:
    'Einführung eines neuen, auf die Kundenpräferenzen zugeschnittenen Produkts',
  cx_solution_93:
    'Untersuchung konkurrierender Unternehmen zur Sicherung einer führenden Position auf dem Markt',
  cx_solution_94:
    'Wir bieten einen umfassenden 360°-Ansatz zur Schaffung eines kundenorientierten Dienstleistungsmodells.',
  cx_solution_95: 'Ausbildung und Entwicklung des Personals',
  cx_solution_96: 'Einführung von Servicestandards',
  cx_solution_97: 'Steigerung des Kundenstroms und des Umsatzes',
  cx_solution_98:
    'Festlegung von KPIs und Motivationssystemen für das Personal',
  cx_solution_99: 'Entdecken Sie unsere Erfolgsgeschichten',
  cx_solution_100: 'Automobilindustrie',
  cx_solution_101: 'Tabak',
  cx_solution_102: 'Einzelhandel',
  cx_solution_103: 'Qualitative phase',
  cx_solution_104: 'Quantitative Phase (Segmentierung)',
  cx_solution_105: 'Schwerpunktgruppen',
  cx_solution_106: 'Weltweite Online-Berichterstattung',
  cx_solution_107: 'Mix von Techniken bei Interviews',
  cx_solution_108: 'Nur offene Fragen',
  cx_solution_109: 'Unterstützung der Befragten 24/7',
  cx_solution_110:
    'Bewahrung wichtiger Details und eines realen Bildes des Weges',
  cx_solution_111: 'Marktforschung',
  cx_solution_112: 'Diagnostik des Unternehmens und der Wettbewerber',
  cx_solution_113:
    'Aktualisierte Dienstleistungsmodelle und neue Geschäftsprozesse',
  cx_solution_114: 'Förderung einer kundenorientierten Dienstleistung',
  cx_solution_115:
    'Umsetzung von Änderungen und Kontrolle der Entwicklung von Dienstleistungsstrategien',
  usage_attitude_1: 'Nutzung und Einstellung (U&A)',
  usage_attitude_2:
    'Gewinnen Sie umfassende Einblicke in die Art und Weise, wie Ihre Zielgruppe Ihre Marke wahrnimmt, nutzt und sich mit ihr auseinandersetzt.',
  usage_attitude_3: 'Sprechen Sie mit einem Experten',
  usage_attitude_4: 'Über',
  usage_attitude_5:
    '<b>Verbraucher erforschen</b> Gewohnheiten, um Vorlieben und Einflüsse eingehend zu ermitteln',
  usage_attitude_6:
    '<b>Entdecken Sie das `Warum`</b> hinter den Entscheidungen der Verbraucher mit unserer detaillierten Analyse',
  usage_attitude_7:
    '<b>Verstehen, wie</b> Kunden mit Ihren Produkten interagieren, um strategische Erkenntnisse zu gewinnen',
  usage_attitude_8:
    '<b>Gewinnen Sie Einblicke in</b> Verbraucherverhalten, Gewohnheiten und Vorlieben für fundierte Entscheidungen',
  usage_attitude_9: 'Erleben Sie',
  usage_attitude_10:
    '4Service-Experten mit mehr als 20 Jahren Kundenerfahrung entwickeln einzigartige Lösungen, die zum Wachstum Ihres Unternehmens beitragen',
  usage_attitude_11: 'Kontakt',
  usage_attitude_12: 'Für wen',
  usage_attitude_13: 'Wer profitiert von Markenverwendung und -einstellung?',
  usage_attitude_14:
    'Verschiedene Industrie- und Dienstleistungssektoren sind interessiert an:',
  usage_attitude_15: '<b>Erkennen von</b> Kundenbedürfnisse und Schmerzpunkte',
  usage_attitude_16:
    '<b>Identifizierung</b> Möglichkeiten zur Steigerung des Kundenkonsums',
  usage_attitude_17:
    '<b>Entwicklung von</b> neue Produktkategorien oder Marketingstrategien',
  usage_attitude_18:
    '<b>Gewinnung von</b> ein tiefes Verständnis der Wettbewerbslandschaft',
  usage_attitude_19:
    'Die Methodik wird je nach Wunsch des Kunden individuell ausgewählt',
  usage_attitude_20:
    '<b>Qualitative Forschung</b> umfasst das Sammeln und Analysieren nicht-numerischer Daten, um Konzepte, Meinungen oder Erfahrungen zu erfassen.',
  usage_attitude_21:
    '<b>Quantitative Forschung</b> methods entail objectively measuring and numerically analyzing data obtained through polls, questionnaires, and surveys.',
  usage_attitude_22:
    '<b>Nach der Datenerfassung</b>, Experten analysieren die Informationen und ziehen Schlussfolgerungen, um Ihnen die besten Empfehlungen zu geben.',
  usage_attitude_23: 'Wer braucht',
  usage_attitude_24: 'Ihre Erfolgsgeschichte beginnt hier',
  usage_attitude_25:
    '<b>Sie erhalten eine Recherche</b> mit wertvollen Erkenntnissen, die die strategische Entscheidungsfindung unterstützen und sicherstellen, dass die Maßnahmen auf einem tiefen Verständnis des Verbraucherverhaltens beruhen.',
  usage_attitude_26:
    '<b>Die Forschung bietet eine solide Grundlage für die Verfeinerung</b> und Optimierung der Markenstrategien, um sie besser auf die Vorlieben und Einstellungen der Verbraucher abzustimmen.',
  usage_attitude_27: 'Lösungen',
  usage_attitude_28:
    'Entdecken Sie zahlreiche Möglichkeiten mit unseren Lösungen',
  usage_attitude_29: 'Produktpräferenz innerhalb der Kategorie',
  usage_attitude_30:
    'Identifizieren Sie die wichtigsten Vorlieben der Verbraucher in verschiedenen Kategorien, einschließlich Lebensmittel, Getränke, Kleidung, Elektronik und mehr.',
  usage_attitude_31: 'Konsum-/Nutzungsverhalten',
  usage_attitude_32:
    'Analyse der Häufigkeit des Konsums oder der Nutzung bestimmter Produkte/Dienstleistungen und Ermittlung der mit ihrer Nutzung verbundenen Anlässe und Momente.',
  usage_attitude_33: 'Kaufverhalten',
  usage_attitude_34:
    'Untersuchen Sie die Kanäle, die Kunden für ihre Einkäufe nutzen, und ermitteln Sie die Informationsquellen, auf die sie sich verlassen.',
  usage_attitude_35: 'Motivationen und Bedürfnisse der Verbraucher',
  usage_attitude_36:
    'Untersuchung der Motivationen und Hindernisse, die den Konsum beeinflussen. Bewertung der Faktoren, die die Kaufentscheidungen der Verbraucher beeinflussen. die Wahrnehmung der Produktkategorie zu untersuchen und zu analysieren, wie der Preis wahrgenommen wird.',
  usage_attitude_37: 'Unser Ansatz',
  usage_attitude_38: 'Studie zur Markennutzung und -einstellung',
  usage_attitude_39: 'Informationen',
  usage_attitude_40:
    'Durchführung von Erhebungen mit quantitativen und qualitativen Methoden',
  usage_attitude_41: 'Starten Sie',
  usage_attitude_42:
    'Festlegung von Zielen und Methoden auf der Grundlage individueller Anforderungen',
  usage_attitude_43: 'Analyse',
  usage_attitude_44:
    'Ausgefeilte Techniken, die von unseren Experten eingesetzt werden',
  usage_attitude_45:
    'Wir verwenden eine Reihe von Tools, die auf Ihre spezifischen Anforderungen abgestimmt sind',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Die computergestützte Telefonbefragung ist eine Methode, die wir zur Durchführung von Umfragen und zur Sammlung wertvoller Daten am Telefon einsetzen, wobei wir eine spezielle Software verwenden, um den Prozess zu rationalisieren und die Genauigkeit unserer Marktforschungsbemühungen zu gewährleisten.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Die computergestützte Webbefragung ist ein wichtiges Instrument in unserem Arsenal, das uns die Durchführung von Umfragen und die Erhebung von Daten über Online-Plattformen mit Hilfe spezieller Software ermöglicht und eine effiziente und genaue Datenerhebung für die Marktforschungsbedürfnisse unserer Kunden gewährleistet.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Die computergestützte persönliche Befragung ist eine Methode, die wir einsetzen, um den Datenerfassungsprozess zu optimieren, die Befragung reibungsloser zu gestalten und genaue, aufschlussreiche Ergebnisse für Ihre Geschäftsanforderungen zu gewährleisten.',
  usage_attitude_52: 'Schwerpunktgruppen',
  usage_attitude_53:
    'Focus groups are dynamic research sessions we facilitate, bringing together a diverse group of participants to discuss specific topics or products in-depth, allowing us to gather qualitative insights, uncover attitudes, preferences, and perceptions, and explore ideas and concepts, ultimately informing our clients` decision-making and strategy development processes.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face ist ein wesentlicher Bestandteil unseres Ansatzes, der die direkte Interaktion und das persönliche Engagement mit Kunden und Auftraggebern erleichtert und so Vertrauen, Verständnis und eine effektive Kommunikation fördert, die für den Aufbau dauerhafter Beziehungen und die Förderung des Geschäftserfolgs unerlässlich sind.',
  usage_attitude_56: 'Ethnographische Forschung',
  usage_attitude_57:
    'Studium von Menschen und Kulturen durch Eintauchen und Beobachtung in ihrer natürlichen Umgebung, was oft zu tiefen Einsichten in Verhaltensweisen, Überzeugungen und soziale Dynamik führt.',
  thank_you_page:
    'Vielen Dank für Ihre Zeit! Unser Manager wird Sie bald kontaktieren.',
  back_main: 'Zurück zum Hauptmenü',
  opportunities_1: 'Erfahren Sie mehr über unsere Fälle',
  opportunities_2: 'Entdecken Sie',
  opportunities_3: 'für Sie selbst',
  opportunities_4: 'Möglichkeiten',
  opportunities_5:
    'der internationalen Wirtschaft zusammen mit der Forschungsholding 4Service',
  opportunities_6:
    'Was <span>Produkte</span> können wir Ihr Unternehmen stärken mit?',
  opportunities_7: 'CX',
  opportunities_8: 'Umfassende maßgeschneiderte Lösungen für Unternehmen',
  opportunities_9: 'Alle Arten von Mystery Shopping',
  opportunities_10:
    'Wir implementieren Mystery-Mitarbeiter, Mystery-Caller, Luxus Mystery Shopper, Automotive Mystery Shopper und andere Arten',
  opportunities_11: 'Maßgeschneiderte IT-Lösungen für Unternehmen',
  opportunities_12:
    'Eigene IT-Lösungen mit künstlicher Intelligenz, Plattformen für die Analyse von Feedback und die Transkription von Anrufen',
  opportunities_13: 'Marktforschung',
  opportunities_14:
    'Für alle Geschäftsanforderungen (Brand Health Tracking, Segmentierung, NPS, eNPS, CJM, Produkt- und Werbetests usw.)',
  opportunities_15: 'Prüfungen',
  opportunities_16:
    'Wir führen verschiedene Service-Audits sowie Preisüberwachungen durch (offline, für bestimmte Nischen usw.)',
  opportunities_17: 'Dienstleistungsdesign',
  opportunities_18:
    '4Service - zertifizierte Partner der DesignThinkers Academy, die Firmenschulungen für Teams anbieten und regelmäßig Workshops für Unternehmen durchführen',
  opportunities_19:
    'Wir teilen unsere Erfahrung, aber <span>respektieren und stören nicht</span> Ihren Geschäftsprozess.',
  opportunities_20:
    'Wir kennen die Schwierigkeiten, mit denen sich Unternehmen auf ihrem Entwicklungsweg konfrontiert sehen, und wir wissen, wie man diese Hindernisse dank internationaler erfolgreicher Erfahrung überwinden kann. Sie erhalten nicht nur Erfahrung, sondern auch Ressourcen.',
  opportunities_21: 'Wir laden Sie zur Mitarbeit ein!',
  opportunities_22:
    'Wir bieten ein <span>Partnerschaftsprogramm</span> für Ihr Unternehmen',
  opportunities_23:
    'Unter der international anerkannten Marke 4Service können Sie die Gewinnung neuer Kunden positiv beeinflussen.',
  opportunities_24:
    'Unsere Partner erhalten umfassende Unterstützung, darunter Schulungen, Marketingmaterial und operative Hilfe.',
  opportunities_25:
    'Durch eine Partnerschaft mit uns können Sie Ihre Pläne verwirklichen, ohne Ihre laufenden Geschäftsprozesse zu unterbrechen.',
  opportunities_26: 'Unsere <span>Büros</span>',
  opportunities_27: 'Unsere Kunden',
  opportunities_28: 'Wir haben über 20 Jahre Erfahrung mit CX',
  opportunities_29: 'Erfasste Länder',
  opportunities_30: 'Tausend',
  opportunities_31: 'Einzigartige Projekte',
  opportunities_32: 'Millionen',
  opportunities_33: 'Besuche von Testkäufern',
  opportunities_34: 'ist unter den TOP 10 der europäischen CX-Agenturen',
  opportunities_35: 'Ihre Möglichkeiten mit 4Service',
  opportunities_36: 'Erhöhung des Gewinns',
  opportunities_37: 'Sie erhalten Zugang zu internationalen Aufträgen',
  opportunities_38: 'Einzigartiger CX-Ansatz',
  opportunities_39: 'Unterstützung durch Experten mit 20 Jahren Erfahrung',
  opportunities_40: 'Unterstützung',
  opportunities_41: 'Unser Team unterstützt Sie dabei, Ergebnisse zu erzielen',
  opportunities_42: 'Vertrieb',
  opportunities_43: 'Wir helfen Ihnen beim Aufbau eines Vertriebssystems',
  opportunities_44: 'Zugang zu Software',
  opportunities_45: 'Sie erhalten Zugang zu allen zertifizierten IT-Tools',
  opportunities_46: 'Ausbildung',
  opportunities_47:
    'Wir werden nicht nur Ihr Personal schulen, sondern auch weiterhin Beratungsdienste anbieten',
  opportunities_48: 'Wer für eine Partnerschaft geeignet ist?',
  opportunities_49: 'Ist Ihr Unternehmen mit der Marketingforschung verbunden?',
  opportunities_50: 'Oder planen Sie, sich in einem neuen Bereich zu versuchen',
  opportunities_51: 'Haben Sie vor:',
  opportunities_52: 'Innovationen umsetzen',
  opportunities_53: 'Arbeit mit internationalen Marken',
  opportunities_54: 'Erweitern Sie Ihr Produktportfolio',
  opportunities_55: 'Stärken Sie Ihre Marktposition?',
  opportunities_56: 'Schulung Ihrer Mitarbeiter',
  opportunities_57: 'Skalieren Sie Ihr Unternehmen',
  opportunities_58: 'Unsere Ressourcen = Ihre Ressourcen',
  opportunities_59:
    'Aufgrund unserer umfangreichen Erfahrung sind wir bereit, diese in der ersten Phase der Zusammenarbeit mit Ihnen zu teilen',
  opportunities_60: 'Analyse der Daten:',
  opportunities_61: 'Internationales Analyseteam',
  opportunities_62: 'Methoden der Datenverarbeitung - SPSS, Power BI',
  opportunities_63: 'Innovative Methoden und ein breites Spektrum an Methoden',
  opportunities_64: 'Berichterstattung in jedem Format',
  opportunities_65: 'Software:',
  opportunities_66: 'Eigene maßgeschneiderte IT-Entwicklungen',
  opportunities_67: 'DigSee Sure für CAPI',
  opportunities_68: 'SurveyMonkey für CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Marketing-Unterstützung:',
  opportunities_72: 'PR-Unterstützung',
  opportunities_73:
    'Unterstützung bei der Werbung und der Erstellung von Inhalten',
  opportunities_74: 'Einrichtung von Prozessen zur Lead-Generierung',
  opportunities_75: 'IT-Unterstützung:',
  opportunities_76:
    'Technische Unterstützung bei der Installation, Konfiguration und Nutzung unserer Software.',
  opportunities_77: 'Fernunterstützung',
  opportunities_78: 'Sicherheitskontrolle',
  opportunities_79: 'Operative Unterstützung:',
  opportunities_80: 'Unterstützung bei der Einrichtung von Feldprozessen',
  opportunities_81: 'System zur Betrugsbekämpfung',
  opportunities_82: 'Rekrutierung jeglicher Komplexität',
  opportunities_83: 'Operative Leitung des Teams der Projekt- und Außenstellen',
  opportunities_84: 'Qualitätskontrolle der Rekrutierung',
  opportunities_85: 'UpWork',
  opportunities_86: 'Welche Boni erhalten Sie?',
  opportunities_87: 'Vergrößerung des Kundenstamms',
  opportunities_88: 'Stärkung des Marktauftritts',
  opportunities_89: 'Diversifizierung der Kundenausfallrisiken',
  opportunities_90: 'Optimierung der technologischen Prozesse',
  opportunities_91: 'Internationale Erfahrung',
  opportunities_92: 'Mehr Verkäufe an bestehende Kunden',
  main_67:
    'Die Analyse der Wettbewerbspreise optimiert das Angebot, steigert den Gewinn und erhöht den Marktanteil, indem sie Daten über die Zahlungsbereitschaft der Verbraucher zur Ermittlung der wichtigsten Merkmale nutzt',
  main_68:
    'Sicherstellen, dass Ihre Einzelhandelsgeschäfte mit den Markenstandards übereinstimmen, Bewertung der physischen Merkmale und ihrer Auswirkungen auf die Kundenwahrnehmung',
  main_69: 'CX-Lösung',
  main_70:
    'Entdecken Sie unsere einzigartige 360-Grad-CX-Lösung: Wir passen Tools und Services an Ihre Ziele an, um Ihr Geschäft voranzutreiben',
  main_71: 'Sprachanalytik',
  main_72:
    'Unser fortschrittliches Sprachanalyse-Tool transkribiert gesprochene Sprache in Text und liefert Unternehmen wertvolle Erkenntnisse zur Verbesserung der Kundenerfahrung, zur Überwachung der Leistung, zur Sicherstellung der Compliance und zur Förderung des Wachstums',
  banner_1:
    'СX Beratung und Dienstleistungen eine 360°-Sicht auf Ihr Unternehmen',
  banner_2: 'Bleiben Sie auf dem Laufenden über die neueste Forschung!',
  banner_3:
    'Ich erkläre mich mit der Verarbeitung personenbezogener Daten gemäß der Datenschutzrichtlinie einverstanden',
  banner_4: '*Wählen Sie den Markt',
  banner_5: 'Vielen Dank für Ihr Abonnement!',
  banner_6: 'Wir werden nur die interessantesten Inhalte mit Ihnen teilen!',
  banner_7: 'Einreichen',
  banner_8: 'Ukrainisch',
  banner_9: 'Kasachstan',
  brand_awareness_1: 'Markenbekanntheit',
  brand_awareness_2: 'und Verwendung',
  brand_awareness_3:
    'Erschließen Sie das Potenzial Ihrer Markenbekanntheit und -nutzung für Ihren Geschäftserfolg',
  brand_awareness_4: 'Sprechen Sie mit einem Experten',
  brand_awareness_5: 'Über',
  brand_awareness_6:
    '<b>Bewertung des Bekanntheitsgrads und der</b> Vertrautheit Ihrer Marke',
  brand_awareness_7:
    '<b>Bestimmen Sie das Niveau der Marke </b> Bekanntheit auf Ihrem Zielmarkt',
  brand_awareness_8:
    '<b>Beobachten Sie genau</b> zu Bindungs- und Loyalitätsraten',
  brand_awareness_9:
    '<b>Wählen Sie die optimale Strategie</b> die notwendigen Indikatoren zu verbessern',
  brand_awareness_10:
    'Entdecken Sie zahlreiche Möglichkeiten mit unseren Lösungen',
  brand_awareness_11: 'Markenbekanntheit',
  brand_awareness_12: 'Verwendung der Marke',
  brand_awareness_13:
    '<b>Entdecken Sie</b> Ihre Markenbekanntheit bei den Verbrauchersegmenten',
  brand_awareness_14: '<b>Monitor</b> den Bekanntheitsgrad Ihres Publikums',
  brand_awareness_15:
    '<b>Studie</b> die spontanen Gedanken der Verbraucher, die einen Kauf tätigen wollen',
  brand_awareness_16:
    '<b>Verstehen Sie</b> die wichtigsten Quellen, die zur Bekanntheit Ihrer Marke beitragen',
  brand_awareness_17:
    '<b>Erwägen Sie</b> die Anzahl der Verbraucher, die Erfahrungen mit den Produkten oder Dienstleistungen Ihrer Marke gemacht haben',
  brand_awareness_18:
    '<b>Identifizieren Sie</b> die Verbraucher, die bei Ihrer Marke geblieben sind, nachdem sie sie zunächst ausprobiert haben',
  brand_awareness_19:
    '<b>freischalten</b> die Wahrscheinlichkeit, dass Ihre Marke weiterempfohlen wird',
  brand_awareness_20:
    '<b>aufdecken</b> der Prozentsatz der Verbraucher, die Ihre Marke für eine künftige Nutzung in Betracht ziehen',
  brand_awareness_21: 'Wer profitiert von Markenbekanntheit und -verwendung?',
  brand_awareness_22: 'Unternehmen, die ein neues Produkt einführen',
  brand_awareness_23: 'Unternehmen, die eine etablierte Marke wiederbeleben',
  brand_awareness_24:
    'Jedes Unternehmen, das verstehen und verbessern möchte, wie seine Marke von den Verbrauchern erkannt und genutzt wird',
  brand_awareness_25:
    'Unsere Methoden liefern wertvolle Erkenntnisse zur Optimierung Ihrer Markenbekanntheit und -nutzung',
  brand_awareness_26:
    'Die Methodik wird je nach Wunsch des Kunden individuell ausgewählt',
  brand_awareness_27: 'Methodik des Markennutzungstrichters',
  brand_awareness_28:
    'Die Probierrate gibt an, wie groß der Anteil der Zielgruppe ist, die die Marke kennt und ihre Produkte oder Dienstleistungen ausprobiert hat.',
  brand_awareness_29:
    'Die Wiederholungsrate gibt den Prozentsatz des Publikums an, das mit der Marke vertraut ist und ihre Produkte oder Dienstleistungen ausprobiert hat und in den letzten 12 Monaten weiterhin Käufe getätigt oder die Angebote genutzt hat.',
  brand_awareness_30:
    'Die Kundenbindungsrate gibt den Anteil der Personen an, die die Produkte oder Dienstleistungen der Marke regelmäßig nutzen, d. h. den Prozentsatz, der sich in den letzten 6 Monaten mit diesen Angeboten beschäftigt hat.',
  brand_awareness_31:
    'Die Kundenbindungsrate zeigt den Prozentsatz der Kunden, die in den letzten 6 Monaten die Produkte/Dienstleistungen der Marke in Anspruch genommen haben, sich weiterhin für die Marke entscheiden und ihr gegenüber Loyalität zeigen.',
  brand_awareness_32: 'Methodik des Markenbewusstseins',
  brand_awareness_33:
    'Top of mind (TOM) ist die erste Marke, die der Befragte spontan nennt.',
  brand_awareness_34:
    'Die gesamte spontane Aufmerksamkeit ist die Summe der spontanen Erwähnungen, einschließlich TOM.',
  brand_awareness_35:
    'Aided Awareness zeigt, wie gut Ihre Marke von Nutzern erkannt wird, wenn sie dazu aufgefordert werden.',
  brand_awareness_36: 'Karte der Markenattribute',
  brand_awareness_37:
    'Dieses Tool bewertet die Bedeutung der verschiedenen Markenattribute und stellt sie visuell dar. Es gibt Aufschluss darüber, wie jedes einzelne Attribut zur Gesamtleistung Ihrer Marke und zur Wahrnehmung durch die Verbraucher beiträgt.',
  brand_awareness_38:
    'Entdecken Sie die Schlüssel zur Steigerung der Wirkung Ihrer Marke mit unserer umfassenden Analyse.',
  brand_awareness_39: 'Wahrnehmungskarte für Marken',
  brand_awareness_40:
    'Dieses Instrument bietet eine visuelle Darstellung der Verbraucherwahrnehmung in einer wettbewerbsorientierten Marktlandschaft. Dieser Ansatz ist von entscheidender Bedeutung, um die Unterschiede zwischen den Marken klar herauszuarbeiten und die wichtigsten Marktattribute hervorzuheben.',
  brand_awareness_41:
    'Es vereinfacht die Beobachtung und Interpretation von Beziehungen zwischen Marken und diesen definierenden Merkmalen.',
  brand_awareness_42:
    'Die Nähe einer bestimmten Marke zu bestimmten Attributen bedeutet, dass sie stark mit diesen Merkmalen assoziiert wird. Ebenso weist die Nähe zwischen zwei Marken auf ihre Ähnlichkeiten hin, die ein gemeinsames Image und folglich eine Zugehörigkeit zum selben Marktsegment zeigen.',
  consumer_journey_map_1: 'Karte der Verbraucherreise',
  consumer_journey_map_2:
    'Entdecken und überwinden Sie Hindernisse in Ihrem Unternehmen mit unserer Consumer Journey Map',
  consumer_journey_map_3: 'VERTRAUEN DURCH',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Erschließen Sie das Potenzial von CJM zur Optimierung der Interaktionen zwischen Ihren Kunden und Ihrer Marke',
  consumer_journey_map_6:
    'Bestimmen Sie, welche Schritte der Verbraucher vor dem Kauf eines Produkts unternimmt',
  consumer_journey_map_7:
    'Gewinnen Sie Einblicke von Ihren echten Kunden, nicht von imaginären Kunden',
  consumer_journey_map_8:
    'Gewinnen Sie ein umfassendes Verständnis der Erfahrungen Ihrer Kunden',
  consumer_journey_map_9:
    'Erkennen Sie verpasste Chancen und vergleichen Sie sich mit Ihren Wettbewerbern',
  consumer_journey_map_10: 'Entdecken Sie unseren CJM-Ansatz',
  consumer_journey_map_11:
    'Wir schaffen einen Pfad der Verbraucherinteraktion mit einem Produkt, einer Dienstleistung, einem Unternehmen oder einer Marke über verschiedene Kanäle und während eines bestimmten Zeitraums.',
  consumer_journey_map_12:
    'Wir skizzieren die Phasen, die Ihre Kunden durchlaufen, bevor sie einen Kauf abschließen, die Erwartungen, Emotionen und Motivationen des Kunden in jeder Phase.',
  consumer_journey_map_13: 'Profitieren Sie mit uns',
  consumer_journey_map_14:
    'Wir bieten ein Team von erfahrenen Fachleuten, die die Feinheiten des globalen Geschäftserfolgs verstehen',
  consumer_journey_map_15:
    'Wir sind bestrebt, Ergebnisse zu liefern, die Ihre Erwartungen übertreffen',
  consumer_journey_map_16:
    'Wir entdecken komplexe Lösungen, die zu Ihrem Wachstum beitragen',
  consumer_journey_map_17:
    'Wir sind darauf spezialisiert, maßgeschneiderte CJM-Lösungen anzubieten',
  consumer_journey_map_18: 'Buchen Sie eine Beratung',
  consumer_journey_map_19: 'Datengesteuerte Entscheidungen',
  consumer_journey_map_20:
    'Treffen Sie Ihre Entscheidungen auf der Grundlage realer Daten und nicht auf der Grundlage von Annahmen',
  consumer_journey_map_21: 'Optimieren Sie Ihr CJM',
  consumer_journey_map_22:
    'Aufbau engerer Beziehungen zu den Kunden durch Eingehen auf ihre Bedürfnisse und Erwartungen in jeder Phase ihrer Reise',
  consumer_journey_map_23: 'Den Weg weisen',
  consumer_journey_map_24:
    'Vergleichen Sie sich mit Ihren Konkurrenten und bleiben Sie dem Markt voraus',
  consumer_journey_map_25: 'Empfehlungen',
  consumer_journey_map_26:
    'Setzen Sie unsere CJM-Empfehlungen um, um den Erfolg zu sichern, die Kundenbindung zu fördern, den Umsatz zu steigern und Ihr Geschäftspotenzial zu maximieren',
  consumer_journey_map_27:
    'Entdecken Sie die tatsächliche Verbraucherreise mit unserer Consumer Journey Map',
  consumer_journey_map_28:
    'Experten mit mehr als 20 Jahren Erfahrung bieten eine umfassende Recherche',
  consumer_journey_map_29:
    'Qualitative Forschung beinhaltet das Sammeln und Analysieren nicht-numerischer Daten, um Konzepte, Meinungen oder Erfahrungen zu erfassen.',
  consumer_journey_map_30:
    'Quantitative Forschungsmethoden beinhalten die objektive Messung und numerische Analyse von Daten, die durch Umfragen, Fragebögen und Erhebungen gewonnen werden.',
  consumer_journey_map_31:
    'Nach der Datenerfassung analysieren die Experten die Informationen und ziehen Schlussfolgerungen, um Ihnen die besten Empfehlungen zu geben.',
  boarding_1: 'Karte der Kundenreise',
  boarding_2:
    'Beim Customer Journey Mapping (CJM) geht es darum, eine Zielgruppe zu definieren, ihre Bedürfnisse und ihr Umfeld zu verstehen und dann einen Plan zu erstellen, um ein ansprechendes Erlebnis zu bieten',
  boarding_3: 'Netto-Promotion-Punktzahl',
  boarding_4:
    'Der Net Promoter Score (NPS) ist der Goldstandard der Kundenerfahrungsmetriken und basiert auf einer einzigen Frage: Wie wahrscheinlich würden Sie die Organisation/das Produkt/die Dienstleistung weiterempfehlen?',
  boarding_5: 'Tracking der Markengesundheit',
  boarding_6:
    'Brand Health Tracking (BHT)-Forschung kann messen, wie Ihre Marke in Bezug auf Bekanntheit und Nutzung, Markenpositionierung und Markenleistung abschneidet',
  boarding_7: 'Forschung im Bereich Humanressourcen',
  boarding_8:
    'Human Resource Research dient der Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zur aktuellen Entwicklung und zum Management',
  boarding_9: 'UX-Tests',
  boarding_10:
    'Beim Usability-Testing (UX) geht es darum, wie echte Menschen mit einer Website, einer App oder einem anderen Produkt interagieren und ihr Verhalten und ihre Reaktionen darauf zu beobachten',
  boarding_11: 'Index der Kundenzufriedenheit',
  boarding_12:
    'Die Kundenzufriedenheit misst das Ausmaß, in dem die Erwartungen des Kunden in Bezug auf den Kauf eines Produkts oder einer Dienstleistung Ihres Unternehmens erfüllt wurden',
  boarding_13: 'Anzeigenprüfung',
  boarding_14:
    'Eine Werbekampagne ist eine große Investition. Stellen Sie sicher, dass Ihre Anzeigen leistungsstark sind und einen maximalen ROI liefern',
  boarding_15: 'Audit vor Ort/Einzelhandelsaudit',
  boarding_16:
    'Sammeln Sie detaillierte Daten über Ihr Geschäft oder Ihre Marke, analysieren Sie Wachstumsbarrieren und entwickeln Sie effektive Strategien, um diese zu überwinden',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Software für Leistungsmanagement. Gamified-Anwendung zur Umsatzsteigerung',
  boarding_19: 'Voicer',
  boarding_20:
    'Feedback-Management-System. Die Stimme Ihrer Kunden in einem System. Kunden-Feedback-Management-System',
  boarding_21: 'Qualitative Forschung',
  boarding_22:
    'Qualitative Forschung ist das Sammeln und Analysieren nicht-numerischer Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen',
  boarding_23: 'Quantitative Forschung',
  boarding_24:
    'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen, Fragebögen und Erhebungen gesammelt wurden',
  boarding_25: 'Mystery Shopping',
  boarding_26:
    'Mystery Shopping ist eine Marktforschungstechnik zur Messung und Überwachung der Interaktionen zwischen einem Kunden und einem Unternehmen oder einer Organisation während eines vordefinierten Szenarios',
  boarding_27: 'Wantent',
  boarding_28:
    'Eine KI-gestützte Content-Intelligence-Plattform, die Erkenntnisse über das Publikum liefert',
  boarding_29: 'Preisüberwachung ',
  boarding_30:
    'Preisbeobachtung ist der Prozess der Erfassung, Verarbeitung und Analyse der Preise der Wettbewerber',
  boarding_31: 'Service design',
  boarding_32:
    'Service design ist es, die Bedürfnisse und Wünsche der Menschen zu verstehen, die ein Produkt oder eine Dienstleistung nutzen werden, indem man Zeit mit ihnen verbringt',
  boarding_33: 'Preisanalyse für die Automobilindustrie',
  boarding_34:
    'Die Analyse der Wettbewerbspreise optimiert das Angebot, steigert den Gewinn und erhöht den Marktanteil, indem sie Daten über die Zahlungsbereitschaft der Verbraucher zur Ermittlung der wichtigsten Merkmale nutzt',
  boarding_35: 'Facility Check Audit',
  boarding_36:
    'Sicherstellen, dass Ihre Einzelhandelsgeschäfte mit den Markenstandards übereinstimmen, Bewertung der physischen Merkmale und ihrer Auswirkungen auf die Kundenwahrnehmung',
  boarding_37: 'CX-Lösung',
  boarding_38:
    'Entdecken Sie unsere einzigartige 360-Grad-CX-Lösung: Wir passen Tools und Services an Ihre Ziele an, um Ihr Geschäft voranzutreiben',
  boarding_39: 'Sprachanalytik',
  boarding_40:
    'Unser fortschrittliches Sprachanalyse-Tool transkribiert gesprochene Sprache in Text und liefert Unternehmen wertvolle Erkenntnisse zur Verbesserung der Kundenerfahrung, zur Überwachung der Leistung, zur Sicherstellung der Compliance und zur Förderung des Wachstums',
  boarding_41: 'Verwendung und Einstellung',
  boarding_42:
    'Usage and Attitude ist unser Ansatz, um zu verstehen, wie Ihre Zielgruppe sich mit Ihrer Marke auseinandersetzt, indem wir praktische Nutzungsmuster und psychologische Einstellungen analysieren, was maßgeschneiderte Marketing- und Produktstrategien ermöglicht',
  boarding_43: 'Markenbekanntheit und -verwendung',
  boarding_44:
    'Markenbekanntheit und -verwendung beinhaltet die Bewertung von Bekanntheit, Vertrautheit, Interaktionshäufigkeit und Bindung innerhalb Ihres Zielmarktes, um die Sichtbarkeit und das Engagement der Marke zu verbessern',
  boarding_45: 'Markenidentität & Positionierung',
  boarding_46:
    'Markenidentität und -positionierung ist eine umfassende Analyse, die Verbraucherwahrnehmungen, verschiedene Ansichten der Zielgruppe und Einblicke in die Art und Weise, wie Ihre Marke wahrgenommen wird, aufdeckt',
  boarding_47: 'Kundenprofilierung & Segmentierung',
  boarding_48:
    'Bei der Kundenprofilierung und -segmentierung wird Ihr Zielmarkt auf der Grundlage gemeinsamer Merkmale in verschiedene Gruppen eingeteilt, was die Entwicklung personalisierter Strategien ermöglicht, die auf bestimmte Zielgruppensegmente abgestimmt sind',
  boarding_49: 'Karte der Verbraucherreise',
  boarding_50:
    'Eine Consumer Journey Map ist eine visuelle Darstellung der Phasen, Berührungspunkte, Emotionen und Motivationen, die ein Verbraucher während seiner Interaktion mit Ihrem Produkt oder Ihrer Marke erlebt',
  boarding_51:
    'Erfahren Sie mehr über innovative Techniken, die unser Engagement für die Bereitstellung umsetzbarer Erkenntnisse und die Erzielung wirksamer Ergebnisse für Ihr Unternehmen untermauern',
  boarding_52:
    'Entdecken Sie unsere Produktreihe mit einer Fülle von datengestützten Informationen, die auf Ihre Branche zugeschnitten sind, damit Sie fundierte Entscheidungen treffen und der Zeit immer einen Schritt voraus sein können',
  boarding_53:
    'Tauchen Sie mit unseren umfassenden Marktforschungslösungen tief in Markttrends, Verbraucherverhalten und Wettbewerbslandschaften ein',
  boarding_54: 'Ad-hoc-Forschung',
  boarding_55:
    'Ad-hoc-Forschung ist ein maßgeschneiderter Ansatz, der auf ein bestimmtes Problem zugeschnitten ist und eine Mischung aus bestehenden Methoden oder einen neuen Ansatz verwendet, um eine maßgeschneiderte Lösung zu schaffen.',
  boarding_56: 'Soziologische Forschung',
  boarding_57:
    'Die soziologische Forschung deckt die Muster menschlichen Verhaltens und sozialer Interaktionen auf und liefert wertvolle Erkenntnisse, um innovative Strategien zu entwickeln und sinnvolle Verbindungen zu den Zielgruppen zu schaffen.',
  not_found_1: 'Huch! Wir können die Seite nicht finden.',
  not_found_2: 'Informieren Sie sich über unsere Produkte?',
  not_found_3: 'Beratung buchen',
  customer_profiling_segmentation_1: 'Kundenprofilierung und -segmentierung',
  customer_profiling_segmentation_2:
    'Wir helfen Ihnen, Ihre Zielgruppe genau zu bestimmen und Ihre Strategie effektiver zu gestalten',
  customer_profiling_segmentation_3:
    'Nutzen Sie die Macht der Marktsegmentierung, um sicherzustellen, dass Ihre Botschaften Ihre Zielgruppen immer effektiv erreichen',
  customer_profiling_segmentation_4: 'Die Verbraucher besser verstehen',
  customer_profiling_segmentation_5:
    'Finden Sie die beste Zielgruppe für Ihr Produkt',
  customer_profiling_segmentation_6:
    'Neue Segmente erkunden, um neue Kunden zu gewinnen',
  customer_profiling_segmentation_7: 'Hindernisse für den Kauf entdecken',
  customer_profiling_segmentation_8:
    'Wir sind auf die Bereitstellung maßgeschneiderter Segmentierungslösungen spezialisiert',
  customer_profiling_segmentation_9: 'Einzigartiger Ansatz',
  customer_profiling_segmentation_10:
    'Erleben Sie eine einzigartige, maßgeschneiderte Segmentierungslösung, die speziell auf Ihre Bedürfnisse zugeschnitten ist',
  customer_profiling_segmentation_11: 'Ziel-Personas',
  customer_profiling_segmentation_12:
    'Ausarbeitung von Zielpersonen auf der Grundlage tiefgreifender Marktanalysen und umfassender Forschung',
  customer_profiling_segmentation_13: 'Neue Segmente',
  customer_profiling_segmentation_14:
    'Identifizieren Sie Ihre wertvollsten Zielgruppensegmente und nutzen Sie die Vorteile einer maßgeschneiderten Zielgruppenansprache',
  customer_profiling_segmentation_15: 'Empfehlungen',
  customer_profiling_segmentation_16:
    'Setzen Sie unsere Empfehlungen um und erzielen Sie Erfolge, fördern Sie die Kundentreue, steigern Sie den Umsatz und maximieren Sie Ihr Geschäftspotenzial',
  customer_profiling_segmentation_17:
    'Experten mit mehr als 20 Jahren Erfahrung bieten eine umfassende Recherche',
  customer_profiling_segmentation_18:
    'Qualitative Forschung beinhaltet das Sammeln und Analysieren nicht-numerischer Daten, um Konzepte, Meinungen oder Erfahrungen zu erfassen.',
  customer_profiling_segmentation_19:
    'Quantitative Forschungsmethoden beinhalten die objektive Messung und numerische Analyse von Daten, die durch Umfragen, Fragebögen und Erhebungen gewonnen werden.',
  customer_profiling_segmentation_20:
    'Nach der Datenerfassung analysieren die Experten die Informationen und ziehen Schlussfolgerungen, um Ihnen die besten Empfehlungen zu geben.',
  customer_profiling_segmentation_21:
    'Wir nehmen nicht nur Messungen vor, sondern geben Ihnen auch einen Aktionsplan mit klaren Schritt-für-Schritt-Maßnahmen',
  customer_profiling_segmentation_22: 'Unsere Methodik',
  customer_profiling_segmentation_23: 'Jaccard-Index',
  customer_profiling_segmentation_24:
    'Wenn die Befragten direkt befragt werden, stimmen ihre Antworten zu den Unternehmenspräferenzen möglicherweise nicht mit ihrem tatsächlichen Verhalten überein. Anstelle einer direkten Befragung verwenden wir die statistische Jaccard-Analyse, um die Beziehung zwischen Unternehmensmerkmalen und der Wahl der Verbraucher zu verstehen.',
  customer_profiling_segmentation_25: 'Drivers',
  customer_profiling_segmentation_26:
    'Wir identifizieren Treiber (Faktoren, die ein bestimmtes Ergebnis beeinflussen) und kategorisieren sie je nach ihrer Bedeutung in wichtige, mittlere und unwichtige Treiber.',
  customer_profiling_segmentation_27: 'Segmentierung',
  customer_profiling_segmentation_28:
    'Sie erhalten eine Segmentierung mit klaren Merkmalen der Kundenauswahl.',
  customer_profiling_segmentation_29: 'Unser Ansatz zur Segmentierung',
  customer_profiling_segmentation_30: 'Demografische Daten',
  customer_profiling_segmentation_31: 'Alter',
  customer_profiling_segmentation_32: 'Geschlecht',
  customer_profiling_segmentation_33: 'Einkommen',
  customer_profiling_segmentation_34:
    'Sie hilft dabei, Produkte, Dienstleistungen und Marketing auf bestimmte Gruppen zuzuschneiden und so die Relevanz und Wirksamkeit zu verbessern',
  customer_profiling_segmentation_35: 'Psychografisch',
  customer_profiling_segmentation_36: 'Lebensstil',
  customer_profiling_segmentation_37: 'Werte',
  customer_profiling_segmentation_38: 'Verhaltensweisen',
  customer_profiling_segmentation_39:
    'Es ermöglicht personalisiertes Marketing, tiefere Verbindungen zu den Kunden und eine höhere Loyalität und mehr Umsatz für das Unternehmen',
  customer_profiling_segmentation_40: 'Verhaltenstipps',
  customer_profiling_segmentation_41: 'Aktionen',
  customer_profiling_segmentation_42: 'Motivation',
  customer_profiling_segmentation_43: 'Kaufhäufigkeit',
  customer_profiling_segmentation_44:
    'Sie ermöglicht ein gezieltes Marketing, eine höhere Kundenzufriedenheit, eine bessere Kundenbindung und eine höhere Rentabilität für das Unternehmen',
  customer_profiling_segmentation_45: 'Geografische Daten',
  customer_profiling_segmentation_46: 'Standort',
  customer_profiling_segmentation_47:
    ' Sie ermöglicht gezieltes Marketing, maßgeschneiderte Produkte, einen effizienten Vertrieb und eine größere Marktdurchdringung für das Unternehmen.',
  brands_identity_1: 'Markenidentität und -positionierung',
  brands_identity_2:
    'Transformieren Sie Ihre Marke mit unserer gründlichen Analyse, die Einblicke in die Verbraucherwahrnehmung gewährt und Ihre Strategie für den Erfolg verfeinert',
  brands_identity_3: 'SGipfel zum Experten',
  brands_identity_4:
    '<b>Entdecken Sie eine umfassende</b> Analyse der Markenidentität',
  brands_identity_5:
    '<b>Einblicke erforschen</b> über die Verbraucherwahrnehmung gesammelt',
  brands_identity_6: '<b>Verstehen Sie die Vielfalt</b> Zielgruppenansichten',
  brands_identity_7:
    '<b>Gewinnen Sie Einblicke in</b> Verbraucherverhalten, Gewohnheiten und Vorlieben für fundierte Entscheidungen',
  brands_identity_8:
    '4Service-Experten mit mehr als 20 Jahren Kundenerfahrung entwickeln einzigartige Lösungen, die zum Wachstum Ihres Unternehmens beitragen',
  brands_identity_9:
    '<b>Sie haben Zugang zu einem Recherchepaket mit allen wichtigen Daten.</b> Es dient als Diagnoseinstrument, das Bereiche mit Stärken und solche, die möglicherweise Aufmerksamkeit erfordern, hervorhebt',
  brands_identity_10:
    '<b>Unsere Dienstleistungen bieten den Unternehmen die notwendigen Instrumente</b> um fundierte Entscheidungen zu treffen, Strategien zu verfeinern und den Erfolg in ihren jeweiligen Branchen voranzutreiben.',
  brands_identity_11:
    'Verbesserung der Position Ihrer Marke auf dem Wettbewerbsmarkt',
  brands_identity_12: 'Unternehmen, die ihre Marktpräsenz verbessern wollen',
  brands_identity_13: 'Unternehmen, die ihre Strategie weiterentwickeln wollen',
  brands_identity_14: 'Markenidentität',
  brands_identity_15: 'Positionierung der Marke',
  brands_identity_16:
    '<b>Entdecken Sie die Stärken Ihrer Marke</b> Wahrnehmung in allen Verbrauchersegmenten.',
  brands_identity_17:
    '<b>Identifizieren Sie die Eigenschaften, die</b> zu Ihrer Marke und Ihren Wettbewerbern.',
  brands_identity_18:
    '<b>Stärken auswerten</b> und Schwächen für strategische Verbesserungen.',
  brands_identity_19:
    '<b>Bewertung der Ausrichtung</b> Ihres Markenimages mit geförderten Werten.',
  brands_identity_20:
    '<b>Bewerten Sie die wahrgenommene</b> Vertrauenswürdigkeit Ihrer Marke.',
  brands_identity_21:
    '<b>Erforschen Sie das Archetypische</b> und symbolische Wahrnehmung Ihrer Marke.',
  brands_identity_22:
    '<b>Identifizieren Sie die Eigenschaften Ihrer Marke</b> Alleinstellungsmerkmale und Unterscheidungsmerkmale im Vergleich zu den Wettbewerbern.',
  brands_identity_23:
    '<b>Ermittlung der wichtigsten Attribute, die</b> die Wahrscheinlichkeit, dass Verbraucher Ihre Produkte/Dienstleistungen kaufen.',
  brands_identity_24:
    '<b>Bestimmen Sie, wie Ihre Marke derzeit positioniert ist</b> in den Köpfen der Verbraucher zu verankern und Aspekte zu identifizieren, die zu kommunizieren sind, um die gewünschten Marktsegmente anzusprechen.',
  brands_identity_25:
    'Die Methodik wird je nach Wunsch des Kunden individuell ausgewählt',
  brands_identity_26: 'Markenbekanntheit und -verwendung',
  brands_identity_27:
    'Die Bewertung des Markenniveaus umfasst die Messung des Bekanntheitsgrads und des Verbrauchs, was eine grundlegende Komponente bei der Bewertung darstellt.',
  brands_identity_28: 'Markenlieferung und Vertrauen',
  brands_identity_29:
    'Diese Kennzahl misst die Kundenbefürwortung (Empfehlung) und die Fähigkeit der Marke, Versprechen zu erfüllen, positive Kundenerfahrungen zu schaffen und Vertrauen aufzubauen.',
  brands_identity_30: 'Markenimage Leistung',
  brands_identity_31:
    'Die Bewertung der Leistung der Marke beinhaltet die Beurteilung ihrer Assoziation mit sechs entscheidenden Werten (3 rationale und 3 emotionale), ihrer Unterscheidungskraft innerhalb der Kategorie und ihrer Fähigkeit, den Stolz der Verbraucher zu wecken.',
  brands_identity_32: 'Markengesundheitsindex',
  brands_identity_33:
    'Der Brand Health Score zeigt Ihnen ein umfassendes Verständnis dafür, wie Ihre Marke im Wettbewerb abschneidet.',
  sociological_research_1: 'Soziologische Forschung',
  sociological_research_2:
    'Wir führen tiefgreifende Analysen sozialer Phänomene und Prozesse durch, die mit Hilfe einzigartiger analytischer Erkenntnisse und Empfehlungen helfen, fundierte Entscheidungen zu treffen',
  sociological_research_3: 'Kontakt',
  sociological_research_4: 'Unsere Kunden',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - ist eine Datenerhebungsmethode, bei der Umfragen über das Telefon mit Hilfe eines Computers durchgeführt werden. Die CATI-Software ermöglicht es Ihnen, die Prozesse der Datenerfassung, der Beantwortungseingabe und der Umfrageverwaltung zu automatisieren. Die Verwendung von CATI erleichtert die Kontrolle über die Umfrage, ermöglicht eine schnelle Analyse und Verarbeitung der erhaltenen Daten und gewährleistet ein hohes Maß an Standardisierung der Umfrage.',
  sociological_research_7: 'СATI',
  sociological_research_8:
    'CAWI - ist eine Datenerhebungsmethode, bei der die Befragten Fragebögen ausfüllen oder Fragen über eine Weboberfläche beantworten, und die Daten automatisch mit einer speziellen Software erfasst und verarbeitet werden. Die CAWI-Methode ermöglicht groß angelegte Umfragen, eine schnelle Datenerfassung und -analyse.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - Es handelt sich um eine Methode der Datenerfassung für persönliche Umfragen, bei der der Forscher ein Tablet oder ein Mobiltelefon verwendet, um die Antworten der Befragten auf die Fragen einzugeben, was eine automatisierte Datenerfassung ermöglicht, Eingabefehler reduziert und die Analyse der Ergebnisse vereinfacht.',
  sociological_research_11: 'Unsere Experten nutzen eine Vielzahl von Tools',
  sociological_research_12: 'Beispiel',
  sociological_research_13:
    'Wir berechnen und verwenden Stichproben von beliebiger Komplexität',
  sociological_research_14:
    'In unserer Arbeit verwenden wir einfache, systematische, geschichtete und Cluster-Stichproben',
  sociological_research_15:
    'Für landesweit repräsentative Face-to-Face-Erhebungen verwenden wir mehrstufige, geschichtete Stichprobenverfahren',
  sociological_research_16:
    'Die Auswahl der Siedlungen nach Regionen erfolgt je nach Größe und Art der Siedlung, ihre Anzahl wird im Verhältnis zu ihrem Volumen in jeder Region bestimmt',
  sociological_research_17: 'Wir führen aus',
  sociological_research_18: 'Meinungsforschung',
  sociological_research_19: 'Sozioökonomische Forschung',
  sociological_research_20: 'Desk Research',
  sociological_research_21: 'Bewertung der Qualität von Sozialdienstleistungen',
  sociological_research_22: 'Fallstudie',
  sociological_research_23: 'Personalforschung',
  sociological_research_24:
    'Forschung zu bestimmten Zielgruppen (gefährdete Gruppen, einschließlich Binnenvertriebene, Militär, Jugendliche usw.)',
  sociological_research_25: 'Wir arbeiten mit jedem Kunden individuell',
  sociological_research_26: 'Unsere Kunden:',
  sociological_research_27: 'Gemeinnützige Stiftungen',
  sociological_research_28: 'Öffentliche Organisationen und Gewerkschaften',
  sociological_research_29: 'Staatliche Strukturen',
  sociological_research_30: 'Unser Ansatz für die Arbeit',
  sociological_research_31:
    'Wir verwenden einen wissenschaftlichen Ansatz für unsere Arbeit und ein tiefes Verständnis der aktuellen Realität sowie verschiedene Forschungsmethoden, um Daten zu sammeln.',
  sociological_research_32:
    'Wir engagieren hochspezialisierte Experten mit der richtigen Erfahrung, um eine spezifische Anfrage zu bearbeiten und verfügen über einen Pool von Experten aus verschiedenen Bereichen.',
  sociological_research_33:
    'Unsere hauseigene Analyseabteilung analysiert die gesammelten Daten mit einem einzigartigen Satz von Instrumenten, die auf die Besonderheiten der Aufgabe abgestimmt sind.',
  sociological_research_34:
    'Wir erstellen Berichte mit detaillierten Ergebnissen und Empfehlungen unter Berücksichtigung der Bedürfnisse und Erwartungen Ihrer Zielgruppe.',
  sociological_research_35:
    'Wir beraten und unterstützen Sie bei der Umsetzung der Empfehlungen und helfen Ihnen, positive Veränderungen vorzunehmen.',
  sociological_research_36: 'Unsere Forschung zum Thema TV',
  sociological_research_37: 'Lesen Sie unsere Berichte',
  sociological_research_38:
    'Unsere wissenschaftliche Herangehensweise an unsere Arbeit garantiert unseren Kunden genaue und zuverlässige soziologische Daten in der gesamten Ukraine und Europa mit minimalen Fehlern',
  sociological_research_39:
    'Wir arbeiten in Übereinstimmung mit allen Prinzipien und Standards der Ethik und den Regeln für die Durchführung soziologischer Forschung.',
  sociological_research_40:
    'Wir halten uns an den Kodex der Berufsethik für Soziologen der Soziologischen Vereinigung der Ukraine und die Erklärung von Helsinki über die ethischen Grundsätze der Forschung. Mitglieder von ESOMAR, der Ukrainischen Marketingvereinigung, der Allukrainischen Vereinigung der Kontaktzentren der Ukraine.',
  sociological_research_41: 'Feld',
  sociological_research_42: 'Online-Dashboard',
  sociological_research_43: 'regelmäßige Befrager',
  sociological_research_44: 'Vorgesetzte',
  sociological_research_45: 'Qualitätskontrolle vor Ort',
  sociological_research_46: '100% фіксація GPS координатів (CAPI)',
  sociological_research_47:
    'Validierung von Fragebögen (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100%ige Aufzeichnung der Interviews (CAPI, CATI)',
  sociological_research_49: 'Wir verwenden lizenzierte Software:',
  sociological_research_50:
    'SPSS für die Analyse statistischer Daten, Verarbeitung von Datensätzen',
  sociological_research_51:
    'DigSee Sure für CAPI: Fragebogenlogik, Geolokalisierung, Audioaufnahme von Interviews, Videodemonstration',
  sociological_research_52:
    'SurveyMonkey для CAWI: Fragebogenlogik, praktisches Format, Visualisierung',
  sociological_research_53:
    'VoIPTime (Contact Center): Programmierung von Fragebögen beliebiger Komplexität, Übermittlung von Audioaufnahmen an den Kunden',
  sociological_research_54: 'Beispiel',
  sociological_research_55:
    'Wir berechnen und verwenden Stichproben von beliebiger Komplexität',
  sociological_research_56:
    'In unserer Arbeit verwenden wir einfache, systematische, geschichtete und Cluster-Stichproben',
  sociological_research_57:
    'Für landesweit repräsentative Face-to-Face-Erhebungen verwenden wir mehrstufige, geschichtete Stichprobenverfahren',
  sociological_research_58:
    'Die Auswahl der Siedlungen nach Regionen erfolgt je nach Größe und Art der Siedlung, ihre Anzahl wird im Verhältnis zu ihrem Volumen in jeder Region bestimmt',
  sociological_research_59: 'Die Mannschaft',
  sociological_research_60:
    'Unsere Soziologen sind Mitglieder der Vereinigung der Soziologen der Ukraine',
  sociological_research_61:
    'Wir haben 12 Vollzeit-Analysten mit Erfahrung in quantitativen und qualitativen Projekten in verschiedenen Branchen.',
  sociological_research_62:
    'Wir verfügen über ein starkes analytisches Expertenteam mit über 10 Jahren Forschungserfahrung. Wir haben Wissenschaftler, PhDs, MBI.',
  sociological_research_63: 'Irina Maksimyuk',
  sociological_research_64:
    'Leiterin der Forschungsabteilung. Mehr als 15 Jahre Erfahrung in der Marketingforschung, Forschungsmanagement in allen Phasen, Erstellung von analytischen Berichten, Präsentationen',
  sociological_research_65: 'Andrii Biloskurskyi',
  sociological_research_66:
    'Leiter der Abteilung Programmierung und Datenverarbeitung. 20 Jahre Erfahrung in Marketing, Soziologie und Marktforschung',
  sociological_research_67: 'Elena Somowa',
  sociological_research_68:
    'Programmierungsspezialistin Postgraduiertenstudentin der Soziologie an der DonSUU. 5 Jahre Erfahrung in der Marketingforschung Vorstandsmitglied der Demokratischen Allianz der Frauen, Leiterin des Fundraising.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Leiter der soziologischen Forschungsabteilung der Forschungsabteilung, hat 13 Jahre Erfahrung in soziologischen und sozialen Projekten auf internationaler und nationaler Ebene.  Promovierte Soziologin, Leiterin des Forschungsausschusses des Ukrainischen Verbands der Kontaktzentren der Ukraine.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Entdecken Sie unsere alternative Forschung für einzigartige Fälle',
  ad_hoc_3: 'Kontakt',
  ad_hoc_4: 'Unser Ansatz',
  ad_hoc_5:
    'Unser Expertenteam analysiert die gesammelten Daten sorgfältig und bietet Ihnen komplexe Lösungen, die zu Ihrem Wachstum beitragen.',
  ad_hoc_6:
    'Wir entwerfen eine einzigartige, auf Ihre spezifischen Anforderungen zugeschnittene Studie, die auf eine Vielzahl von Methoden zurückgreift, darunter Umfragen, Interviews, Fokusgruppen, Datenanalysen und mehr.',
  ad_hoc_7: 'Warum Ad Hoc Forschung?',
  ad_hoc_8: 'Flexibilität',
  ad_hoc_9:
    'Kein Forschungsprojekt ist wie das andere. Mit Ad Hoc Forschung haben wir die Flexibilität, den einzigartigen Ansatz nach Bedarf anzupassen und weiterzuentwickeln, um sicherzustellen, dass Ihre Erkenntnisse relevant und umsetzbar bleiben.',
  ad_hoc_10: 'Präzision',
  ad_hoc_11:
    'Indem wir unsere Methoden auf Ihre individuellen Anforderungen zuschneiden, liefern wir präzise und zielgerichtete Ergebnisse, die wertvolle Erkenntnisse liefern.',
  ad_hoc_12: 'Innovation',
  ad_hoc_13:
    'Wir setzen in unserem Forschungsprozess auf Innovation und Kreativität und erforschen neue Techniken und Ansätze, um verborgene Möglichkeiten und Herausforderungen aufzudecken.',
  ad_hoc_14: 'Rechtzeitigkeit',
  ad_hoc_15:
    'Ad-hoc-Forschung ermöglicht eine rasche Umsetzung, so dass Sie zeitnah auf wichtige Erkenntnisse zugreifen können und in Ihrer Branche immer einen Schritt voraus sind..',
  ad_hoc_16: 'Unser Fachwissen',
  ad_hoc_17:
    '4Service-Experten mit mehr als 20 Jahren Erfahrung in Marktforschung und Kundenbetreuung entwickeln einzigartige Lösungen, die Ihr Unternehmen voranbringen',
  ad_hoc_18:
    'Wir verwenden eine Reihe von Tools, die auf Ihre spezifischen Anforderungen abgestimmt sind',
  ad_hoc_19: 'Keine Methodik arbeitet für Sie?',
  ad_hoc_20: 'Die Ad-hoc-Forschung anfordern',
  ad_hoc_21: 'Maßgeschneidert für ein bestimmtes Problem',
  ad_hoc_22:
    'Wir verwenden sowohl eine Mischung aus verschiedenen Teilen der Methoden als auch einen völlig neuen Ansatz',
  ad_hoc_23: 'Individueller Ansatz',
  ad_hoc_24: 'Wir schneidern sorgfältig eine Lösung direkt auf Ihr Problem zu',
  ad_hoc_25: 'Gehen Sie über sich hinaus',
  ad_hoc_26:
    'Wir erstellen einen umsetzbaren Plan mit Empfehlungen, damit Ihr Unternehmen seine Ziele erreichen kann',
  ad_hoc_27: 'Sie haben ein nicht standardisiertes Problem',
  ad_hoc_28: 'Keine der Methoden funktioniert für Ihr Problem',
  ad_hoc_29:
    'Sie möchten verschiedene Methoden oder Teile davon auf einmal anfordern',
  ad_hoc_30: 'Wer profitiert von der Ad-hoc-Forschung?',
};
