import IAdminApi from './IAdminApi';
import ApiResponse from '../entities/ApiResponse';
import { IPost } from '../../enteties/IPost';
import { IServerLanguage } from '../../enteties/IServerLanguage';
import { IType } from '../../enteties/IType';
import { getParameterString } from '../../functions/getParameterString';
import { IMetaDataFromServer } from '../../enteties/IMetaDataFromServer';
import { IMetaData } from '../../enteties/IMetaData';

export default class AdminApi implements IAdminApi {
  private baseUrl: string = process.env.REACT_APP_ADMIN_PANEL_API_HOST!;

  private async fetchData(path: string, requestOptions: any): Promise<any> {
    try {
      // console.log(`${this.getBaseUrl(path)}${path}`, { ...requestOptions });
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;
      const data = await response.json();

      return {
        data,
        statusCode,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('FETCH ERROR:', e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    // if (tokenRequired) {
    //   const accessToken = localStorage.getItem('access_token');
    //   myHeaders.Authorization = `Bearer ${accessToken}`;
    // }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async putData(path: string, data: any, formData?: boolean) {
    const accessToken = localStorage.getItem('token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    // if (accessToken) {
    //   myHeaders.append('Authorization', `Bearer ${accessToken}`);
    // }

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  private async deleteData(path: string, data?: any): Promise<any> {
    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      body: string;
    } = {
      method: 'DELETE',
      redirect: 'follow',
      body: JSON.stringify(data),
    };

    return this.fetchData(path, requestOptions);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTypes(): Promise<ApiResponse<IType[]>> {
    return Promise.resolve({
      statusCode: 200,
      data: [
        { id: 1, name: 'Other' },
        { id: 2, name: 'Blog' },
      ],
    });
    // return this.getData('/types/');
  }

  public async getLanguages(): Promise<ApiResponse<IServerLanguage[]>> {
    return this.getData('/languages/');
  }

  // eslint-disable-next-line class-methods-use-this
  public async getPosts(
    language?: string,
    filterParams?: { [key: string]: string | string[] },
    limit = 10,
    offset = 0,
  ): Promise<ApiResponse<{ data: IPost[]; count: number }>> {
    const path = `/a/?offset=${offset || 0}&limit=${limit || 10}${
      language ? `&language=${language}` : ''
    }${getParameterString(filterParams)}`;

    return this.getData(path);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getPost(slug: string): Promise<ApiResponse<IPost>> {
    return this.getData(`/a/${slug}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getMetaBySlug(slug: string): Promise<ApiResponse<IMetaData>> {
    return this.getData(`/meta_data/slug/${slug}`);
  }
}
