import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Expert4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import expert from '../../../assets/images/4opinion/expert.png';

export const Expert4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <Expert4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_20')}</h2>
        <div className="content">
          <img src={expert} alt="" />
          <div className="list">
            <div className="list-item">
              <span>01</span>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_21')}</p>
            </div>
            <div className="list-item">
              <span>02</span>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_22')}</p>
            </div>
            <div className="list-item">
              <span>03</span>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_23')}</p>
            </div>
          </div>
        </div>
      </div>
    </Expert4OpinionStyles>
  );
};
