import methodology1 from '../../../assets/images/sociologicalResearch/methodology1.png';
import methodology2 from '../../../assets/images/sociologicalResearch/methodology2.png';
import methodology3 from '../../../assets/images/sociologicalResearch/methodology3.png';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const sliderData = (
  lang: string,
): { name: string; description: string; img: string }[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'sociological_research_5'),
    description: getTranslationsByLangOrEng(lang, 'sociological_research_6'),
    img: methodology1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'sociological_research_7'),
    description: getTranslationsByLangOrEng(lang, 'sociological_research_8'),
    img: methodology2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'sociological_research_9'),
    description: getTranslationsByLangOrEng(lang, 'sociological_research_10'),
    img: methodology3,
  },
];
