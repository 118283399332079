import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BoardingStyles } from '../styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useNavigate } from 'react-router-dom';
import { TCards } from '..';
import bht from '../../../assets/images/boarding/bht.png';
import caw from '../../../assets/images/boarding/caw.png';
import qur from '../../../assets/images/boarding/qur.png';
import cxs from '../../../assets/images/boarding/cxs.png';
import uaa from '../../../assets/images/boarding/uaa.png';
import bau from '../../../assets/images/boarding/bau.png';
import cpf from '../../../assets/images/boarding/cpf.png';
import cjm from '../../../assets/images/boarding/cjm2.png';
import bip from '../../../assets/images/boarding/bip.png';
import ahr from '../../../assets/images/boarding/ahr.png';
import slr from '../../../assets/images/boarding/slr.png';

export const MarketResearch = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();

  const cards = (lang: string): TCards[] => ([
    {
      bg: 'linear-gradient(45deg, #6C33F1 0%, #F26B35 54.17%, #F8B01D 100%)',
      img: bht,
      title: getTranslationsByLangOrEng(lang, 'boarding_5'),
      text: getTranslationsByLangOrEng(lang, 'boarding_6'),
      link: 'brand-awareness-usage',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(225.73deg, #00FFB6 -1.31%, #48A6E0 30.61%, #9E41E8 100%)',
      img: qur,
      title: getTranslationsByLangOrEng(lang, 'boarding_21'),
      text: getTranslationsByLangOrEng(lang, 'boarding_22'),
      link: 'qualitative-research',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(259.3deg, #FDA272 5.63%, #C835B6 51.15%, #0066FF 113.53%)',
      img: caw,
      title: getTranslationsByLangOrEng(lang, 'boarding_23'),
      text: getTranslationsByLangOrEng(lang, 'boarding_24'),
      link: 'quantitative-research',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(227.07deg, #006B55 0%, #000000 100%)',
      img: cxs,
      title: getTranslationsByLangOrEng(lang, 'boarding_37'),
      text: getTranslationsByLangOrEng(lang, 'boarding_38'),
      link: 'cx',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(135.55deg, #5498FF 0%, #7E6DE4 100.95%)',
      img: uaa,
      title: getTranslationsByLangOrEng(lang, 'boarding_41'),
      text: getTranslationsByLangOrEng(lang, 'boarding_42'),
      link: 'u&a',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(135.55deg, #BBBAFF 0%, #8E50CC 100.95%)',
      img: bau,
      title: getTranslationsByLangOrEng(lang, 'boarding_43'),
      text: getTranslationsByLangOrEng(lang, 'boarding_44'),
      link: 'brand-awareness-usage',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(225deg, #FFBD14 0%, #DB9000 100%)',
      img: bip,
      title: getTranslationsByLangOrEng(lang, 'boarding_45'),
      text: getTranslationsByLangOrEng(lang, 'boarding_46'),
      link: 'brand-identity-and-positioning',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(180deg, #FA3DC2 0%, #AE19A4 100%)',
      img: cpf,
      title: getTranslationsByLangOrEng(lang, 'boarding_47'),
      text: getTranslationsByLangOrEng(lang, 'boarding_48'),
      link: 'customer-profiling-and-segmentation',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(180deg, #FDA272 0%, #F15967 100%)',
      img: cjm,
      title: getTranslationsByLangOrEng(lang, 'boarding_49'),
      text: getTranslationsByLangOrEng(lang, 'boarding_50'),
      link: 'consumer-journey-map',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(99deg, #FCC666 0.43%, #FA1A2E 52.63%, #5CB7F1 104.84%)',
      img: ahr,
      title: getTranslationsByLangOrEng(lang, 'boarding_54'),
      text: getTranslationsByLangOrEng(lang, 'boarding_55'),
      link: '/adHoc',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(180deg, #F05A25 0%, #FAAF3B 100%)',
      img: slr,
      title: getTranslationsByLangOrEng(lang, 'boarding_56'),
      text: getTranslationsByLangOrEng(lang, 'boarding_57'),
      link: 'sociological-research',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
  ]);

  const handleOpen = (item: TCards) => {
    if (!item.abroad) {
      navigate(`/${interfaceLanguage}/${item.link}`);
    } else {
      window.open(item.link, 'blank');
    }
  };

  return (
    <BoardingStyles>
      <div className="container">
        <div className="intro">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_7')}</h1>
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'boarding_53')}</h2>
        </div>
        <div className="cards">
          {cards(interfaceLanguage).map((item) => (
            <div className="card" onClick={() => handleOpen(item)}>
              <div className="card-img" style={{ background: item.bg }}>
                <img src={item.img} alt={item.title} />
              </div>
              <div className="card-text">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </BoardingStyles>
  );
};
