import styled from 'styled-components';

interface Props {
  background: string;
  backgroundOnHover: string;
  textColor: string;
  textColorOnHover: string;
  borderColor?: string;
  borderOnHover?: string;
}

export const ButtonWithArrowOnHoverStyles = styled.div<Props>`
  button, a {
    position: relative;
    display: flex;
    align-items: center;
    border: ${(props) => (props.borderColor ? `2px solid ${props.borderColor}` : 'none')};

    padding: 0 54px;
    height: 49px;

    background: ${(props) => props.background};
    border-radius: 100px;
    transition: 0.5s all;
    cursor: pointer;

    &:hover {
      border: ${(props) => (props.borderOnHover ? `2px solid ${props.borderOnHover}` : 'none')};
    }

    span {
      transition: 0.5s all;
      color: ${(props) => props.textColor};
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;

      @media (max-width: 1000px) {
        font-size: 13px;
      }

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    svg {
      position: absolute;
      right: 0;

      opacity: 0;
      transition: 0.5s all;
    }

    &:hover {
      background: ${(props) => props.backgroundOnHover};

      span {
        color: ${(props) => props.textColorOnHover};
      }

      span,
      svg {
        transform: translateX(-20px);
      }

      svg {
        opacity: 1;
      }
    }
  }
`;
