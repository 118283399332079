import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BoardingStyles } from '../styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useNavigate } from 'react-router-dom';
import { TCards } from '..';
import p4s from '../../../assets/images/boarding/p4s.png';
import vcr from '../../../assets/images/boarding/vcr.png';
import mys from '../../../assets/images/boarding/mys.png';
import wan from '../../../assets/images/boarding/wan.png';
import prm from '../../../assets/images/boarding/prm.png';
import svd from '../../../assets/images/boarding/svd.png';
import aip from '../../../assets/images/boarding/aip.png';
import fca from '../../../assets/images/boarding/fca.png';
import spa from '../../../assets/images/boarding/spa.png';

export const Products = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();

  const cards = (lang: string): TCards[] => ([
    {
      bg: 'linear-gradient(47.07deg, #0500FF 0%, #00D1FF 100%)',
      img: mys,
      title: getTranslationsByLangOrEng(lang, 'boarding_25'),
      text: getTranslationsByLangOrEng(lang, 'boarding_26'),
      link: 'mystery-shopping',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(227.07deg, #0000FF 0%, #00003C 100%)',
      img: aip,
      title: getTranslationsByLangOrEng(lang, 'boarding_33'),
      text: getTranslationsByLangOrEng(lang, 'boarding_34'),
      link: 'automotive-industry-price-analysis',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(225deg, #883EEC 0%, #7700A0 100%)',
      img: vcr,
      title: getTranslationsByLangOrEng(lang, 'boarding_19'),
      text: getTranslationsByLangOrEng(lang, 'boarding_20'),
      link: 'https://voicerhub.com/en/products/voicer',
      abroad: true,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(47.07deg, #28B5C9 0%, #091638 100%)',
      img: wan,
      title: getTranslationsByLangOrEng(lang, 'boarding_27'),
      text: getTranslationsByLangOrEng(lang, 'boarding_28'),
      link: 'wantent',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(56.34deg, #ED43E6 0%, #FF4F5E 51.56%, #FAC863 100%)',
      img: prm,
      title: getTranslationsByLangOrEng(lang, 'boarding_29'),
      text: getTranslationsByLangOrEng(lang, 'boarding_30'),
      link: 'price-monitoring',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(227.07deg, #042A40 0%, #5E0097 100%)',
      img: fca,
      title: getTranslationsByLangOrEng(lang, 'boarding_35'),
      text: getTranslationsByLangOrEng(lang, 'boarding_36'),
      link: 'facility-check-audit',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(46.69deg, #E8B500 8.04%, #FFF500 100.64%)',
      img: svd,
      title: getTranslationsByLangOrEng(lang, 'boarding_31'),
      text: getTranslationsByLangOrEng(lang, 'boarding_32'),
      link: 'service-design',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(227.07deg, #1BBEE9 0%, #B07AEC 50%, #F35162 100%)',
      img: spa,
      title: getTranslationsByLangOrEng(lang, 'boarding_39'),
      text: getTranslationsByLangOrEng(lang, 'boarding_40'),
      link: 'https://products.plesk.4service.group',
      abroad: true,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es'],
    },
    {
      bg: 'linear-gradient(220.49deg, rgba(24, 24, 27, 0.3) 0%, rgba(138, 138, 142, 0.3) 99.57%)',
      img: p4s,
      title: getTranslationsByLangOrEng(lang, 'boarding_17'),
      text: getTranslationsByLangOrEng(lang, 'boarding_18'),
      link: lang === 'uk' ? 'https://4service.group/ukr/play4sales/' : 'https://4service.group/ukr/ru/play-4-sales/',
      abroad: true,
      langs: ['uk', 'kk-kz', 'ru-kz'],
    },
  ]);

  const handleOpen = (item: TCards) => {
    if (!item.abroad) {
      navigate(`/${interfaceLanguage}/${item.link}`);
    } else {
      window.open(item.link, 'blank');
    }
  };
  return (
    <BoardingStyles>
      <div className="container">
        <div className="intro">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'main_4')}</h1>
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'boarding_52')}</h2>
        </div>
        <div className="cards">
          {cards(interfaceLanguage).filter((el) => el.langs.includes(interfaceLanguage)).map((item) => (
            <div className="card" onClick={() => handleOpen(item)}>
              <div className="card-img" style={{ background: item.bg }}>
                <img src={item.img} alt={item.title} />
              </div>
              <div className="card-text">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </BoardingStyles>
  );
};
