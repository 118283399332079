import React, { useEffect } from 'react';
import { FourOpinionStyles } from './styles';
import {
  Banner4Opinion,
  Benefits4Opinion,
  Expert4Opinion,
  Experts4Opinion,
  History4Opinion,
  Prefooter4Opinion,
  Selection4Opinion,
} from '../../components';

export const FourOpinion = () => {
  useEffect(() => {}, []);
  return (
    <FourOpinionStyles>
      <Banner4Opinion />
      <Benefits4Opinion />
      <Expert4Opinion />
      <Selection4Opinion />
      <Experts4Opinion />
      <History4Opinion />
      <Prefooter4Opinion />
    </FourOpinionStyles>
  );
};
