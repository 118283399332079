import styled from 'styled-components';

export const Expert4OpinionStyles = styled.div`
  background: #fff;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 80px;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }

    img {
      max-width: 345px;
      width: 100%;
      flex-shrink: 0;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 80px;

    @media (max-width: 768px) {
      padding: 50px 0 0;
    }

    &-item {
      max-width: 370px;
      position: relative;

      &:nth-child(1) {
        align-self: flex-start;
      }
      &:nth-child(2) {
        align-self: center;
      }
      &:nth-child(3) {
        align-self: flex-end;
      }

      span {
        font-weight: 700;
        font-size: 96px;
        line-height: 125%;
        color: rgba(0, 0, 255, 0.5);
        position: absolute;
        left: -50px;
        top: -60px;

        @media (max-width: 768px) {
          left: 0px;
          top: -95px;
        }
      }

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 125%;
        color: #000000;
        position: relative;
        z-index: 3;

        @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
`;
