import styled from 'styled-components';

interface Props {
  textColor: string;
  backgroundColor: string;
}

export const MenuStyles = styled.nav<Props>`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;

  transform: translateY(calc(-100% - 73px));
  opacity: 0;

  transition: 0.6s all;
  color: ${(props) => props.textColor || '#000000'};
  background: ${(props) => props.backgroundColor || '#FFFFFF'};
  padding: 213px 10px 30px 10px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(255, 255, 255, 0);
  }

  a {
    color: ${(props) => props.textColor || '#000000'};
  }

  .active {
    color: #0000ff;
  }

  @media (max-width: 768px) {
    padding: 100px 10px 30px 10px;
  }

  &.menuActive {
    transform: translateY(0);
    opacity: 1;
  }

  .menuContent {
    display: flex;
    flex-direction: column;

    justify-content: center;
    height: 100%;
    width: 100%;

    .menuHeadWrapper {
      h2 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 28px;

        letter-spacing: -0.022em;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      .linksContainer {
        display: flex;
        width: 100%;
        justify-content: space-around;
        gap: 20px;

        margin-bottom: 44px;

        @media (max-width: 600px) {
          flex-wrap: wrap;
          gap: 25px 10px;
          justify-content: space-between;
        }

        @media (max-width: 768px) {
          text-align: center;
        }

        a:hover {
          text-decoration: underline;
        }

        .methodsLinksWrapper {
          ul {
            padding-top: 6px;
          }

          @media (max-width: 600px) {
            width: calc(50% - 10px);
          }
        }

        .productsLinksWrapper {
          ul {
            padding-top: 6px;
          }

          @media (max-width: 600px) {
            width: calc(50% - 10px);
          }
        }

        .marketResearchLinksWrapper {
          ul {
            padding-top: 6px;
          }

          @media (max-width: 600px) {
            width: calc(50% - 10px);
          }
        }

        .methodsLinksContainer,
        .productsLinksContainer {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 18px;

          @media (max-width: 768px) {
            font-weight: 500;
            font-size: 11px;
          }
        }

        .otherLinksContainer1 {

          a {
            white-space: pre-wrap;
          }

          @media (max-width: 600px) {
            width: calc(50% - 10px);
          }
        }

        .otherLinksContainer2 {
        }

        .otherLinksContainer1,
        .otherLinksContainer2 {
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 28px;
          letter-spacing: -0.022em;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        ul {
          list-style: none;

          display: grid;
          gap: 6px;
        }
      }

      .socialLinksContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 38px;

        ul {
          display: grid;
          grid-template-columns: repeat(3, auto);
          column-gap: 16px;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .splitter {
      margin: 0 auto 47px auto;

      width: 96%;
      height: 1px;

      background: #b8b8b8;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .closeButton {
      position: fixed;
      bottom: 10px;
      right: 10px;
      display: none;
      border: none;
      background: none;
      width: 40px;
      height: 40px;
      margin-left: auto;
      padding: 0;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .menuFooterContent {
      display: grid;
      justify-content: flex-end;
      align-items: center;
      grid-template-columns: auto auto;
      column-gap: 89px;

      @media (max-width: 768px) {
        display: none;
      }

      h2 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 18px;
      }

      a {
        text-decoration: none;
        color: ${(props) => props.textColor || '#000000'};
        position: relative;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
      }

      div {
        padding: 12px 21px;
        border-radius: 27px;
      }

      div::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 27px;
        padding: 1px;
        background: linear-gradient(
          211.88deg,
          #ed1c2a -11.36%,
          #ee2752 46.79%,
          #444a49 101.73%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
`;
