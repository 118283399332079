import styled from 'styled-components';
import banner from '../../../assets/images/4opinion/banner.png';

export const Banner4OpinionStyles = styled.div`
  background: url(${banner}) no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 528px;
    height: 528px;
    border-radius: 50%;
    background: rgba(0, 0, 255, 0.51);
    filter: blur(65.95px);
  }

  &::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 528px;
    height: 528px;
    border-radius: 50%;
    background: rgba(0, 0, 255, 0.51);
    filter: blur(65.95px);
  }

  .container {
    padding: 120px 10px 70px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;

    @media (max-width: 768px) {
        padding: 40px 10px 40px;
        gap: 30px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 110px;
    line-height: 97%;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 800px;
    align-self: flex-start;

    @media (max-width: 768px) {
        font-size: 50px;
    }
  }

  h3 {
    max-width: 670px;
    font-weight: 500;
    font-size: 40px;
    line-height: 97%;
    color: #ffffff;
    align-self: flex-end;

    @media (max-width: 768px) {
        font-size: 30px;
    }
  }
`;
