import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { History4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const History4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <History4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_13')}</h2>
        <div className="cards">
          <div className="cards-item">
            <h5>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_14')}</h5>
            <ButtonWithArrowOnHover
              link="https://t.me/+k7C3kzcCo_Q2OWJi"
              arrowColor="#fff"
              textColor="#fff"
              borderColor="#fff"
              borderOnHover="#fff"
              background="transparent"
              hoverBackground="transparent"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'four_opinion_3',
              )}
            />
          </div>
          <div className="cards-item">
            <h5>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_15')}</h5>
            <ButtonWithArrowOnHover
              link="https://whatsapp.com/channel/0029VaG21G59cDDUAdLXq83l"
              arrowColor="#fff"
              textColor="#fff"
              borderColor="#fff"
              borderOnHover="#fff"
              background="transparent"
              hoverBackground="transparent"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'four_opinion_3',
              )}
            />
          </div>
        </div>
      </div>
    </History4OpinionStyles>
  );
};
