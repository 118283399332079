import styled from 'styled-components';

export const Selection4OpinionStyles = styled.div`
  background: #fff;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    gap: 30px;

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 25px;
      gap: 26px;
      max-width: 370px;
      width: 100%;
      background: rgba(255, 255, 255, 0.4);
      border: 2px solid #0000ff;
      backdrop-filter: blur(11.65px);
      border-radius: 10px;

      @media (max-width: 768px) {
        padding: 20px;
        gap: 20px;
      }

      &:hover {
        background: #0000ff;

        p {
          color: #fff;
        }
      }

      img {
        width: 100%;
        border-radius: 5px;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 125%;
        color: #292929;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
