import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Selection4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import selection1 from '../../../assets/images/4opinion/selection-1.png';
import selection2 from '../../../assets/images/4opinion/selection-2.png';
import selection3 from '../../../assets/images/4opinion/selection-3.png';

export const Selection4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <Selection4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_16')}</h2>
        <div className="cards">
          <div className="cards-item">
            <img src={selection1} alt="" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_17')}</p>
          </div>
          <div className="cards-item">
            <img src={selection2} alt="" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_18')}</p>
          </div>
          <div className="cards-item">
            <img src={selection3} alt="" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_19')}</p>
          </div>
        </div>
      </div>
    </Selection4OpinionStyles>
  );
};
